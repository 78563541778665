const Organigrama= ()=> import('@/views/ADMIN/Organigrama.vue')
const OrgList= ()=> import('@/components/Organigrama/List.vue')
const OrgAdd= ()=> import('@/components/Organigrama/Add.vue')
const OrgEmpresas= ()=> import('@/components/Organigrama/OrganigramaEmpresas.vue')

export const organigramaList = {
  path: "/organigrama",
  name: "Organigrama",
  meta: { requiresAuth: true},
  component: Organigrama,
  redirect: { name: "org-list" },
  children: [ {
      path: "list",
      meta: { requiresAuth: true},
      name: "org-list",
      component: OrgList
    }, {
      path: "add",
      meta: { requiresAuth: true},
      name: "org-add",
      component: OrgAdd
    }, {
      path: "edit/:id",
      meta: { requiresAuth: true},
      name: "org-edit",
      props: true,
      component: OrgAdd
    }, {
      path: "empresas",
      meta: { requiresAuth: true},
      name: "org-empresas",
      component: OrgEmpresas
    },
  ],
};