const Seguridad= ()=> import('@/views/ADMIN/Seguridad.vue')
const SeguridadList= ()=> import('@/components/Seguridad/List.vue')
const SeguridadAdd= ()=> import('@/components/Seguridad/Add.vue')
const SeguridadAddCat= ()=> import('@/components/Seguridad/AddCategories.vue')

export const seguridadList = {
  path: "/seguridad",
  name: "Seguridad",
  meta: { requiresAuth: true},
  component: Seguridad,
  redirect: { name: "seguridad-list" },
  children: [ {
      path: "list",
      meta: { requiresAuth: true},
      name: "seguridad-list",
      component: SeguridadList
    }, {
      path: "add",
      meta: { requiresAuth: true},
      name: "seguridad-add",
      component: SeguridadAdd
    }, {
      path: "edit/:id",
      meta: { requiresAuth: true},
      name: "seguridad-edit",
      component: SeguridadAdd
    }, {
      path: "add-categories",
      meta: { requiresAuth: true},
      name: "seguridad-add-categories",
      component: SeguridadAddCat
    },
  ],
};