import { apolloClient } from "../../../vue-apollo";
import {
  PAGINADOR_NOTICIAS_ADMIN_NE,
  NOTICIAS_PAGINADAS_ADMIN_NE,
  //GET_CATEGORIAS,
  GET_CATEGORIAS_LIST,
} from "../../../apollo/queries";
import {
  DELETE_NOTICIA,
  ADD_NOTICIA,
  EDIT_NOTICIA,
  CREAR_CATEGORIA_NOTICIA,
  EDITAR_CATEGORIA_NOTICIA,
  ELIMINAR_CATEGORIA_NOTICIA,
  CAMBIAR_ESTADO_NOTICIA,
} from "../../../apollo/mutations";
export default {
  namespaced: true,
  state: {
    paginationArr: [],
    newsArr: [],
    categories: [],
  },
  mutations: {
    addNewsToList(state, noticia) {
      state.newsArr.unshift(noticia);
    },
    deleteNewsFromList(state, news_id) {
      let found_index = state.newsArr.findIndex(
        (news) => news.id_noticia == news_id.id_noticia
      );
      state.newsArr.splice(found_index, 1);
    },
    UPDATE_NEWS_LIST(state, noticia) {
      let found_index = state.newsArr.findIndex(
        (news) => news.id_noticia == noticia.id_noticia
      );
      if (found_index != -1) Object.assign(state.newsArr[found_index], noticia);
    },
    setPagination(state, resp) {
      state.paginationArr = resp;
    },
    setNewsByPagination(state, resp) {
      state.newsArr = resp;
    },
    setCategories(state, resp) {
      state.categories = resp;
    },
    CREATE_NEWS_CATEGORY(state, resp) {
      state.categories.unshift(resp);
    },
    EDIT_NEWS_CATEGORY(state, resp) {
      const idx = state.categories.findIndex(
        (cat) => cat.id_categoria === resp.id_categoria
      );
      state.categories.splice(idx, 1);
      state.categories.splice(idx, 0, resp);
      // Edit de la categoría en el state de noticias, a todas las noticias que correspondan
      state.newsArr.forEach((noticia, idxNot) => {
        noticia.categoria.forEach((cat, idxCat) => {
          if (cat.id_categoria === resp.id_categoria) {
            state.newsArr[idxNot].categoria[idxCat] = resp;
          }
        });
      });
    },
    DELETE_NEWS_CATEGORY(state, resp) {
      const idx = state.categories.findIndex(
        (cat) => cat.id_categoria === resp.id_categoria
      );
      state.categories.splice(idx, 1);
    },
  },
  actions: {
    // Obtener noticias
    async getNewsPagination({ commit }, data_) {
      let response = [];
      let params = {
        query: PAGINADOR_NOTICIAS_ADMIN_NE,
        variables: data_,
      };
      await apolloClient.query(params).then((res) => {
        if (!res.loading) response = res.data.paginadorNoticiasAdminNE;
      });

      commit("setPagination", response);
    },

    async getNewsPaginated({ commit }, data_) {
      let response = [];
      let params = {
        query: NOTICIAS_PAGINADAS_ADMIN_NE,
        variables: data_,
      };
      await apolloClient.query(params).then((res) => {
        if (!res.loading) response = res.data.noticiasPaginadasAdminNE;
      });
      commit("setNewsByPagination", response);
    },

    async deleteNews({ commit }, data_) {
      const params = {
        mutation: DELETE_NOTICIA,
        variables: data_,
      };

      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.eliminarNoticia;
        if (response != null) commit("deleteNewsFromList", response);
      } catch (error) {
        console.log("Error deleting news:", error);
      }
      return response;
    },

    async addNews({ commit }, data_) {
      let response = null;
      let params = {
        mutation: ADD_NOTICIA,
        variables: data_,
      };
      try {
        await apolloClient.mutate(params).then((res) => {
          if (!res.loading) response = res.data.agregarNoticia;
          if (response != null) commit("addNewsToList", response);
        });
      } catch (e) {
        console.log("response de get addNews: ", e);
      }

      return new Promise((resolve) => {
        resolve(response);
      });
    },

    async editNews({ commit }, data_) {
      let response = null;
      let params = {
        mutation: EDIT_NOTICIA,
        variables: data_,
      };
      await apolloClient
        .mutate(params)
        .then((res) => {
          //console.log("editNews", res);
          if (!res.loading) response = res.data.editarNoticia;
          if (response != null) commit("UPDATE_NEWS_LIST", response);
        })
        .catch((err) => {
          console.log("error:", err);
        });
      return new Promise((resolve) => {
        resolve(response);
      });
    },

    async changeNewsState({ commit }, data_) {
      let params = {
        mutation: CAMBIAR_ESTADO_NOTICIA,
        variables: data_,
      };

      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        //console.log("Data: changeNewsState:", res.data.cambiarEstadoNoticia);
        if (!res.loading) response = res.data.cambiarEstadoNoticia;
        commit("UPDATE_NEWS_LIST", response);
      } catch (e) {
        console.log("response de cambiar estado de noticia: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getNewsCategories({ commit }, data_) {
      let response = null;
      let params = {
        query: GET_CATEGORIAS_LIST,
        variables: data_,
        fetchPolicy: "network-only",
      };
      try {
        await apolloClient.query(params).then((res) => {
          if (!res.loading) response = res.data.categoriasAdministrador;
        });
      } catch (e) {
        console.log("response de get categorias: ", e);
        //response = null;
      }
      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          commit("setCategories", response);
        } else {
          resolve(null);
        }
      });
    },

    async addNewsCategory({ commit }, data_) {
      let response = [];
      let params = {
        mutation: CREAR_CATEGORIA_NOTICIA,
        variables: data_,
      };
      try {
        let data = null;
        await apolloClient.mutate(params).then((res) => {
          if (!res.loading) data = res.data.CrearCategoriaNoticia;
          response = data;
        });
      } catch (e) {
        console.log("response de crear categoría de noticia: ", e);
        console.log(e.networkError.result.errors);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          //console.log("addNewsCategory response:", response);
        } else {
          resolve(false);
        }
        commit("CREATE_NEWS_CATEGORY", response);
      });
    },

    async editNewsCategory({ commit }, data_) {
      let response = [];
      let params = {
        mutation: EDITAR_CATEGORIA_NOTICIA,
        variables: data_,
      };
      try {
        let data = null;
        await apolloClient.mutate(params).then((res) => {
          if (!res.loading) data = res.data.EditarCategoriaNoticia;
          response = data;
        });
      } catch (e) {
        console.log("response de editar categoría de noticia: ", e);
        console.log(e.networkError.result.errors);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
        } else {
          resolve(false);
        }
        commit("EDIT_NEWS_CATEGORY", response);
      });
    },

    async deleteNewsCategory({ commit }, data_) {
      let response = [];
      let params = {
        mutation: ELIMINAR_CATEGORIA_NOTICIA,
        variables: data_,
      };
      try {
        let data = null;
        await apolloClient.mutate(params).then((res) => {
          if (!res.loading) data = res.data.EliminarCategoriaNoticia;
          response = data;
        });
      } catch (e) {
        console.log("response de eliminar categoría de noticia: ", e);
        console.log(e.networkError.result.errors);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          //console.log("deleteNewsCategory response:", response);
        } else {
          resolve(false);
        }
        commit("DELETE_NEWS_CATEGORY", response);
      });
    },
  },
};
