const Video = () => import("@/views/ADMIN/Video.vue");
const VideoList = () => import("@/components/VideosSection/List.vue");
const VideoAdd = () =>
  import("@/components/VideosSection/VideoAddPrincipal.vue");
const VideoCategory = () =>
  import("@/components/VideosSection/VideoCategory.vue");

export const videosList = {
  path: "/videos",
  name: "Videos",
  meta: { requiresAuth: true },
  component: Video,
  redirect: { name: "videos-list" },
  children: [
    {
      path: "list",
      meta: { requiresAuth: true},
      name: "videos-list",
      component: VideoList,
    },
    {
      path: "add",
      meta: { requiresAuth: true},
      name: "videos-add",
      component: VideoAdd,
    },
    {
      path: "edit/:id",
      meta: { requiresAuth: true},
      name: "videos-edit",
      component: VideoAdd,
    },
    {
      path: "category",
      meta: { requiresAuth: true},
      name: "videos-category",
      component: VideoCategory,
    },
  ],
};
