import gql from 'graphql-tag'

export const SAVE_FILE_REPO = gql `
subscription saveFileRepo(
  $archivo_b64: String
  $descripcion_archivo: String
  $formato_b64: String
  $id_empresa: String
  $id_usuario: String
  $nombre_archivo: String
  $tiempo: String
  $tipo_archivo: Int #2: videoMain. 4: banner. 5: bannerH.  6: ImagenMain
) {
  saveFileRepo(
    archivo_b64: $archivo_b64
    descripcion_archivo: $descripcion_archivo
    formato_b64: $formato_b64
    id_empresa: $id_empresa
    id_usuario: $id_usuario
    nombre_archivo: $nombre_archivo
    tiempo: $tiempo
    tipo_archivo: $tipo_archivo
  ) {
    percent
    id_archivo
    nombre_archivo
    url_archivo
    url_imagen
    descripcion_archivo
    tiempo_video
    id_estado_fk
    fecha_creacion
    fecha_modificacion
  }
}`;