import { apolloClient } from "../../../vue-apollo";
import {
	GET_NACIMIENTOS_TOTAL,
	GET_NACIMIENTOS_PAGINADOS,
	GET_USERS_SELECT,
	GET_GENEROS_OPTIONS
} from "@/apollo/queries";
import {
	CREAR_NUEVO_NACIMIENTO,
	EDITAR_NACIMIENTO,
	ELIMINAR_NACIMIENTO
} from "@/apollo/mutations";
export default {
	namespaced: true,
	state: {
		nacimientos: [],
		listPaginas: [],
		usuarios: [],
		generosOptions: []
	},

	getters: {
		getNacimientoId: (state) => (id) => {
			let objeto = null;
			objeto = state.nacimientos.find((item) => item.id_documento == id);
			return objeto
		},
	},

	mutations: {
		SET_PAGINAS_NACIMIENTO(state, data) {
			state.listPaginas = data;
		},
		SET_NACIMIENTOS(state, respuesta) {
			state.nacimientos = respuesta;
		},
		SET_USUARIOS(state, data) {
			state.usuarios = data;
		},
		CREAR_NACIMIENTO(state, nuevo) {
			state.nacimientos.unshift(nuevo);
		},
		EDITAR_NACIMIENTO(state, editado) {
			const indexNacimiento = state.nacimientos.findIndex(el => el.id_nacimiento == editado.id_nacimiento);
			if (indexNacimiento !== -1) Object.assign(state.nacimientos[indexNacimiento], editado);
		},
		DELETE_NACIMIENTO(state, eliminar) {
			state.nacimientos = state.nacimientos.filter(nac => nac.id_nacimiento !== eliminar.id_nacimiento);
		},
		SET_GENEROS_OPTIONS(state, generos) {
			state.generosOptions = generos;
		}

	},
	actions: {
		// Obtener total de paginas de nacimientos 
		async getTotalNacimientosPaginas({ commit }, payload) {
			let response = {};
			let data = {};
			let params = {
				query: GET_NACIMIENTOS_TOTAL,
				variables: payload
			}
			try {
				await apolloClient.query(params).then(res => {
					if (!res.loading) data = res.data.NuevosPadresPaginacion;
					response = data;
				});
			} catch (e) {
				console.log("listar getTotalNacimientos falla: ", e);
				response = null
			}
			return new Promise((resolve) => {
				if (response != null) {
					commit("SET_PAGINAS_NACIMIENTO", response);
					resolve(response)
				} else {
					resolve(false)
				}
			});
		},
		// obtiene los datos de la pagina actual
		async getBirths({ commit, dispatch }, payload) {
			const paginatedBirths = await dispatch("getTotalNacimientosPaginas", payload.data_);
			let pagina = payload.pagina;
			let ids_births = [];
			if (paginatedBirths) {
				paginatedBirths.forEach((data) => {
					if (data.pagina == pagina) {
						ids_births = data.ids;
					}
				});
			}
			let data_ = {
				id_empresa: payload.data_.id_empresa,
				id_nacimiento: ids_births
			}
			let params = {
				query: GET_NACIMIENTOS_PAGINADOS,
				variables: data_,
				fetchPolicy: 'network-only'
			}

			let response = null;
			try {
				const res = await apolloClient.query(params)
				if (!res.loading) response = res.data.listarNuevosPadresId;
				commit("SET_NACIMIENTOS", response);
			} catch (e) {
				console.error("Falla error, getNacimientos ", e);
			}
			return response;
		},

		async getUsuariosList({ commit }, payload) {
			const params = {
				query: GET_USERS_SELECT,
				variables: payload
			}

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.usuarios;
				commit("SET_USUARIOS", response);
			} catch (e) {
				console.log("listar GET_USUARIOS falla: ", e);
			}
			return response;
		},

		// Crear nuevo registro de nacimiento
		async addNacimiento({ commit }, payload) {
			const params = {
				mutation: CREAR_NUEVO_NACIMIENTO,
				variables: payload
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.crearNuevosPadres;
				commit("CREAR_NACIMIENTO", response);
			} catch (e) {
				console.log("response de crear addNacimineto: ", e);
			}
			return response;
		},

		// Editar registro de nacimiento
		async editNacimiento({ commit }, payload) {
			const params = {
				mutation: EDITAR_NACIMIENTO,
				variables: payload
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.editarNuevosPadres;
				commit("EDITAR_NACIMIENTO", response);
			} catch (e) {
				console.log("response de editar editNacimineto: ", e);
				console.log(e.networkError.result.errors);
			}
			return response;
		},

		//Eliminar nacimiento
		async deleteNacimiento({ commit }, payload) {
			const params = {
				mutation: ELIMINAR_NACIMIENTO,
				variables: payload
			};
			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.eliminarNuevosPadres;
				commit("DELETE_NACIMIENTO", response);
			} catch (e) {
				console.log("response de eliminar deleteNacimiento: ", e);
				console.log(e.networkError.result.errors);
			}
			return response;
		},

		async getGenerosOptions({ commit }) {
			const params = {
				query: GET_GENEROS_OPTIONS
			};
			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.filtroGenero;
				commit("SET_GENEROS_OPTIONS", response);
			} catch (e) {
				console.error("Error de eliminar getGenerosOptions", e);
			}
			return response;
		}

	},
};