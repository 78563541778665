import { apolloClient } from "../../../vue-apollo";
import { GET_SAFETY_DOCS, GET_DOC_ID } from  "../../../apollo/queries";
import {
  DELETE_SAFETY_DOC,
  CHANGE_SAFETY_DOC_STATUS,
  EDIT_SAFETY_DOC,
  ADD_SAFETY_DOC
} from "@/apollo/mutations";
export default {
  namespaced: true,
  state: { safety_docs_list: [], library_docs_list: [], ObjDocumento: {} },
  mutations: {
    getDocs(state, resp) {
      state.safety_docs_list = resp;
    },
    addDoc(state, doc) {
      state.safety_docs_list.unshift(doc);
    },
    editDoc(state, documento) {
      // obtiene index del obj doc
      let index_org = state.safety_docs_list.findIndex(
        (doc) => doc.id_documento == documento.id_documento
      );
      state.safety_docs_list[index_org] = documento;
    },
    deleteDoc(state, documento) {
      var index = state.safety_docs_list.findIndex(
        (doc) => doc.id_documento == documento.id_documento
      );
      state.safety_docs_list.splice(index, 1);
    },
    addDocLib(state, doc) {
      state.library_docs_list.unshift(doc);
    },
    editDocLib(state, editedDoc) {
      // obtiene index del obj doc
      var index = state.library_docs_list.findIndex(
        (doc) => doc.id_documento == editedDoc.id_documento
      );
      // para sacar el antiguo y reemplazarlo por el nuevo obj
      state.library_docs_list.splice(index, 1, editedDoc);
    },
    getDocsLib(state, resp) {
      state.library_docs_list = resp;
    },
    deleteDocLib(state, documento) {
      let index = state.library_docs_list.findIndex(
        (doc) => doc.id_documento == documento.id_documento
      );
      state.library_docs_list.splice(index, 1);
    },
    
    getDocId(state, doc){
      state.ObjDocumento = doc;
    },
    SET_CHAGE_ESTADO_DOC(state, documento){
      let index_org = state.safety_docs_list.findIndex(
          (doc) => doc.id_documento == documento.id_documento
      );
      state.safety_docs_list[index_org].id_estado_fk = documento.id_estado_fk;
      
    },
    SET_CHAGE_ESTADO_DOC_BIBLIO(state, documento){
      let index_org = state.library_docs_list.findIndex(
          (doc) => doc.id_documento == documento.id_documento
      );
      state.library_docs_list[index_org].id_estado_fk = documento.id_estado_fk;
      
    },
  },
  getters: {
    getDocumentId: (state) => (id) => {
      let objeto = null;
      objeto = state.safety_docs_list.find((item) => item.id_documento == id);
      //console.log("revisando el getter:_ ", objeto);
      return objeto
    },
    getDocumentbibliotecaId: (state) => (id) => {
      let objeto = null;
      objeto = state.library_docs_list.find((item) => item.id_documento == id);
      return objeto
    },
  },
  actions: {
    // Obtener documentos de seguridad
    async getDocsList({ commit }, doc) {
      const { data } = await apolloClient.query({
        query: GET_SAFETY_DOCS,
        variables: {
          id_empresa: doc.id_empresa,
          id_categoria: doc.id_categoria,
          id_modulo: doc.id_modulo,
          plataforma: doc.plataforma,
          nombre: doc.nombre,
        },
      });
      commit("getDocs", data.AllDocumentosAdmin);
    },
    async getDocId({ commit }, data_) {
      let response = [];
      let params = {
          query: GET_DOC_ID,
          variables: data_
      };
      await apolloClient.query(params).then(res => {
          //console.log('getDocId', res.data.FindDocumentoId);
          if (!res.loading) response = res.data.FindDocumentoId;
      });
      return new Promise((resolve)=>{
        if(response  != null){
            //console.log("pasa a distinto de null")
            commit('getDocId', response);
            resolve(response);
        } else {
            resolve(false);
        }
    });
      
  },
    // Agregar documento de seguridad
    async addDocAction({ commit }, data_) {
      let response = null;
      let params = {
          mutation: ADD_SAFETY_DOC,
          variables: data_
      };
      await apolloClient.mutate(params).then(res => {
          console.log('addDocAction', res.data.agregarDocumento);
          if (!res.loading) response = res.data.agregarDocumento;
      });
      return new Promise((resolve)=>{
          if(response  != null){
              //console.log("pasa a distinto de null")
              commit("addDoc", response);
              resolve(response);
          } else {
              resolve(false);
          }
      });
      
    },
    // Agregar documento de biblioteca
    async addDocLibAction({ commit }, data_) {
      let response = null;
      let params = {
          mutation: ADD_SAFETY_DOC,
          variables: data_
      };
      await apolloClient.mutate(params).then(res => {
          console.log('addDocLibAction', res.data.agregarDocumento);
          if (!res.loading) response = res.data.agregarDocumento;
      });
      return new Promise((resolve)=>{
          if(response  != null){
              //console.log("pasa a distinto de null")
              commit("addDocLib", response);
              resolve(response);
          } else {
              resolve(false);
          }
      });
    },
    // Eliminar documento de seguridad
    async deleteDocAction({ commit }, payload) {
      //commit("deleteDoc", id);
      let response = null;
      let params = {
          mutation: DELETE_SAFETY_DOC,
          variables: payload
      };
      await apolloClient.mutate(params).then(res => {
          //console.log('delete deleteDocAction', res);
          if (!res.loading) response = res.data.eliminarDocumento;
      
      });
      return new Promise((resolve) => {
          if (response != null) {
              commit('deleteDoc', response);
              resolve(response);
          }
          else {
              resolve(false)
          }
          
      })
    },

    // Cambiar estado de documento en seguridad
    async cambiaEstadoDoc({ commit }, payload) {
      let response = null;
      let params = {
          mutation: CHANGE_SAFETY_DOC_STATUS,
          variables: payload
      };
      await apolloClient.mutate(params).then(res => {
          //console.log('change status organigrama', res.data.cambiarEstadoDocumento);
          if (!res.loading) response = res.data.cambiarEstadoDocumento;
      });
      return new Promise((resolve) => {
          if(response  != null){
              //console.log("pasa a distinto de null")
              commit("SET_CHAGE_ESTADO_DOC", response);
              resolve(response);
          } else {
              resolve(false);
          }
      })
    },
    // Editar documento de seguridad
    async editDocAction({ commit }, payload) {
      
      let response = null;
      let params = {
          mutation: EDIT_SAFETY_DOC,
          variables: payload
      };
      await apolloClient.mutate(params).then(res => {
          //console.log('edit organigrama', res.data.editarDocumento);
          if (!res.loading) response = res.data.editarDocumento;
      });
      return new Promise((resolve)=>{
          if(response  != null){
              //console.log("pasa a distinto de null")
              commit("editDoc", response);
              resolve(response);
          } else {
              resolve(false);
          }
      });
    },
    // Obtener documentos de biblio
    async getDocsLibList({ commit }, data_) {
      const { data } = await apolloClient.query({
        query: GET_SAFETY_DOCS,
        variables: data_
      });
      commit("getDocsLib", data.AllDocumentosAdmin);
    },
    // Eliminar documento de biblio
    async deleteDocLibAction({ commit }, payload) {
      let response = null;
      let params = {
          mutation: DELETE_SAFETY_DOC,
          variables: payload
      };
      await apolloClient.mutate(params).then(res => {
          //console.log('delete deleteDocAction', res);
          if (!res.loading) response = res.data.eliminarDocumento;
      
      });
      return new Promise((resolve) => {
          if (response != null) {
              commit('deleteDocLib', response);
              resolve(response);
          }
          else {
              resolve(false)
          }
          
      })
    },
    // Editar documento de biblio
    async editDocLibAction({ commit }, payload) {
      let response = null;
      let params = {
          mutation: EDIT_SAFETY_DOC,
          variables: payload
      };
      await apolloClient.mutate(params).then(res => {
          //console.log('edit organigrama', res.data.editarDocumento);
          if (!res.loading) response = res.data.editarDocumento;
      });
      return new Promise((resolve)=>{
          if(response  != null){
              //console.log("pasa a distinto de null")
              commit("editDocLib", response);
              resolve(response);
          } else {
              resolve(false);
          }
      });
    },
    // Cambiar estado doc biblioteca
    async cambiaEstadoDocBiblioteca({ commit }, payload) {
      let response = null;
      let params = {
          mutation: CHANGE_SAFETY_DOC_STATUS,
          variables: payload
      };
      await apolloClient.mutate(params).then(res => {
          //console.log('change status organigrama', res.data.cambiarEstadoDocumento);
          if (!res.loading) response = res.data.cambiarEstadoDocumento;
      });
      return new Promise((resolve) => {
          if(response  != null){
              //console.log("pasa a distinto de null")
              commit("SET_CHAGE_ESTADO_DOC_BIBLIO", response);
              resolve(response);
          } else {
              resolve(false);
          }
      })
    },
  },
};
