const Gallery = () => import("@/views/ADMIN/Gallery.vue");
const GalleryList = () => import("@/components/Gallery/List.vue");
const GalleryAdd = () => import("@/components/Gallery/Add.vue");

export const galleryList = {
  path: "/galeria",
  name: "Galeria",
  component: Gallery,
  meta: { requiresAuth: true },
  redirect: { name: "gallery-list" },
  children: [
    {
      path: "list",
      meta: { requiresAuth: true},
      name: "gallery-list",
      component: GalleryList,
    },
    {
      path: "add",
      meta: { requiresAuth: true},
      name: "gallery-add",
      component: GalleryAdd,
    },
    {
      path: "edit/:id",
      meta: { requiresAuth: true},
      name: "gallery-edit",
      component: GalleryAdd,
    },
  ],
};
