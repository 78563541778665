const News= ()=> import('@/views/ADMIN/News.vue')
const NewsList= ()=> import('@/components/News/List.vue')
const NewsUserList= ()=> import('@/components/News/UserList.vue')
const NewsAdd= ()=> import('@/components/News/Add.vue')
const NewsAddCat= ()=> import('@/components/News/AddCategories.vue')

export const newsList = {
  path: "/noticias",
  name: "Noticias",
  meta: { requiresAuth: true},
  component: News,
  redirect: { name: "news-list" },
  children: [ {
      path: "list",
      meta: { requiresAuth: true},
      name: "news-list",
      component: NewsList
    }, {
      path: "userlist",
      meta: { requiresAuth: true},
      name: "intra-news-list",
      component: NewsUserList
    }, {
      path: "add",
      meta: { requiresAuth: true},
      name: "news-add",
      component: NewsAdd
    }, {
      path: "edit/:id",
      meta: { requiresAuth: true},
      name: "news-edit",
      component: NewsAdd
    }, {
      path: "add-categories/",
      meta: { requiresAuth: true},
      name: "news-categories",
      component: NewsAddCat
    },
  ],
};


export const intraNewsList =  {
  path: "/intra-noticias",
  meta: { requiresAuth: true},
  name: "IntraNoticias",
  component: NewsUserList
};