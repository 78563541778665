import { apolloClient } from "../../../vue-apollo";

import { } from "../../../apollo/queries";
import {
	EDIT_PASSWORD,
	VERIFY_PASSWORD
} from "../../../apollo/mutations";


export default {
	namespaced: true,
	state: {
	},

	mutations: {},
	actions: {
		async editPasswordAction(_, data_) {
			const params = {
				mutation: EDIT_PASSWORD,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params)
				if (!res.loading) response = res.data.editPasswordUsuarioUpdate;
			} catch (e) {
				console.error("Error en cambiar contraseña:", e);
			}
			return response;
		},

		async verifyPasswordAction(_, data_) {
			const params = {
				mutation: VERIFY_PASSWORD,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params)
				if (!res.loading) response = res.data.checkCurrentPasswordBoolean;
			} catch (e) {
				console.error("Error verificando contraseña:", e);
			}
			return response;
		},
	},
};