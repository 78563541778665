const TransporteMain = ()=> import( '@/views/ADMIN/Transporte.vue')
const TransporteDash = ()=> import( '@/components/Transporte/Dashboard.vue')
const TransporteTramos = ()=> import( '@/components/Transporte/MisTramos.vue')
const Planificaciones = ()=> import( '@/components/Transporte/MisPlanificaciones.vue')
const EnCurso = ()=> import( '@/components/Transporte/EnCurso.vue')
const Transportes = ()=> import( '@/components/Transporte/Transportes.vue')
const InscripcionesPersonal = ()=> import( '@/components/Transporte/InscripcionesPersonal.vue')
const InscripcionesBusqueda = ()=> import( '@/components/Transporte/InscripcionesBusqueda.vue')
const InscripcionesAsignar = ()=> import( '@/components/Transporte/InscripcionesAsignar.vue')

export const transporteList = {
  path: "/transporte",
  name: "Transporte",
  meta: { requiresAuth: true},
  component: TransporteMain,
  redirect: { name: "transporte-dashboard" },
  children: [ {
      path: "dashboard",
      meta: { requiresAuth: true},
      name: "transporte-dashboard",
      component: TransporteDash
    }, {
      path: "tramos",
      meta: { requiresAuth: true},
      name: "transporte-tramos",
      component: TransporteTramos
    }, {
      path: "planificaciones",
      meta: { requiresAuth: true},
      name: "transporte-planificaciones",
      component: Planificaciones
    }, {
      path: "en_curso",
      meta: { requiresAuth: true},
      name: "transporte-en_curso",
      component: EnCurso
    }, {
      path: "transportes",
      meta: { requiresAuth: true},
      name: "transporte-transportes",
      component: Transportes
    }, {
      path: "inscripciones",
      meta: { requiresAuth: true},
      name: "transporte-inscripciones",
      component: InscripcionesPersonal,
      children: [ {
          path: "list",
          meta: { requiresAuth: true},
          name: "transporte-inscripciones-list",
          component: InscripcionesBusqueda
        }, {
          path: "add/:id",
          meta: { requiresAuth: true},
          name: "transporte-inscripciones-add",
          component: InscripcionesAsignar
        }, {
          path: "edit/:id",
          meta: { requiresAuth: true},
          name: "transporte-inscripciones-edit",
          component: InscripcionesAsignar
        },
      ],
    },
  ],
};