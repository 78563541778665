const CanalCorporativo = () => import('@/views/ADMIN/TV.vue')
const TvDashboard = () => import('@/components/tv/TvDashboard.vue')
const HomeCanales = () => import('@/components/tv/HomeCanales.vue')
const AgregarLayout = () => import('@/components/tv/AgregarLayout.vue')
const AgregarVideo = () => import('@/components/tv/AgregarVideo.vue')
const AgregarBanner = () => import('@/components/tv/AgregarBanner.vue')
const AgregarCanal = () => import('@/components/tv/AgregarCanal.vue')
const HomePantallas = () => import('@/components/tv/HomePantallas.vue')
const AgregarPantallas = () => import('@/components/tv/AgregarPantallas.vue')

export const canalCorporativoList = {
  path: "/CanalCorporativo",
  name: "CanalCorporativo",
  meta: { requiresAuth: true},
  component: CanalCorporativo,
  redirect: { name: "CanalCorporativo-dashboard" },
  children: [ {
    path: "dashboard",
    meta: { requiresAuth: true},  
      name: "CanalCorporativo-dashboard",
      component: TvDashboard
    }, {
      path: "canales",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-canales",
      component: HomeCanales
    }, {
      path: "contenido-add/:id",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-addContenido",
      component: AgregarLayout
    }, {
      path: "contenido-edit/:id",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-editContenido",
      component: AgregarLayout
    }, {
      path: "listado-videos/:id",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-listadoVideos",
      props: true,
      component: AgregarVideo
    }, {
      path: "listado-banners/:id",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-listadoBanners",
      props: true,
      component: AgregarBanner
    }, {
      path: "listado-banners-h/:id",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-listadoBannersHorizontal",
      props: true,
      component: AgregarBanner
    }, {
      path: "canales-add",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-add-canales",
      component: AgregarCanal
    }, {
      path: "canales-edit/:id",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-edit-canales",
      props: true,
      component: AgregarCanal
    }, {
      path: "pantallas",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-pantallas",
      component: HomePantallas
    }, {
      path: "pantallas-add",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-add-pantallas",
      component: AgregarPantallas
    }, {
      path: "pantallas-edit/:id",
      meta: { requiresAuth: true},
      name: "CanalCorporativo-edit-pantallas",
      props: true,
      component: AgregarPantallas
    },
  ],
};