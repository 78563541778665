const Culture = () => import("@/views/ADMIN/Culture.vue");
const CultureAdd = () => import("@/components/Culture/Add.vue");
const CultureValorAdd = () => import("@/components/Culture/AddValor.vue");
const CultureList = () => import("@/components/Culture/List.vue");

export const cultureList = {
  path: "/nuestra-cultura",
  name: "Cultura",
  meta: { requiresAuth: true },
  component: Culture,
  redirect: { name: "culture-list" },
  children: [
    {
      path: "add",
      meta: { requiresAuth: true},
      name: "culture-add",
      component: CultureAdd,
    },
    {
      path: "addvalor/",
      meta: { requiresAuth: true},
      name: "culture-valor-add",
      component: CultureValorAdd,
    },
    {
      path: "editvalor/:id",
      meta: { requiresAuth: true},
      name: "culture-valor-edit",
      component: CultureValorAdd,
    },
    {
      path: "list",
      meta: { requiresAuth: true},
      name: "culture-list",
      component: CultureList,
    },
  ],
};
