import { EDITAR_ESTADO_SOLICITUD_HOTELERIA } from "@/apollo/mutations";
import { apolloClient } from "../../../vue-apollo";
import {
  GET_DASHBOARD_HOTELERIA,
  GET_SOLICITUDES_ADMIN_PAGINADO,
  GET_URGENCIA_SOLICITUD,
  GET_CLOSET_UBICACION,
  GET_CAMA_UBICACION,
  GET_CALIFICACION_HOTELERIA,
  GET_SERVICIOS_HOTELERIA,
  GET_DETALLES_SERVICIOS_HOTELERIA,
  GET_ALL_SOLICITUD_USUARIO,
  TOTAL_SOLICITUDES_USUARIO,
  LIMITE_ALL_SOLICITUDES_USUARIO,
  GET_SOLICITUD_USUARIO_INDIVIDUAL,
  GET_AMONESTACIONES_ID,
  GET_ESTADO_SOLICITUD_BY_EMPRESA,
  GET_SOLICITUDES_ADMIN_EXCEL,
} from "@/apollo/queries";

export default {
  namespaced: true,
  state: {
    solicitudesListPaginado: [],
    solicitudesListPaginadoHash:{},
    serviciosHoteleria: [],
    estadosHoteleria: [],
    paginaData:{}
  },

  mutations: {
    setSolicitudesListPaginado(state, data) {
      state.solicitudesListPaginadoHash = {}
      state.solicitudesListPaginado = []
      data.forEach(el => {
        state.solicitudesListPaginadoHash[el.id_solicitud] = true
        state.solicitudesListPaginado.push(el)
      });
    },
    setSolicitudesList(state, data) {
      data.forEach(el => {
        if(state.solicitudesListPaginadoHash[el.id_solicitud] === undefined){
          state.solicitudesListPaginado.push(el)
          state.solicitudesListPaginadoHash[el.id_solicitud] = true
        }
      })
    },
    setSolicitudChangeState(state, data) {
      let solicitud = state.solicitudesListPaginado.findIndex(({id_solicitud})=> id_solicitud === data.id_solicitud)
      if(solicitud >= 0){
        state.solicitudesListPaginado[solicitud] = data
      } 
    },
    setServiciosHoteleriaLista(state, data) {
      state.serviciosHoteleria = data;
    },
    setEstadosSolicitudes(state, data) {
      state.estadosHoteleria = data;
    },
    setPagina(state, data) {
      state.paginaData = data;
    }
  },

  actions: {
    async getDataHoteleria(_, data) {
      const params = {
        query: GET_DASHBOARD_HOTELERIA,
        variables: data,
      };
      let response = null;
      try {
        let hoteleriaData = null;
        response = await apolloClient.query(params);
        if (!response.loading) hoteleriaData = response.data.DashboardHoteleria;
        response = hoteleriaData;
      } catch (e) {
        console.log("Response de getDataHoteleria: ", e);
        if (
          e.networkError &&
          e.networkError.result &&
          e.networkError.result.errors
        ) {
          console.log(e.networkError.result.errors);
        } else {
          console.log(e.message);
        }
      }
      return response;
    },
    async getSolicitudesAdminPaginado({ commit }, data) {
      const params = {
        query: GET_SOLICITUDES_ADMIN_PAGINADO,
        variables: data,
        fetchPolicy: "network-only"
      };
      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.getListaSolicitudesAdminPaginado;
        commit("setPagina", response);
        if(response && data.pagina == 1) commit("setSolicitudesListPaginado", response.data);
        if(response && data.pagina > 1) commit("setSolicitudesList", response.data);
      } catch (e) {
        console.log("Response de getListaSolicitudesAdminPaginado: ", e);
        console.log(e.networkError.result.errors);
      }

      return response;
    },
    async getSolicitudesAdminExcel(_, data) {
      const params = {
        query: GET_SOLICITUDES_ADMIN_EXCEL,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.solicitudesHoteleriaExcel;
        return data
      } catch (e) {
        console.log("Response de solicitudesHoteleriaExcel: ", e);
        console.log(e.networkError.result.errors);
      }
    },

    async getEstadosSolicitud({ commit }, data) {
      const params = {
        query: GET_ESTADO_SOLICITUD_BY_EMPRESA,
        variables: data,
      };
      await apolloClient
        .query(params)
        .then((response) => {
          response.data.getEstadoByIdEmp && commit(
            "setEstadosSolicitudes",
            response.data.getEstadoByIdEmp.map(
              ({ color, id_estado, nombre_estado, __typename }) => ({
                color,
                id: Number(id_estado),
                label: nombre_estado,
                type: __typename,
              })
            )
          );
          response.data.getEstadoByIdEmp == null && commit("setEstadosSolicitudes", []);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getServiciosHoteleria({ commit }, data) {
      const params = {
        query: GET_SERVICIOS_HOTELERIA,
        variables: data,
      };
      let response = null;
      try {
        response = await apolloClient.query(params);
        response && commit(
          "setServiciosHoteleriaLista",
          response.data.getServiciosIdEmp.map(
            ({
              id_servicio,
              nombre_servicio,
              descripcion_servicio,
              imagen_servicio,
              __typename,
            }) => ({
              id: Number(id_servicio),
              label: nombre_servicio,
              desc: descripcion_servicio,
              img: imagen_servicio,
              type: __typename,
            })
          )
        );
        !response && commit("setServiciosHoteleriaLista", []);
      } catch (e) {
        console.log("Response de getServiciosIdEmp: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getUrgenciaSolicitud(_, data) {
      const params = {
        query: GET_URGENCIA_SOLICITUD,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) response = response.data.getUrgenciaIdEmp;
        response = data;
      } catch (e) {
        console.log("Response de getUrgenciaIdEmp: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async changeState({ commit }, data) {
      for (const i in data) {
          const element = data[i];
          const params = {
            mutation: EDITAR_ESTADO_SOLICITUD_HOTELERIA,
            variables: element,
          };
          console.log(params);
          let response = null;
          try {
            response = await apolloClient.mutate(params);

            response && commit("setSolicitudChangeState",response.data.UpdateEstadoSolicitudHoteleria)
          } catch (e) {
            console.log("Response de changeState: ", e);
          }

      }
      
    },	
    async getClosetUbicacion(_, data) {
      const params = {
        query: GET_CLOSET_UBICACION,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) response = response.data.getClosetIdEmp;
        response = data;
      } catch (e) {
        console.log("Response de getClosetIdEmp: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getCamaUbicacion(_, data) {
      const params = {
        query: GET_CAMA_UBICACION,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) response = response.data.getCamaIdEmp;
        response = data;
      } catch (e) {
        console.log("Response de getCamaIdEmp: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getCalificacionHoteleria(_, data) {
      const params = {
        query: GET_CALIFICACION_HOTELERIA,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) response = response.data.getCalificacionIdEmp;
        response = data;
      } catch (e) {
        console.log("Response de getCalificacionIdEmp: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getDetallesServiciosHoteleria(_, data) {
      const params = {
        query: GET_DETALLES_SERVICIOS_HOTELERIA,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) response = response.data.getServicioDetalleIdEmp;
        response = data;
      } catch (e) {
        console.log("Response de getServicioDetalleIdEmp: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getSolicitudesUsuario(_, data) {
      const params = {
        query: GET_ALL_SOLICITUD_USUARIO,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading)
          response = response.data.getAllSolicitudesUsuario;
        response = data;
      } catch (e) {
        console.log("Response de getAllSolicitudesUsuario: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getTotalSolicitudesUsuario(_, data) {
      const params = {
        query: TOTAL_SOLICITUDES_USUARIO,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) response = response.data.totalSolicitudesUsuario;
        response = data;
      } catch (e) {
        console.log("Response de totalSolicitudesUsuario: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async limiteSolicitudesUsuario(_, data) {
      const params = {
        query: LIMITE_ALL_SOLICITUDES_USUARIO,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading)
          data = response.data.getAllSolicitudesUsuarioLimit;
        response = data;
      } catch (e) {
        console.log("Response de getAllSolicitudesUsuarioLimit: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getSolicitudUsuario(_, data) {
      const params = {
        query: GET_SOLICITUD_USUARIO_INDIVIDUAL,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) response = response.data.getOneSolicitudUsuario;
        response = data;
      } catch (e) {
        console.log("Response de getOneSolicitudUsuario: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getAmonestaciones(_, data) {
      const params = {
        query: GET_AMONESTACIONES_ID,
        variables: data,
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) response = response.data.getAmonestaciones;
        response = data;
      } catch (e) {
        console.log("Response de getAmonestaciones: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },
  },
};
