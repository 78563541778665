const AlertaPush = () => import("@/views/ADMIN/AlertaPush.vue");
const AlertaPushList = () => import("@/components/AlertaPush/ListFilter.vue");
const AlertaPushAdd = () => import("@/components/AlertaPush/Add.vue");

export const alertPushList = {
  path: "/alerta-push",
  name: "Push",
  meta: { requiresAuth: true },
  component: AlertaPush,
  redirect: { name: "push-list" },
  children: [
    {
      path: "list",
      meta: { requiresAuth: true},
      name: "push-list",
      component: AlertaPushList,
    },
    {
      path: "add",
      meta: { requiresAuth: true},
      name: "push-add",
      component: AlertaPushAdd,
    },
    {
      path: "edit/:id",
      meta: { requiresAuth: true},
      name: "push-edit",
      component: AlertaPushAdd,
    },
  ],
};
