import Vue from 'vue'
import VueApollo from 'vue-apollo'

import { InMemoryCache } from 'apollo-cache-inmemory'

import { split, ApolloLink, concat } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from 'apollo-utilities'

import { ApolloClient } from 'apollo-client';

import { onError } from '@apollo/client/link/error';
import { EventBus } from '@/eventBus';

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

const httpLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP
});

const wsLink = new GraphQLWsLink(createClient({
    url: process.env.VUE_APP_GRAPHQL_WSS,
    options: {
        reconnect: true,
    },
    connectionParams: () => ({
        'access-token': localStorage.getItem('access-token') || '',
    }),
    retryAttempts: 20
}));

const splitLink = split(({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
}, wsLink, httpLink);   


const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'access-token': localStorage.getItem('access-token') || '',
        }
    }));
    return forward(operation);
});

const logoutLink = onError((error) => {
    if (error.graphQLErrors && error.graphQLErrors[0].extensions.status === 500) EventBus.$emit("failedServerResponse");
    if (error.networkError.statusCode === 401) EventBus.$emit("logout");
    if (error.networkError.statusCode === 403) EventBus.$emit("logout");
    if (error.networkError.statusCode === 404) EventBus.$emit("logout");
});

const tempLink = concat(authMiddleware, splitLink);
  

// Config
const defaultOptions = {
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    link: logoutLink.concat(tempLink), /* authLink */
    defaultHttpLink: false,

    // Override default cache
    cache: new InMemoryCache({})

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: { ... }
    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
}

export const apolloClient = new ApolloClient({ ...defaultOptions })

var loading = 0;

// Create vue apollo provider
export const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
        $query: {
            loadingKey: 'loading',
            fetchPolicy: 'cache-and-network',
        },
    },
    // Watch loading state for all queries
    // See 'Smart Query > options > watchLoading' for detail

    watchLoading(isLoading, countModifier) {
        loading += countModifier
        console.log('Global loading', loading, countModifier)
    },
    errorHandler(error) {
        // eslint-disable-next-line no-console
        console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
})

// -------------- apollo client 2 --------------------

const httpLinkCC = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_WSS_HTTP
});
const splitLinkCC = split(({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
}, wsLink, httpLinkCC);   

const tempLinkCC = concat(authMiddleware, splitLinkCC);
  
const defaultOptionsCC = {
    tokenName: AUTH_TOKEN,
    persisting: false,
    websocketsOnly: false,
    ssr: false,
    link: logoutLink.concat(tempLinkCC),
    defaultHttpLink: false,
    cache: new InMemoryCache({}),
    connectToDevTools: true
};

export const apolloClientCC = new ApolloClient({ ...defaultOptionsCC });

