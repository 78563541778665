import { apolloClient } from "../../../vue-apollo";
import {
  GET_SEGMENTACION,
  GET_SEGMENTACION_ELEMENTOS,
  REPORTE_ENCUESTAS_ADMIN,
  PAGINAS_ENCUESTAS_ADMIN,
  GET_ENCUESTAS_PAGINADAS,
  GET_ESTADOS_ENCUESTA,
  GRAFICO_ENCUESTA,
  GET_ENCUESTA_ID
} from "@/apollo/queries";
import {
  CREATE_ENCUESTA,
  EDIT_ENCUESTA_ID,
  DELETE_ENCUESTA
} from "@/apollo/mutations"
export default {
  namespaced: true,
  state: {
    encuestas_list: [],
    encuesta_add: [],
    segmentaciones_list: [],
    segmentaciones_filtradas: [],
    reporte: {},
    paginas_encuestas: [],
    estados: [],
    preguntasDeleted: [],
    encuesta: { preguntas: [] }
  },
  getters: {
    getEncuestaById: (state) => (id_encuesta) => {
      const { encuestas_list } = state;
      const encuesta = encuestas_list.find(
        (survey) => survey.id_encuesta == id_encuesta
      );

      return encuesta;
    },
  },
  mutations: {
    GET_ENCUESTAS(state, encuesta) {
      state.encuestas_list = encuesta;
    },
    GET_SEGMENTACIONES(state, segmentacion) {
      state.segmentaciones_list = segmentacion;
    },
    GET_SEGMENTACIONES_FILTRADAS(state, filtrosPush) {
      state.segmentaciones_filtradas = filtrosPush;
    },
    EDIT_ENCUESTA(state, encuesta) {
      let index = state.encuestas_list.findIndex(
        e => e.id_encuesta == encuesta.id_encuesta
      );
      state.encuestas_list[index] = encuesta;
    },
    CREATE_ENCUESTA(state, encuesta) {
      state.encuestas_list.unshift(encuesta);
    },
    GET_REPORTE(state, data) {
      state.reporte = data;
    },
    DELETE_ENCUESTA(state, data) {
      let index = state.encuestas_list.findIndex(
        (e) => e.id_encuesta === data.id_encuesta
      );
      state.encuestas_list.splice(index, 1);
    },
    SET_PAGINAS_ENCUESTA(state, data) {
      state.paginas_encuestas = data;
    },
    SET_ESTADOS_ENCUESTA(state, data) {
      state.estados = data;
    },
    ADD_PREGUNTA(state, pregunta) {
      state.encuesta.preguntas.push(pregunta);
    },
    SET_ENCUESTA(state, data) {
      state.encuesta = data;
      state.preguntasDeleted = [];
    },
    CLEAN_ENCUESTA(state) {
      state.encuesta = { preguntas: [] };
    },
    DELETE_PREGUNTA_BY_INDEX(state, index) {
      const removed = state.encuesta.preguntas.splice(index, 1)[0];
      removed.pregunta = "delete";
      removed.opciones = [];
      state.preguntasDeleted.push(removed);
    },
    UPDATE_PREGUNTA(state, { index, pregunta }) {
      // Ensure the index is within the array bounds
      if (index >= 0 && index < state.encuesta.preguntas.length) {
        state.encuesta.preguntas[index] = pregunta;
      }
    }

  },
  actions: {
    async crearEncuesta({ commit }, data_) {
      const params = {
        mutation: CREATE_ENCUESTA,
        variables: data_
      };
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.crearEncuesta;
        commit("CREATE_ENCUESTA", response);
      } catch (e) {
        console.log("Falla metodo crearEncuesta: ", e);
      }
      return response;
    },
    async editarEncuesta({ commit, state }, data_) {
      data_.preguntas = data_.preguntas.concat(state.preguntasDeleted);
      let params = {
        mutation: EDIT_ENCUESTA_ID,
        variables: data_
      }
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.editarEncuesta;
        commit("EDIT_ENCUESTA", response);
      } catch (e) {
        console.log("Falla metodo editarEncuesta: ", e);
      }
      return response;
    },

    async getPaginasEncuestas({ commit }, data_) {
      let response = null;
      let params = {
        query: PAGINAS_ENCUESTAS_ADMIN,
        variables: data_,
        fetchPolicy: 'network-only'
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.PaginasEncuestasAdmin;
        });
      } catch (e) {
        console.log("Falla error, getPaginasEncuestas ", e);
      }
      return new Promise((resolve) => {
        if (response != null) {
          if (response.length == 0) {
            commit("SET_PAGINAS_ENCUESTA", response);
            resolve(false);
          } else {
            commit("SET_PAGINAS_ENCUESTA", response);
            resolve(response);
          }
        } else {
          resolve(false);
        }
      });
    },

    async getEncuestasList({ commit }, data_) {
      let response = null;
      let params = {
        query: GET_ENCUESTAS_PAGINADAS,
        variables: data_,
        fetchPolicy: 'network-only'
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.LimitEncuestasAdmin;
        });
      } catch (e) {
        console.log("Falla error, getEncuestasList ", e);
      }
      return new Promise((resolve) => {
        if (response != null) {
          if (response.length == 0) {
            commit("GET_ENCUESTAS", response);
            resolve(false);
          } else {
            commit("GET_ENCUESTAS", response);
            resolve(response);
          }
        } else {
          resolve(false);
        }
      });
    },

    // metodo de paginacion y envia los datos correspondiente a muro según pagina, esto depreca metodo de getEncuestasList
    async getEncuestasPaginasCompleto({ dispatch, commit }, payload) {
      let response = null;
      const paginatedEncuesta = await dispatch("getPaginasEncuestas", payload.data_);
      let pagina = payload.pagina;
      let id_encuesta = [];
      if (paginatedEncuesta) {
        paginatedEncuesta.forEach((data) => {
          if (data.pagina == pagina) {
            id_encuesta = data.reportes;
          }
        });
      }
      //console.log("dato de paginacion: ", paginatedReconocimiento);
      let data_ = {
        id_empresa: payload.data_.id_empresa,
        id_encuesta: id_encuesta
      }
      let params = {
        query: GET_ENCUESTAS_PAGINADAS,
        variables: data_,
        fetchPolicy: 'network-only'
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.LimitEncuestasAdmin;
        });
      } catch (e) {
        console.log("Falla error, getPostPaginasPostCompleto ", e);
      }
      return new Promise((resolve) => {
        if (response != null) {
          if (response.length == 0) {
            commit("GET_ENCUESTAS", response);
            resolve(false);
          } else {
            commit("GET_ENCUESTAS", response);
            resolve(response);
          }
        } else {
          resolve(false);
        }
      });
    },
    // borrar encuesta NUEVO METODO
    async deleteEncuesta({ commit }, data_) {
      let response = null;
      let data = [];
      let params = {
        mutation: DELETE_ENCUESTA,
        variables: data_
      }
      try {
        await apolloClient.mutate(params).then(res => {
          if (!res.loading) data = res.data.eliminarEncuesta;
          response = data;
        });
      } catch (e) {
        console.log("Falla metodo deleteEncuesta: ", e);
      }
      return new Promise((resolve) => {
        if (response != null) {
          commit("DELETE_ENCUESTA", response);
          resolve(response);
        } else {
          resolve(false);
        }
      });
    },
    // Obtener estados de encuesta NUEVO METODO
    async getEstadosEncuestas({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
        query: GET_ESTADOS_ENCUESTA,
        variables: data_
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.CategoriasEncuestas;
          response = data;
        });
      } catch (e) {
        console.log("listar getEstadosEncuestas falla: ", e);
        response = null
      }
      return new Promise((resolve) => {
        if (response != null) {
          commit("SET_ESTADOS_ENCUESTA", response);
          resolve(response)
        } else {
          resolve(null)
        }
      });
    },

    async getSegmentacionesStore({ commit }, segmentaciones_query) {
      const { data } = await apolloClient.query({
        query: GET_SEGMENTACION,
        variables: {
          id_elementos_segmentacion: segmentaciones_query.id_elementos_segmentacion,
          nombre_segmentacion: segmentaciones_query.nombre_segmentacion,
        },
      });
      commit("GET_SEGMENTACIONES", data.tipoSegmentacion);
    },
    
    async getFiltrosSegmentaciones({ commit }, id_elementos_segmentacion) {
      const { data } = await apolloClient.query({
        query: GET_SEGMENTACION_ELEMENTOS,
        variables: {
          idEmpresa: id_elementos_segmentacion.idEmpresa,
        },
      });
      commit("GET_SEGMENTACIONES_FILTRADAS", data.FiltrosPush);
    },

    async getDataEncuesta(_, data_) {
      const params = {
        query: REPORTE_ENCUESTAS_ADMIN,
        variables: data_
      };

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.reportesEncuestasAdmin;
      } catch (error) {
        console.log('Error en getDataEncuesta:', error);
      }

      return response;
    },

    async getDataGraficoEncuesta({ commit }, data_) {
      const params = {
        query: GRAFICO_ENCUESTA,
        variables: data_
      };

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.GraficoEncuesta;
        commit("GET_REPORTE", response);
      } catch (error) {
        console.log('Error en getDataGraficoEncuesta:', error);
      }

      return response;
    },

    addPreguntaToEncuesta({ commit }, pregunta) {
      commit("ADD_PREGUNTA", pregunta);
    },
    updateQuestionInEncuesta({ commit }, pregunta) {
      commit('UPDATE_PREGUNTA', pregunta);
    },

    setEncuesta({ commit }, encuesta) {
      commit("SET_ENCUESTA", encuesta);
    },
    
    async getEncuestaById({ commit }, payload) {
      const params = {
        query: GET_ENCUESTA_ID,
        variables: payload,
        fetchPolicy: 'network-only'
      };

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.encuestaIdAdmin;
        commit("SET_ENCUESTA", response);
      } catch (error) {
        console.log('Error en getEncuestaById:', error);
      }

      return response;    
    },
    cleanEncuesta({ commit }) {
      commit("CLEAN_ENCUESTA");
    },
    deletePreguntaByIndex({ commit }, index) {
      commit("DELETE_PREGUNTA_BY_INDEX", index);
    }
  },
};