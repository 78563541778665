import LoginToken from '@/views/LoginToken.vue'
import Login from '@/views/Login.vue'

export const loginTokenRoute = {
    path: "/Login/:token/:key",
    name: "Login-token",
    component: LoginToken
};

export const loginRoute = {
    path: "/Login/",
    name: "Login",
    component: Login
};