import { apolloClient } from "../../../vue-apollo";

import {
	GET_USER,
	GET_USERS,
	GET_USER_PAGINATION,
	GET_USER_TOTAL_PAGINACION,
	GET_SINDICATO,
	GET_CARGOS,
	GET_GERENCIAS,
	GET_SUCURSALES,
	GET_GROUPS,
	GET_SOCIETIES,
	GET_NATIONS,
	GET_WORK_SHIFTS,
	CHECK_EMAIL_RUT,
	GET_PRIVILEGIOS
} from "../../../apollo/queries";
import {
	ADD_USER,
	DELETE_USER,
	EDIT_USER,
	ADD_BULK_LOAD,
	EDIT_BULK_LOAD,
	RESET_USER_PASSWORD
} from "../../../apollo/mutations";


export default {
	namespaced: true,
	state: {
		users_list: [],
		paginationArr: [],
		userArrForCurrentPage: [],
		positionArr: [],
		areaArr: [],
		workplaceArr: [],
		shiftArr: [],
		societyArr: [],
		syndicateArr: [],
		nationArr: [],
		groupArr: [],
		privilegesArr: [],
		user_edit: {}
	},

	mutations: {
		setPositions(state, resp) {
			state.positionArr = resp.map(p => { return { value: p.id_cargo, label: p.nombre_cargo } });
		},
		setAreas(state, resp) {
			state.areaArr = resp.map(a => { return { value: a.id_gerencia, label: a.nombre_gerencia } });
		},
		setSucursales(state, resp) {
			state.workplaceArr = resp.map(w => { return { value: w.id_sucursal, label: w.nombre_sucursal } });
		},
		setShifts(state, resp) {
			state.shiftArr = resp.map(s => { return { value: s.id_turno, label: s.nombre_turno } });
		},
		setSocieties(state, resp) {
			state.societyArr = resp.map(s => { return { value: s.id_sociedad, label: s.nombre_sociedad } });
		},
		setSyndicates(state, resp) {
			state.syndicateArr = resp.map(s => { return { value: s.id_sindicato, label: s.nombre_sindicato } });
		},
		setNations(state, resp) {
			state.nationArr = resp.map(n => { return { value: n.id_nacion, label: n.nombre_nacion } });
		},
		setGroups(state, resp) {
			state.groupArr = resp.map(g => { return { value: g.id_grupo, label: g.nombre_grupo } });
		},
		setPrivilegesList(state, resp) {
			state.privilegesArr = resp;
		},
		setUsersByPagination(state, resp) {
			state.userArrForCurrentPage = resp;
		},
		setPagination(state, resp) {
			state.paginationArr = resp;
		},
		setUsers(state, resp) {
			if (state.users_list.length < 1) {
				state.users_list = resp;
			}
		},
		deleteUserFromList(state, user_id) {
			console.log('SE BORRA DE LA LISTA', user_id);
			const found_index = state.userArrForCurrentPage.findIndex(user => user.id_usuario == user_id);
			state.userArrForCurrentPage.splice(found_index, 1);
		},
		editUserList(state, user) {
			console.log('USUARIO', user);
			const user_index = state.userArrForCurrentPage.findIndex(user_ => user_.id_usuario == user.id_usuario);
			if (user_index != undefined) {
				state.userArrForCurrentPage.splice(user_index, 1);
				state.userArrForCurrentPage.unshift(user);
			}
		},
		addUserToList(state, user) {
			state.userArrForCurrentPage.unshift(user);
		},
		SET_USER(state, user) {
			Object.assign(state.user_edit, user);
		}
	},
	actions: {
		async addBulkLoadAction(_, data_) {
			const params = {
				mutation: ADD_BULK_LOAD,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.cargaMasivaUpdated;
			} catch (e) {
				console.error("Error en carga masiva de usuarios:", e);
			}

			return response;
		},

		async editBulkLoadAction(_, data_) {
			const params = {
				mutation: EDIT_BULK_LOAD,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.updateNominaMasiva;
			} catch (e) {
				console.error("Error editando carga masiva de usuarios:", e);
			}

			return response;
		},

		// Obtener usuarios
		async getUsersList({ commit }, id) {
			const { data } = await apolloClient.query({
				query: GET_USERS,
				variables: { id_empresa: id.empresa, id_usuario: id.usuario },
			});
			commit("setUsers", data.usuarios);
		},
		async getUserPagination({ commit }, data_) {
			const params = {
				query: GET_USER_PAGINATION,
				variables: data_,
                cachepolicy: 'network-only'
			};

			let response = null;
			try {
				const res = await apolloClient.query(params)
				if (!res.loading) response = res.data.TotalUsuariosPaginacion;
			} catch (e) {
				console.error("Error obteniendo paginación:", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("setPagination", response);
				} else {
					resolve(false);
				}
			});
		},

		async createUserAction({ commit }, data_) {
			const params = {
				mutation: ADD_USER,
				variables: data_,
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.addUsuario;
				commit("addUserToList", response);
			} catch (e) {
				console.error("Error al crear nuevo usuario:", e);
				// console.log(e.networkError.result.errors);
			}

			return response;
		},

		async getUsersInfoByIds({ commit }, data_) {
			const params = {
				query: GET_USER_TOTAL_PAGINACION,
				variables: data_,
				fetchPolicy: 'network-only'
			};

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.listUsersPaginacion;
			} catch (e) {
				console.error("Error al obtener lista de usuarios:", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("setUsersByPagination", response);
				} else {
					resolve(false);
				}
			});
		},

		// eslint-disable-next-line no-unused-vars
		async checkEmailRutAction(_, data_) {
			const params = {
				query: CHECK_EMAIL_RUT,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.CheckEmailRut.check;
			} catch (e) {
				console.error("Error en chequeo de email/rut");
			}

			return response;
		},

		async getPrivileges({ commit }, data_) {
			const params = {
				query: GET_PRIVILEGIOS,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.privilegios;
				commit("setPrivilegesList", response);
			} catch (e) {
				console.error("Error en obtención de lista de privilegios");
			}
			return response;
		},
		async getPositionsQuery({ commit, state }, data_) {
			const params = {
				query: GET_CARGOS,
				variables: data_
			};

			let response = [];
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.cargos;
				commit("setPositions", response);
			} catch (e) {
				console.error("Error en la obtención de cargos:", e);
			}
			return state.positionArr;
		},
		async getAreasQuery({ commit, state }, data_) {
			const params = {
				query: GET_GERENCIAS,
				variables: data_
			};

			let response = [];
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.gerencias;
				commit("setAreas", response);
			} catch (e) {
				console.error("Error en la obtención de gerencias:", e);
			}
			return state.areaArr;
		},
		async getSucursalesAction({ commit, state }, data_) {
			const params = {
				query: GET_SUCURSALES,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.sucursales;
				commit("setSucursales", response);
			} catch (e) {
				console.error("Error en la obtención de sucursales:", e);
			}
			return state.workplaceArr;
		},
		async getShiftsQuery({ commit, state }, data_) {
			const params = {
				query: GET_WORK_SHIFTS,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.turnos;
				commit("setShifts", response);
			} catch (e) {
				console.error("Error en la obtención de turnos:", e);
			}
			return state.shiftArr;
		},
		async getSocietiesQuery({ commit, state }, data_) {
			const params = {
				query: GET_SOCIETIES,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.sociedades;
				commit("setSocieties", response);
			} catch (e) {
				console.error("Error en la obtención de sociedades:", e);
			}
			return state.societyArr;
		},
		async getSyndicatesQuery({ commit, state }, data_) {
			const params = {
				query: GET_SINDICATO,
				variables: data_
			};
			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.sindicatos;
				commit("setSyndicates", response);
			} catch (e) {
				console.error("Error en obtención de sindicatos:", e);
			}
			return state.syndicateArr;
		},
		async getNationsQuery({ commit, state }, data_) {
			const params = {
				query: GET_NATIONS,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.naciones;
				commit("setNations", response);
			} catch (e) {
				console.error("Error en obtención de nacionalidades:", e);
			}
			return state.nationArr;
		},
		async getGroupsQuery({ commit, state }, data_) {
			const params = {
				query: GET_GROUPS,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.listGruposAcciones;
				commit("setGroups", response);
			} catch (e) {
				console.error("Error en obtención de grupos:", e);
			}
			return state.groupArr;
		},
		async deleteUserAction({ commit }, data_) {
			const params = {
				mutation: DELETE_USER,
				variables: data_
			}
			let response = null;

			try {
				let data = null;
				const res = await apolloClient.mutate(params);
				if (!res.loading) data = res.data.eliminarUsuario;
				response = data.id_usuario;
			} catch (e) {
				console.error("Error eliminar usuario:", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("deleteUserFromList", response);
				} else {
					resolve(false);
				}
			});
		},

		async editUserAction({ commit }, data_) {
			const params = {
				mutation: EDIT_USER,
				variables: data_
			};

			let response = null;
			try {
				let data = null;
				const res = await apolloClient.mutate(params);
				if (!res.loading) data = res.data.editUsuario;
				response = data;
			} catch (e) {
				console.error("Error editando usuario:", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("editUserList", response);
				} else {
					resolve(false);
				}
			});
		},

		async resetUserPasswordAction(_, data_) {
			const params = {
				mutation: RESET_USER_PASSWORD,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params)
				if (!res.loading) response = res.data.resetPassword;
			} catch (e) {
				console.error("Error en restablecer contraseña:", e);
			}
			return response;
		},

		async getUserAction({ commit, state }, data_) {
			const params = {
				query: GET_USER,
				variables: data_,
				fetchPolicy: 'network-only'
			};

			let response = null;
			try {
				const res = await apolloClient.query(params)
				if (!res.loading) response = res.data.usuario;
				commit("SET_USER", response);
			} catch (e) {
				console.error("Error en obtención del usuario:", e);
			}
			return state.user_edit;
		},
	},
};