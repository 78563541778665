const Customization = () => import("@/views/ADMIN/customization/Customization.vue");
const CustomizationColors = () => import("@/views/ADMIN/customization/ColorsView.vue");
const CustomizationModules = () => import("@/views/ADMIN/customization/ModulesView.vue");
const CustomizationMenu = () => import("@/views/ADMIN/customization/MenuView.vue");

export const customization = {
  path: "/customization",
  name: "Personalización",
  meta: { requiresAuth: true },
  component: Customization,
  redirect: { name: "Logotipos y colores" },
  children: [
    {
      path: "colors",
      meta: { requiresAuth: true},
      name: "Logotipos y colores",
      component: CustomizationColors,
    },
    {
      path: "modules",
      meta: { requiresAuth: true},
      name: "Gestión de módulos",
      component: CustomizationModules,
    },
    {
      path: "menu",
      meta: { requiresAuth: true},
      name: "Menú personalizado",
      component: CustomizationMenu,
    },
  ],
};
