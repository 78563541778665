import { apolloClient } from "../../../vue-apollo";
import {
  LIST_MODULOS_EMPRESAS,
  BUSCAR_MENU,
  GET_INTERFAZ
} from "@/apollo/queries";
import {
  EDIT_MODULOS,
  EDIT_INTERFAZ,
  ACTUALIZAR_MENU
} from "@/apollo/mutations";

export default {
  namespaced: true,
  state: {
    listaModulosActivos: [],
    listaModulosDesactivados: [],
    menu: [],
    selectedMenuGeneral: { id_header: null, nombre_header: '', color_activo: '' }
  },

  mutations: {
    setListaModulos(state, modulos) {
      state.listaModulosActivos = modulos.filter(mod => mod.id_estado_fk === 1);
      state.listaModulosDesactivados = modulos.filter(mod => mod.id_estado_fk !== 1);
    },

    editListaModulos(state, modulos) {
      modulos
      // state.listaModulosActivos = modulos.filter(mod => mod.id_estado_fk === "1");
      // state.listaModulosDesactivados = modulos.filter(mod => mod.id_estado_fk !== "1");
    },

    setMenu(state, menu) {
      state.menu = menu;
    },

    setSelectedMenuGeneral(state, menuGeneral) {
      state.selectedMenuGeneral = menuGeneral
    },
    cleanSelectedMenuGeneral(state) {
      state.selectedMenuGeneral = { id_header: null, nombre_header: '', color_activo: '' };
    }
  },

  actions: {
    async getListaModulos({ commit }) {
      const params = {
        query: LIST_MODULOS_EMPRESAS
      }

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.listModulosEmpresas;
        commit("setListaModulos", response);
      } catch (e) {
        console.log("Falla error, getListaModulos ", e);
      }
      return response;
    },

    async getMenu({ commit }) {
      const params = {
        query: BUSCAR_MENU
      }

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.buscarMenu;
        commit("setMenu", response);
      } catch (e) {
        console.log("Falla error, buscarMenu ", e);
      }
      return response;
    },

    async getInterfaz() {
      const params = {
        query: GET_INTERFAZ
      }

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.getInterfaz;
      } catch (e) {
        console.log("Falla error, getInterfaz ", e);
      }
      return response;
    },

    async editInterfaz(_, payload) {
      const params = {
        mutation: EDIT_INTERFAZ,
        variables: payload
      }

      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.editInterfaz;
      } catch (e) {
        console.log("Falla error, editInterfaz ", e);
      }
      return response;
    },

    async actualizarMenu(_, payload) {
      const params = {
        mutation: ACTUALIZAR_MENU,
        variables: payload
      }

      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.actualizarMenu;
      } catch (e) {
        console.log("Falla error, actualizarMenu ", e);
      }
      return response;
    },

    async editModulos({ commit }, payload) {
      const params = {
        mutation: EDIT_MODULOS,
        variables: payload
      }

      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.editModulosEmpresas;
        commit("editListaModulos");
      } catch (e) {
        console.log("Falla error, editModulos ", e);
      }
      return response;
    },

    setSelectedMenuGeneral({ commit }, menuGeneral) {
      commit("setSelectedMenuGeneral", menuGeneral);
    },
    cleanSelectedMenuGeneral({ commit }) {
      commit("cleanSelectedMenuGeneral");
    }
  }
};