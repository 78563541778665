import { apolloClient } from "../../../vue-apollo";
import {
    GET_ESTADISTICAS_USABILIDAD,
    GET_ESTADISTICAS,
    TOP_NOTICIAS,
    TOP_GALERIAS,
    TOP_VIDEO,
    ESTADISTICAS_GENERALES_MODULOS,
    GET_DATA_EXCEL_ESTADISTICAS_TOTAL,
    GET_GRAFICO_USUARIO_SESION,
    GET_DATA_EXCEL_HOME,
    GET_YEAR_DASHBOARD
} from "@/apollo/queries";

export default {
    namespaced: true,

    state: {
        statisticsModule: {},
        usuario: {},
        topNews: [],
        topGallery: [],
        topVideo: [],
        generalStatistics: [],
        usability: [],
        percentageUsers: [],
        dataExcel: [],
        years_dashboard: [],
        dataExcelTotal: []
    },
    mutations: {
        STATISTICS_MODULE(state, data) {
            state.statisticsModule = data;
        },
        TOP_NEWS(state, data) {
            if (data.length > 5) {
                state.topNews = data.slice(0, 5);
            } else {
                state.topNews = data;
            }
        },
        TOP_GALLERY(state, data) {
            if (data.length > 5) {
                state.topGallery = data.slice(0, 5);
            } else {
                state.topGallery = data;
            }
        },
        TOP_VIDEO(state, data) {
            if (data.length > 5) {
                state.topVideo = data.slice(0, 5);
            } else {
                state.topVideo = data;
            }
        },
        GENERAL_STATISTICS(state, data) {
            state.generalStatistics = data;
        },
        FILTER_USABILIY(state, data) {
            state.usability = data;
        },
        SET_DATA_EXCEL(state, data) {
            state.dataExcel = data;
        },
        SET_YEAR_DASHBOARD(state, data){
            state.years_dashboard = data.map(yearObj => yearObj.year);
        },
        SET_DATA_TOTAL_EXCEL(state, data){
            state.dataExcelTotal = data;
        }

    },

    actions: {
        async getEstadisticasUsabilidad(_, data_) {
            let params = {
                query: GET_ESTADISTICAS_USABILIDAD,
                variables: data_,
                fetchPolicy: "network-only"
            };
            let response = null;
            try {
                const res = await apolloClient.query(params);
                if (!res.loading) response = res.data.DashboardEmpresaYear;
            } catch(e) {
                console.log("error getEstadisticasUsabilidad:", e);
            }
            return response;
        },

        async getStatisticsModule({ commit }, data_) {
            let response = [];
            await apolloClient.query({
                query: GET_ESTADISTICAS,
                variables: data_,
                fetchPolicy: 'network-only'
            }).then(res => {
                if (!res.loading) response = res.data.ModuloEstadisticaInd;
            });
            commit("STATISTICS_MODULE", response);
        },

        async getTopNews({ commit }, data_) {
            const params = {
                query: TOP_NOTICIAS,
                variables: data_
            };
            let response = null;
            try {
                const res = await apolloClient.query(params);
                if (!res.loading) response = res.data.noticiasTopVistasPeriodo;
                commit("TOP_NEWS", response);
            } catch(e) {
                console.log("error getTopNews:", e);
            }
            return response;
        },

        async getTopGallery({ commit }, data_) {
            const params = {
                query: TOP_GALERIAS,
                variables: data_
            };
            let response = null;
            try {
                const res = await apolloClient.query(params);
                if (!res.loading) response = res.data.galeriasTopPeriodo;
                commit("TOP_GALLERY", response);
            } catch(e) {
                console.log("error getTopGallery:", e);
            }
            return response;
        },

        async getTopVideo({ commit }, data_) {
            const params = {
                query: TOP_VIDEO,
                variables: data_
            };
            let response = null;
            try {
                const res = await apolloClient.query(params);
                if (!res.loading) response = res.data.videosTopPeriodo;
                commit("TOP_VIDEO", response);
            } catch(e) {
                console.log("error getTopVideo:", e);
            }
            return response;
        },

        async getGeneralStatistics({ commit }, data_) {
            const params = {
                query: ESTADISTICAS_GENERALES_MODULOS,
                variables: data_
            };
            let response = null;
            try {
                const res = await apolloClient.query(params);
                if (!res.loading) response = res.data.EstadisticasGeneralesModulosFilter;
                commit("GENERAL_STATISTICS", response);
            } catch(e) {
                console.log("listar getGeneralStatistics falla: ", e);
            }
            return response;
        },

        async getGraficoUsuariosSesion({ commit }, data_) {
            const params = {
                query: GET_GRAFICO_USUARIO_SESION,
                variables: data_
            };
            let response = null;
            try {
                const res = await apolloClient.query(params);
                if (!res.loading) response = res.data.GetGraficoUsuariosSesion.datos_grafico;
                commit("FILTER_USABILIY", response);
            } catch(e) {
                console.log("listar getGraficoUsuariosSesion falla: ", e);
            }
            return response;
        },

        async getDataForExcel({ commit }, data_) {
            let response = [];
            await apolloClient.query({
                query: GET_DATA_EXCEL_HOME,
                variables: data_,
                cachepolicy: 'network-only'
            }).then(res => {
                response = res.data.ListUsuariosSesion
            });
            commit("SET_DATA_EXCEL", response);
        },
        
        async getDataForExcelTotal({ commit }, data_){
            let response = {};
            let data = {};
            let params = {
                query: GET_DATA_EXCEL_ESTADISTICAS_TOTAL,
                variables: data_
            }
            try {
                await apolloClient.query(params).then(res => {
                if (!res.loading) data = res.data.MetricasExcel;
                response = data;
                });
            } catch(e) {
                console.log("listar getDataForExcelTotal falla: ", e);
                response = null;
            }
            return new Promise((resolve)=>{
                if(response  != null){
                    commit("SET_DATA_TOTAL_EXCEL", response);
                    resolve(response)
                } else {
                    resolve(null)
                }
            });
        },
        //Metodo para solicitar las fechas asociadas a la empresa 
        async getYearDashboard({ commit }, data_){
            let response = {};
            let data = {};
            let params = {
                query: GET_YEAR_DASHBOARD,
                variables: data_
            }
            try {
                await apolloClient.query(params).then(res => {
                if (!res.loading) data = res.data.YearsDashboard;
                response = data;
                });
            } catch(e) {
                console.log("listar getYearDashboard falla: ", e);
                response = null;
            }
            return new Promise((resolve)=>{
                if(response  != null){
                    commit("SET_YEAR_DASHBOARD", response);
                    resolve(response)
                } else {
                    resolve(null)
                }
            });
        }
    },
};