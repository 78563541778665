const Covid = () => import("@/views/ADMIN/Covid.vue");
const CovidHome = () => import("@/components/Covid/CovidHome.vue");
const CovidUser = () => import("@/components/Covid/CovidUser.vue");
const CovidSymptoms = () => import("@/components/Covid/CovidSymptoms.vue");

export const covidList = {
  path: "/folovid",
  name: "Covid-19",
  meta: { requiresAuth: true },
  component: Covid,
  redirect: { name: "covid-home" },
  children: [
    {
      path: "home",
      meta: { requiresAuth: true},
      name: "covid-home",
      component: CovidHome,
    },
    {
      path: "user",
      meta: { requiresAuth: true},
      name: "covid-user",
      component: CovidUser,
    },
    {
      path: "symptoms",
      meta: { requiresAuth: true},
      name: "covid-symptoms",
      component: CovidSymptoms,
    },
  ],
};
