import { apolloClient } from "../../../vue-apollo";
import {
  ESTADOS_RECONOCE,
  LIST_ADMIN_BENEFICIO,
  TIPO_BENEFICIO,
  PAGINA_LIST_BENEFICIOS,
  LIST_PAGINADA_SOLICIUDES,
  ELEMENTS_TIPOS_SOLICITUD,
  DASHBOARD_BENEFICIO,
} from "@/apollo/queries";
import { CHANGE_STATE_BENEF } from "@/apollo/mutations";
export default {
  namespaced: true,
  state: { 
    benefit_list_edits: [], 
    id_type_solicitud: 0, 
    tab_component: [{name: "Dashboard",route: "benefit-Dashboard"}],
    data_estados: [],
    final_list: [],
    paginas_solicitud: [],
    termimno_carga: true,
    dash_data: {},
    series: [],
    v_chartOptions: [],
    key_chart: 0,
  },
  mutations: {
    getAdminListSoli(state, soli) {
      if(soli.length>0){
        let filtro_data = soli.map(function(element) {
            element.check = false;
            return element
        });
        state.final_list = filtro_data;
      } else {
        state.final_list = [];
      }
      state.termimno_carga = !state.termimno_carga;
    
    },
    addAdminListSoli(state, soli) {
      if (state.benefit_list_edits[Number(soli.id)] != undefined) {
        state.benefit_list_edits[Number(soli.id)].push(soli.data);
      } else {
        state.benefit_list_edits[Number(soli.id)] = [];
        state.benefit_list_edits[Number(soli.id)].push(soli.data);
      }
      state.final_list = soli.data;
    },
    changIdSolicitud(state, id) {
      state.id_type_solicitud = id;
    },
    // setea todo los tipos de solicitudes del cliente
    SET_TIPO_BENEFICIOS(state, data) {
      state.tab_component = [];
      state.tab_component = [
        {
          name: "Dashboard",
          route: "benefit-Dashboard",
        },
      ];
      data.forEach((type) => {
        state.tab_component.push({
          name: type.nombre_solicitud,
          route: "solici-request",
          id: `${type.id_tipo}`,
        });
      });
    },

    SET_ESTADOS_SOLICITUDES(state, datos) {
      state.data_estados = [];
      datos.forEach((element) =>
        state.data_estados.push({
          id: element.id_estado_solicitud,
          type: element.__typename,
          label: element.nombre,
        })
      );
    },

    SET_CHANGE_LIST_BENEFICIO(state, response){
      let found_index = state.final_list.findIndex(datos => datos.id_solicitud == response.id_solicitud);
      state.final_list.splice(found_index, 1);
    },

    SET_PAGINADOR_SOLICITUD(state, datos) {
      state.paginas_solicitud = datos;
    },
    SET_DASHBOARD_BENEFICIO(state, datos){
      state.series = [];
      state.v_chartOptions = [];
      datos.solicitudes_tipo.forEach((solicitud) => {
        state.v_chartOptions.push(solicitud.nombre_solicitud);
        state.series.push(Number(solicitud.cantidad_solicitudes));
      });
      state.dash_data = datos;
      state.key_chart = state.key_chart + 1;
    },

    ADD_LISTA_SOLICITUDES(state, datos) {
      let filtro_data = datos.map(function (element) {
        element.check = false;
        return element;
      });
      filtro_data.forEach(function (dato) {
        state.final_list.push(dato);
      });
      state.termimno_carga = !state.termimno_carga;
    },
  },
  actions: {
    // obtener lita de tipos de solicitudes asociadas al cliente
    async getTipoDeSolicitudes({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
        query: TIPO_BENEFICIO,
        variables: data_,
      };
      try {
        await apolloClient.query(params).then((res) => {
          if (!res.loading) data = res.data.TipoSolicitudes;
          response = data;
        });
      } catch (e) {
        console.log("listar getTipoDeSolicitudes falla: ", e);
        response = null;
      }
      return new Promise((resolve) => {
        if (response != null) {
          commit("SET_TIPO_BENEFICIOS", response);
          resolve(response);
        } else {
          resolve(null);
        }
      });
    },
    // obtener beneficios
    async getAdminListSoliAction({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
        query: LIST_ADMIN_BENEFICIO,
        variables: data_,
        fetchPolicy: "network-only",
      };
      try {
        await apolloClient.query(params).then((res) => {
          if (!res.loading) data = res.data.ListSolicitudesAdmin;
          response = data;
        });
      } catch (e) {
        console.log("listar getAdminListSoliAction falla: ", e);
        response = null;
      }
      return new Promise((resolve) => {
        if (response != null) {
          console.log("data lista de solicitudes", response);
          commit("getAdminListSoli", response);
          resolve(response);
        } else {
          resolve(null);
        }
      });
    },
    // Obtener lista de solicitudes paginadas
    async getPaginacionSolicitudes({ commit }, data_) {
      let response = null;
      let params = {
        query: PAGINA_LIST_BENEFICIOS,
        variables: data_,
        fetchPolicy: "network-only",
      };
      try {
      await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.PaginasSolicitudesAdmin;
        });
      } catch (e) {
        console.log("Falla error, getPaginacionSolicitudes ", e);
        //return false
      }
      return new Promise((resolve) => {
        if (response != null) {
          if (response.length == 0) {
            commit("getAdminListSoli", []);
            resolve(false);
          } else {
            resolve(response);
            commit("SET_PAGINADOR_SOLICITUD", response);
          }
        } else {
          resolve(false);
        }
      });
    },
    // Obtiene una lista de solicitudes paginadas, se debe ocupar el metodo getPaginacionSolicitudes primero
    async getListSolicitudes({ commit }, data_) {
      let response = null;
      let params = {
        query: LIST_PAGINADA_SOLICIUDES,
        variables: data_,
        fetchPolicy: "network-only",
      };
      try {
        await apolloClient.query(params).then((res) => {
          if (!res.loading) response = res.data.ListSolicitudesAdminById;
        });
      } catch (e) {
        console.log("Falla error, getListSolicitudes ", e);
      }
      return new Promise((resolve) => {
        if (response != null) {
          if (response.length == 0) {
            commit("getAdminListSoli", response);
            resolve(false);
          } else {
            resolve(response);
            commit("getAdminListSoli", response);
          }
        } else {
          resolve(false);
        }
      });
    },
    // obtiene las solicitudes, paginadas, pero llama a otro state, dado que en este caso hace un push
    async getListSolicitudesAppend({ commit }, data_) {
      let response = null;
      let params = {
        query: LIST_PAGINADA_SOLICIUDES,
        variables: data_,
      };
      try {
      await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.ListSolicitudesAdminById;
        });
      } catch (e) {
        console.log("Falla error, getListSolicitudesAppend ", e);
      }
      return new Promise((resolve) => {
        if (response != null) {
          if (response.length == 0) {
            resolve(false);
          } else {
            resolve(response);
            commit("ADD_LISTA_SOLICITUDES", response);
          }
        } else {
          resolve(false);
        }
      });
    },
    // obtener los datos de un tipo de beneficio
    async getTipoBeneficio({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
        query: ELEMENTS_TIPOS_SOLICITUD,
        variables: data_,
      };
      try {
        await apolloClient.query(params).then((res) => {
          if (!res.loading)
            data = res.data.ListarAllElementsTiposSolicitudAdmin;
          response = data;
        });
      } catch (e) {
        console.log("listar getTipoBeneficio falla: ", e);
        response = null;
      }
      return new Promise((resolve) => {
        if (response != null) {
          commit("SET_TIPO_BENEFICIO", response);
          resolve(response);
        } else {
          resolve(null);
        }
      });
    },
    // mutacion para cambio de estado de solicitudes de beneficios
    async cambioEstadoSolicitud({ commit }, data_) {
      console.log("data para el cambiar estados de solicitud");
      let response = null;
      let data = [];
      let params = {
        mutation: CHANGE_STATE_BENEF,
        variables: data_,
      };
      try {
        await apolloClient.mutate(params).then((res) => {
          console.log("cambioEstadoSolicitud", res.data.ChangeEstadoSolicitud);
          if (!res.loading) data = res.data.ChangeEstadoSolicitud;
          response = data;
        });
      } catch (e) {
        console.log("Falla metodo cambioEstadoSolicitud: ", e);
      }
      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          commit("SET_CHANGE_LIST_BENEFICIO", response);
        } else {
          resolve(false);
        }
      });
    },
    // obtener estados de las solicitudes
    async getEstadosSolicitud({ commit }, data_) {
      let response = {};
      let data = {};
      let params = {
        query: ESTADOS_RECONOCE,
        variables: data_,
      };
      try {
        await apolloClient.query(params).then((res) => {
          if (!res.loading) data = res.data.ListEstadosSolicitudes;
          response = data;
        });
      } catch (e) {
        console.log("listar getEstadosSolicitud falla: ", e);
        response = null;
      }
      return new Promise((resolve) => {
        if (response != null) {
          commit("SET_ESTADOS_SOLICITUDES", response);
          resolve(response);
        } else {
          resolve(null);
        }
      });
    },
    // añadir beneficios editados
    async addAdminListSoliAction({ commit }, soli) {
      commit("addAdminListSoli", soli);
    },
    // añadir beneficios editadosss
    async changIdSolicitudAction({ commit }, id) {
      commit("changIdSolicitud", id);
    },
    // getDashboard
    async getDashboard({ commit }, payload) {
      let response = {};
      let data = {};
      let params = {
        query: DASHBOARD_BENEFICIO,
        variables: payload,
      };
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.DashboardSolicitud;
          response = data;
        });
      } catch (e) {
        console.log("listar getDashboard falla: ", e);
        response = null;
      }
      return new Promise((resolve) => {
        if (response != null) {
          commit("SET_DASHBOARD_BENEFICIO", response);
          resolve(response);
        } else {
          resolve(null)
        }
      });
    }
  }
};
