import { GET_CULTURAS } from "@/apollo/queries";
import { DELETE_CULTURA_VALOR, ADD_CULTURA_VALOR, EDIT_CULTURA_VALOR } from "@/apollo/mutations";
import { apolloClient } from "../../../vue-apollo";
export default {
    namespaced: true,
    state: { culture: {}, culture_action: {} },
    mutations: {
        EDIT_VALOR(state, valor) {
            let index_val = state.culture.valores.findIndex(
                val => val.id_valor == valor.id_valor
            );
            if (index_val != -1) {
                Object.assign(state.culture.valores[index_val], valor)
            }
        },
        ADD_VALOR(state, valor) {
            state.culture.valores.unshift(valor);
        },
        DELETE_VALOR(state, valor) {
            let found_valor = state.culture.valores.findIndex(val => val.id_valor === valor.id_valor);
            if (found_valor != -1) {
                state.culture.valores.splice(found_valor, 1);
            }
        },
        NEW_CULTURE(state, res) {
            state.culture_actions_list.unshift(res);
        },
        SET_CULTURE_LIST(state, res) {
            state.culture = res;
        },
    },
    actions: {
        async getAllCultura({ commit }, data_) {
            let response = null;
            let params = {
                query: GET_CULTURAS,
                variables: data_,
                fetchPolicy: 'network-only'
            };
            await apolloClient.query(params).then(res => {
                if (!res.loading) response = res.data.culturas;
                if (response != null) {
                    commit("SET_CULTURE_LIST", response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async deleteValor({ commit }, data_) {
            let response = null;
            let params = {
                mutation: DELETE_CULTURA_VALOR,
                variables: data_,
            };
            await apolloClient.mutate(params).then(res => {
                if (!res.loading) response = res.data.eliminarValorCultura;
                if (response != null) {
                    commit("DELETE_VALOR", response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async addValor({ commit }, data_) {
            let response = null;
            let params = {
                mutation: ADD_CULTURA_VALOR,
                variables: data_,
            };
            await apolloClient.mutate(params).then(res => {
                if (!res.loading) response = res.data.crearValorCultura;
                if (response != null) {
                    commit("ADD_VALOR", response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async editValor({ commit }, data_) {
            let response = null;
            let params = {
                mutation: EDIT_CULTURA_VALOR,
                variables: data_,
            };
            await apolloClient.mutate(params).then(res => {
                if (!res.loading) response = res.data.editarValorCultura;
                if (response != null) {
                    commit("EDIT_VALOR", response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async addCultureAllAction({ commit }, action) {
            commit("SET_CULTURE_LIST", action);
        },
    },
};