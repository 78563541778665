const InicioAdmin = ()=> import('@/views/ADMIN/Home.vue')
const InicioAdminDash = ()=> import('@/components/HomeAdmin/Home.vue')

export const homeList = {
  path: "/inicio",
  name: "Inicio",
  meta: { requiresAuth: true},
  component: InicioAdmin,
  redirect: { name: "inicio-dash" },
  children: [ {
      path: "dashboard",
      meta: { requiresAuth: true},
      name: "inicio-dash",
      component: InicioAdminDash
    }
  ],
}
