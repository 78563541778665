import { apolloClient } from "../../../vue-apollo";
import {
	GET_DASHBOARD_TRANSPORTE,
	GET_TRAMOS_EMPRESA,
	GET_TIPO_MEDIOS,
	GET_ALL_PLANIFICACIONES,
	GET_CADENCIA_PLANIFICACION,
	GET_ONE_PLANIFICACION_ID,
	GET_VIAJES_EN_CURSO,
	GET_TRAMOS_VIAJES_EN_CURSO,
	GET_USUARIOS,
	GET_PLANIFICACIONES_APP,
	GET_TURNOS,
	GET_TEAMS,
	GET_TIPO_TRANSPORTE,
	//GET_TRAMOS_APP,
	GET_TRAMOS_ADMIN,
	GET_TRAMOS_APP_VUELTA,
	GET_TRAMOS_INFO,
	GET_TRAMOS_PLANIFICADOS_PAGINACION,
	GET_TIPO_IDA_VUELTA,
	GET_FIND_SOLICITUDES,
	GET_TRAMOS_PAGINATION,
	GET_TRAMOS_TOTAL_PAGINATION,
	GET_SOLICITUDES_PAGINATION,
	GET_SOLICITUDES_TOTAL_PAGINATION,
	GET_PLANIFICACIONES_PAGINATION,
	GET_PLANIFICACIONES_TOTAL_PAGINATION
} from "@/apollo/queries";
import {
	DELETE_SOLICITUD_TRANSPORTE,
	CREATE_SOLICITUD_TRANSPORTE,
	EDIT_SOLICITUD_TRANSPORTE,
	EDIT_SOLICITUD_TRANSPORTE_ADMIN,
	CREATE_TRAMO,
	EDIT_TRAMO,
	DELETE_TRAMO,
	CREATE_PLANIFICACION,
	UPDATE_PLANIFICACION,
	DELETE_PLANIFICACION,
	CREATE_TIPO_MEDIO_EMPRESA,
	EDIT_TIPO_MEDIO_EMPRESA,
	DELETE_TIPO_MEDIO_EMPRESA,
	UPDATE_PLANIFICACION_DATE,
	UPDATE_PLANIFICACION_TRAMOS,
	UPDATE_PLANIFICACION_INSCRIPCION
} from "../../../apollo/mutations";

export default {
	namespaced: true,
	state: {
		dashboard: [],
		tramos_list: [],
		transportes_list: [],
		planificaciones_list: [],
		cadencia_list: [],
		viajesEnCurso_list: [],
		tramosEnCurso_list: [],
		usuarios_list: [],
		planes_list: [],
		turnos_list: [],
		teams_list: [],
		tipos_list: [],
		tramosApp_list: [],
		idaVuelta_list: [],
		tramosAppVuelta_list: [],

		tramosPaginationArr: [],
		tramosArr: [],

		solicitudesPaginationArr: [],
		solicitudesArr: [],
		solicitudes_list: [],

		planificacionesPaginationArr: [],
		planificacionesArr: [],
		
		editingPlanificacion: {},
		editingTramo: {},
		editingTransporte: {},
		selectedTramo: {},
		selectedSolicitud: {},
		plannedTramos: [],
		plannedTramosPagination: [],

		loadingSpinner: false,
		loadingSpinnerArr: [],
	},

	getters: {
		transporteSegunTipo: (state) => (tipo=null) => {
			if (tipo) {
				return state.transportes_list.filter(medio => medio.transporte_tipo.nombre_transporte === tipo);
			} else {
				return state.transportes_list;
			}
		}
	},

	mutations: {
		updateLoadingSpinner(state, payload) {
			if(payload) state.loadingSpinnerArr.push(payload);
			else state.loadingSpinnerArr.pop();
			state.loadingSpinner = state.loadingSpinnerArr.length > 0;	
		},
		setDashboardTransporte(state, resp) {
			state.dashboard = resp;
		},
		// Mis Tramos get all
		setTramosEmpresa(state, resp) {
			state.tramos_list = resp;
		},
		addTramo(state, tramo) {
			if (tramo.length == 1) {
				state.tramosArr.unshift(tramo[0]);
			}
			if (tramo.length == 2) {
				state.tramosArr.unshift(tramo[1]);
				state.tramosArr.unshift(tramo[0]);
			}
		},
		deleteTramo(state, tramo) {
			if (tramo.length == 1) {
				let index = state.tramosArr.findIndex(t => t.id_destino === tramo[0].id_destino);
				if (index != -1) state.tramosArr.splice(index, 1);
			}
			if (tramo.length == 2) {
				let index1 = state.tramosArr.findIndex(t => t.id_destino === tramo[0].id_destino);
				if (index1 != -1) state.tramosArr.splice(index1, 1);
				let index2 = state.tramosArr.findIndex(t => t.id_destino === tramo[1].id_destino);
				if (index2 != -1) state.tramosArr.splice(index2, 1);
			}
		},
		editTramo(state, tramo) {
			if (tramo.length == 1) {
				let index = state.tramosArr.findIndex(t => t.id_destino === tramo[0].id_destino);
				if (index != -1) state.tramosArr.splice(index, 1, tramo[0]);
			}
			if (tramo.length == 2) {
				let index1 = state.tramosArr.findIndex(t => t.id_destino === tramo[0].id_destino);
				if (index1 != -1) state.tramosArr.splice(index1, 1, tramo[0]);
				let index2 = state.tramosArr.findIndex(t => t.id_destino === tramo[1].id_destino);
				if (index2 != -1) state.tramosArr.splice(index2, 1, tramo[1]);
			}
		},

		setTransportesList(state, resp) {
			state.transportes_list = resp;
		},
		addTransporte(state, medio) {
			state.transportes_list.unshift(medio);
		},
		editTransporte(state, medio) {
			let index = state.transportes_list.findIndex(t => t.id_medio == medio.id_medio);
			if (index != -1) state.transportes_list.splice(index, 1, medio);
		},
		deleteTransporte(state, medio) {
			let index = state.transportes_list.findIndex(t => t.id_medio === medio.id_medio);
			if (index != -1) state.transportes_list.splice(index, 1);
		},
		setPlanificaciones(state, planificaciones) {
			state.planificaciones_list = planificaciones.filter(
				p => p.transporte_planificacion_cadencia.id_cadencia != "1"
			);
		},
		GET_CADENCIA_PLANIFICACION_LIST(state, cadencia) {
			state.cadencia_list = cadencia;
		},
		ADD_PLANIFICACION(state, plan) {
			state.planificacionesArr.unshift(plan);
		},
		EDIT_PLANIFICACION(state, planificacion) {
			let index = state.planificacionesArr.findIndex(p => 
				p.id_planificacion == planificacion.id_planificacion
			);
			if (index != -1) {
				Object.assign(state.planificacionesArr[index], planificacion)
			}
		},
		deletePlanificacion(state, planificacion) {
			let index = state.planificacionesArr.findIndex(p => p.id_planificacion == planificacion.id_planificacion);
			state.planificacionesArr.splice(index, 1);
		},
		// En Curso
		GET_VIAJES_EN_CURSO_LIST(state, viajes) {
			let estado_ = '';
			let orden_ = 4;
			let fechaDeInicio = '';
			let fechaDeTermino = '';
			let today = new Date();
			viajes.forEach(plan => {
				if (plan.transporte_planificacion.transporte_planificacion_cadencia.id_cadencia == '1') {
					fechaDeTermino = new Date(plan.fecha_viaje + 'T00:00:00');
					fechaDeInicio = new Date(plan.fecha_viaje + 'T00:00:00');
					fechaDeInicio.setDate(fechaDeInicio.getDate() - 1);
				}
				if (plan.transporte_planificacion.transporte_planificacion_cadencia.id_cadencia == '2') {
					fechaDeInicio = new Date(plan.transporte_planificacion.fecha_inicio_inscripcion + 'T00:00:00');
					fechaDeTermino = new Date(plan.transporte_planificacion.fecha_fin_inscripcion + 'T00:00:00');
				}
				if (today > fechaDeInicio && today < fechaDeTermino) {
					estado_ = "Activa";
					orden_ = 1;
				}
				if (fechaDeInicio > today) {
					estado_ = "Programada";
					orden_ = 2;
				}

				if (fechaDeTermino < today) {
					estado_ = "Cerrada";
					orden_ = 3;
				}
				plan.estado = estado_;
				plan.orden = orden_;
			});
			viajes.sort((a, b) => {
				return (a.orden - b.orden);
			});
			state.viajesEnCurso_list = viajes;
		},
		GET_TRAMOS_EN_CURSO_LIST(state, tramos) {
			state.tramosEnCurso_list = tramos;
		},
		// inscripciones personal
		GET_USUARIOS_LIST(state, users) {
			users.forEach(user => {
				user.nombre = user.usuario.primer_nombre + ' ' + user.usuario.apellido_paterno;
			});
			state.usuarios_list = users;
		},
		GET_PLANIFICACIONES_APP_LIST(state, planes) {
			if (planes != undefined && planes.length > 0) {
				state.planes_list = planes;
			}
		},
		GET_TURNOS_LIST(state, turnos) {
			state.turnos_list = turnos;
		},
		GET_TEAMS_LIST(state, teams) {
			state.teams_list = teams;
		},
		RESET_TIPOS_LIST(state) {
			state.tipos_list = [];
		},
		RESET_TRAMOS_LIST(state) {
			state.tramosApp_list = [];
		},
		RESET_PLANIFICACIONES_LIST(state) {
			state.planes_list = [];
		},
		GET_TIPOS_LIST(state, tipos) {
			state.tipos_list = tipos;
		},
		GET_TRAMOS_APP_LIST(state, tramos) {
			tramos.forEach(t => {
				let date = new Date(t.fecha_viaje + 'T00:00:00')
				let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
				let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let year = date.getFullYear();
				t.tramo = t.transporte_lista_destino.nombre_origen + ' - ' + t.transporte_lista_destino.nombre_destino + ' (' + day + '/' + month + '/' + year +') ' + t.transporte_lista_destino.transporte_tipo_medio.transporte_tipo.nombre_transporte;
				
				
				t.asientos = t.asientos.map(asiento => Object.assign(asiento, { selected: false }));
			});
			state.tramosApp_list = tramos;
		},
		GET_TRAMOS_APP_VUELTA_LIST(state, tramos) {
			tramos.forEach(t => {
				let date = new Date(t.fecha_viaje + 'T00:00:00')
				let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
				let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let year = date.getFullYear()
				t.tramo = t.transporte_lista_destino.nombre_origen + ' - ' + t.transporte_lista_destino.nombre_destino + 
					' (' + day + '-' + month + '-' + year + ') ' + t.transporte_lista_destino.transporte_tipo_medio.transporte_tipo.nombre_transporte;
				t.asientos = t.asientos.map(asiento => Object.assign(asiento, { selected: false }));			
			});
			state.tramosAppVuelta_list = tramos;
		},
		GET_IDA_VUELTA_LIST(state, tipo) {
			tipo.forEach(t => {
				if (t.id_ida_vuelta == '1') { t.name = 'Ida'; }
				if (t.id_ida_vuelta == '2') { t.name = 'Ida y vuelta'; }
			});
			state.idaVuelta_list = tipo;
		},
		GET_SOLICITUDES_LIST(state, sol) {
			sol.forEach(t => {
				t.tramo = t.transporte_tramo.transporte_lista_destino.nombre_origen + ' - ' + t.transporte_tramo.transporte_lista_destino.nombre_destino;
			});
			state.solicitudes_list = sol;
		},
		ADD_SOLICITUD(state, resp) {
			let user = {};
			let index = state.solicitudesArr.findIndex(req => req.usuario.id_usuario == resp.usuario.id_usuario);
			if (index != -1) {
				user = state.solicitudesArr[index];
				user.solicitudes.push(resp.id_solicitud);
				state.solicitudesArr.splice(index, 1, user);
			}
		},
		setTramosPagination(state, resp) {
			state.tramosPaginationArr = resp;
		},
		setTramosByPagination(state, resp) {
			state.tramosArr = resp;
		},
		setSolicitudesPagination(state, resp) {
			state.solicitudesPaginationArr = resp;
		},
		setSolicitudesByPagination(state, resp) {
			resp.forEach(user => {
				user.nombre = user.usuario.primer_nombre + ' ' + user.usuario.apellido_paterno;
			});

			resp.forEach(element =>{
				if (Array.isArray(element.usuario.cargo) && element.usuario.cargo[0]==null && typeof element.usuario.cargo[0]=== 'object') {
					element.usuario.cargo[0] = {
						id_cargo : '0',
						nombre_cargo : "S/N"
					}
				}
				if(Array.isArray(element.usuario.gerencia) && element.usuario.gerencia[0]==null && typeof element.usuario.gerencia[0]=== 'object'){
					element.usuario.gerencia[0] = {
						id_gerencia : '0',
						nombre_gerencia : "S/N"
					}
				}
				if(Array.isArray(element.usuario.grupo) && element.usuario.grupo[0]==null && typeof element.usuario.grupo[0]=== 'object'){
					element.usuario.grupo[0] = {
						id_grupo: "0"
					}
				}
				if(Array.isArray(element.usuario.sucursal) && element.usuario.sucursal[0]==null && typeof element.usuario.sucursal[0]=== 'object'){
					element.usuario.sucursal[0] = {
						id_sucursal : '0',
						nombre_sucursal : "S/N"
					}
				}
				if(Array.isArray(element.usuario.turno) && element.usuario.turno[0]==null && typeof element.usuario.turno[0]=== 'object'){
					element.usuario.turno[0] = {
						id_turno : '0',
						nombre_turno : "S/N"
					}
				}
			});
			state.solicitudesArr = resp;
		},
		deleteSolicitud(state, solicitud) {
			let index = state.solicitudesArr.findIndex(s => s.usuario.id_usuario == solicitud.usuario.id_usuario);
			if (index != -1) state.solicitudesArr[index].solicitudes = [];
			
			if (state.plannedTramos.length > 0) {
				const id_tramo = solicitud.transporte_tramo.id_tramo;
				const tramoIdx = state.plannedTramos.findIndex((tramo) => tramo.id_tramo === id_tramo);
				if (tramoIdx !== -1) {
					const solicitudIdx = state.plannedTramos[tramoIdx].transporte_solicitud.findIndex(
						(tramo) => tramo.id_tramo === solicitud.id_solicitud
					);
					if (solicitudIdx) {						
						state.plannedTramos[tramoIdx].transporte_solicitud.splice(solicitudIdx, 1);
						state.plannedTramos[tramoIdx].asientos_tomados--;
					}
				}
			}
		},
		addSolicitudes(state, solicitud) {
			let index = state.solicitudesArr.findIndex(s => s.usuario.id_usuario == solicitud[0].usuario.id_usuario);
			if (index != -1) {
				state.solicitudesArr[index].solicitudes[0] = { id_solicitud: solicitud[0].id_solicitud };
			}

			if (state.plannedTramos.length > 0) {
				const id_tramo = solicitud[0].transporte_tramo.id_tramo;
				const tramoIdx = state.plannedTramos.findIndex((tramo) => tramo.id_tramo === id_tramo);
				if (tramoIdx !== -1) {
					const ida_vuelta = solicitud[0].ida_vuelta === "1" ? "Ida" : "Solo vuelta";
					solicitud[0].transporte_ida_vuelta = { nombre_idea_vuelta: ida_vuelta };
					state.plannedTramos[tramoIdx].transporte_solicitud.push(solicitud[0]);
					state.plannedTramos[tramoIdx].asientos_tomados++;
				}
			}

			
		},
		editSolicitudes(state, solicitud) {
			if (solicitud != undefined) {
				state.solicitudes_list = []
				//state.solicitudesArr = [...state.solicitudesArr.slice(index), ...state.solicitudesArr.slice(0, index)]
			}

			if (Object.keys(state.selectedSolicitud).length != 0) {
				if (state.selectedSolicitud.id_solicitud === solicitud.id_solicitud) {
					state.selectedSolicitud.usuario = solicitud.usuario;
				}
			}
		},
		setSelectedSolicitud(state, solicitud) {
			//console.log("detalle_solicitud: ", solicitud);
			if (Array.isArray(solicitud.usuario.cargo) && solicitud.usuario.cargo[0]==null && typeof solicitud.usuario.cargo[0]=== 'object')
				{
					solicitud.usuario.cargo[0] = {
						id_cargo : '0',
						nombre_cargo : "S/N"
					}
				
				}
				if(Array.isArray(solicitud.usuario.gerencia) && solicitud.usuario.gerencia[0]==null && typeof solicitud.usuario.gerencia[0]=== 'object'){
					solicitud.usuario.gerencia[0] = {
						id_gerencia : '0',
						nombre_gerencia : "S/N"
					}
				}
				if(Array.isArray(solicitud.usuario.grupo) && solicitud.usuario.grupo[0]==null && typeof solicitud.usuario.grupo[0]=== 'object'){
					solicitud.usuario.grupo[0] = {
						id_grupo: "0"
					}
				}
				if(Array.isArray(solicitud.usuario.sucursal) && solicitud.usuario.sucursal[0]==null && typeof solicitud.usuario.sucursal[0]=== 'object'){
					solicitud.usuario.sucursal[0] = {
						id_sucursal : '0',
						nombre_sucursal : "S/N"
					}
				}
				if(Array.isArray(solicitud.usuario.turno) && solicitud.usuario.turno[0]==null && typeof solicitud.usuario.turno[0]=== 'object'){
					solicitud.usuario.turno[0] = {
						id_turno : '0',
						nombre_turno : "S/N"
					}
				}
				if(Array.isArray(solicitud.usuario.sociedad) && solicitud.usuario.sociedad[0]==null && typeof solicitud.usuario.sociedad[0]=== 'object'){
					solicitud.usuario.sociedad[0] = {
						id_sociedad : '0',
						nombre_sociedad : "S/N"
					}
				}
				if(Array.isArray(solicitud.usuario.sindicato) && solicitud.usuario.sindicato[0]==null && typeof solicitud.usuario.sindicato[0]=== 'object'){
					solicitud.usuario.sindicato[0] = {
						id_sindicato : '0',
					}
				}
			state.selectedSolicitud = solicitud;
		},
		setPlanificacionesPagination(state, resp) {
			state.planificacionesPaginationArr = resp;
		},
		setPlanificacionesByPagination(state, resp) {
			state.planificacionesArr = resp;
		},

		setEditingTramo(state, tramo) {
			state.editingTramo = tramo;
		},
		cleanEditingTramo(state) {
			state.editingTramo = {};
		},
		setEditingPlan(state, plan) {
			const tramos_ordenados = plan.transporte_tramo.sort((a, b) => new Date(a.fecha_viaje) - new Date(b.fecha_viaje));
			plan.transporte_tramo = tramos_ordenados;
			state.editingPlanificacion = plan;
		},
		cleanEditingPlan(state) {
			state.editingPlanificacion = {};
		},
		setEditingTransporte(state, transporte) {
			state.editingTransporte = transporte;
		},
		cleanEditingTransporte(state) {
			state.editingTransporte = {};
		},
		setSelectedTramo(state, tramo) {
			state.selectedTramo = tramo;
		},
		resetSelectedTramo(state) {
			state.selectedTramo = {}
		},
		getPlannedTramos(state, tramos) {
			let i = tramos.length;
			while (i--) {
				if (tramos[i].tranpsorte_planificacion_detalle.concurrencia_ejecucion_semanal === null) {
					tramos.splice(i, 1);
				}
			}
			tramos.forEach(tramo => tramo.asientos.map(asiento => Object.assign(asiento, { selected: false })))
			state.plannedTramos = tramos;
		},
		getPlannedTramosPagination(state, pagination) {
			state.plannedTramosPagination = pagination;		
		}
	},
	
	actions: {
		updateLoadingSpinner({ commit }, payload) {
			commit("updateLoadingSpinner", payload);
		},
		setEditingTramoAction({ commit }, tramo) {
			commit("setEditingTramo", tramo);
		},
		cleanEditingTramoAction({ commit }) {
			commit("cleanEditingTramo");
		},
		setEditingPlanAction({ commit }, plan) {
			commit("setEditingPlan", plan);
		},
		cleanEditingPlanAction({ commit }) {
			commit("cleanEditingPlan");
		},
		setEditingTransporteAction({ commit }, transporte) {
			commit("setEditingTransporte", transporte);
		},
		cleanEditingTransporteAction({ commit }) {
			commit("cleanEditingTransporte");
		},
		setSelectedTramoAction({ commit }, tramo) {
			commit("setSelectedTramo", tramo);
		},
		resetSelectedTramo({ commit }) {
			commit("resetSelectedTramo");
		},
		async createTramoAction({ commit }, payload) {
			const params = {
				mutation: CREATE_TRAMO,
				variables: payload
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.createDestino;
					response = data;
				});
			} catch (e) {
				console.log("response de crear tramo: ", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("addTramo", response);
					console.log('createTramo response:', response);
				} else {
					resolve(false);
				}
			});
		},

		async editTramoAction({ commit }, payload) {
			const params = {
				mutation: EDIT_TRAMO,
				variables: payload
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.updateDestino;
					response = data;
				});
			} catch (e) {
				console.log("response de editar tramo: ", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("editTramo", response);
					console.log('editTramo response:', response);
				} else {
					resolve(false);
				}
			});
		},

		async deleteTramoAction({ commit }, payload) {
			const params = {
				mutation: DELETE_TRAMO,
				variables: payload
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.deleteDestino;
					response = data;
				});
			} catch (e) {
				console.log("response de eliminar tramo: ", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("deleteTramo", response);
					console.log('deleteTramo response:', response);
				} else {
					resolve(false);
				}
			});
		},

		async createPlanificacionAction({ commit }, payload) {
			const params = {
				mutation: CREATE_PLANIFICACION,
				variables: payload
			};

			let plan = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.createPlanificacion;
					plan = data;
				});
			} catch (e) {
				console.log("response de crear planificación: ", e);
			}

			return new Promise((resolve) => {
				if (plan != null) {
					resolve(plan);
					commit("ADD_PLANIFICACION", plan);
					console.log('createPlanificacionAction response:', plan);
				} else {
					resolve(false);
				}
			});
		},

		async editPlanificacionAction({ commit }, payload) {
			const params = {
				mutation: UPDATE_PLANIFICACION,
				variables: payload
			};

			let plan = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.updatePlanificacion;
					plan = data;
				});
			} catch (e) {
				console.log("response de editar planificación: ", e);
			}

			return new Promise((resolve) => {
				if (plan != null) {
					resolve(plan);
					commit("EDIT_PLANIFICACION", plan);
					console.log('editPlanificacionAction response:', plan);
				} else {
					resolve(false);
				}
			});
		},
		async editPlanificacionDateAction({ commit }, payload) {
			const params = {
				mutation: UPDATE_PLANIFICACION_DATE,
				variables: payload
			};
			let data = null;
			return await apolloClient.mutate(params).then(res => {
				if (!res.loading) data = res.data.updatePlanificacionFecha;
				commit("EDIT_PLANIFICACION", data);
				return data;
			})
			
		},
		async editPlanificacionInscripcionAction({ commit }, payload) {
			const params = {
				mutation: UPDATE_PLANIFICACION_INSCRIPCION,
				variables: payload
			};
			let data = null;
			return await apolloClient.mutate(params).then(res => {
				if (!res.loading) data = res.data.updatePlanificacionInscripciones;
				commit("EDIT_PLANIFICACION", data);
				return data;
			})
			
		},
		async editPlanificacionTramosAction({ commit }, payload) {
			const params = {
				mutation: UPDATE_PLANIFICACION_TRAMOS,
				variables: payload
			};
			let data = null;
			return await apolloClient.mutate(params).then(res => {
				if (!res.loading) data = res.data.updatePlanificacionTramos;
				commit("EDIT_PLANIFICACION", data);
				return data;
			});
			
		},

		async createTransporteAction({ commit }, payload) {
			const params = {
				mutation: CREATE_TIPO_MEDIO_EMPRESA,
				variables: payload
			};

			let transporte = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.tipoMedioEmpresa;
					transporte = data;
				});
			} catch (e) {
				console.log("response de crear transporte: ", e);
			}

			return new Promise((resolve) => {
				if (transporte != null) {
					resolve(transporte);
					commit("addTransporte", transporte);
					console.log('createTransporteAction response:', transporte);
				} else {
					resolve(false);
				}
			});
		},
		async editTransporteAction({ commit }, payload) {
			const params = {
				mutation: EDIT_TIPO_MEDIO_EMPRESA,
				variables: payload
			};

			let transporte = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.updateTipoMedioEmpresa;
					transporte = data;
				});
			} catch (e) {
				console.log("response de editar transporte: ", e);
			}

			return new Promise((resolve) => {
				if (transporte != null) {
					resolve(transporte);
					commit("editTransporte", transporte);
					console.log('editTransporteAction response:', transporte);
				} else {
					resolve(false);
				}
			});
		},
		async resetTipoTransporte({ commit }) {
			commit("RESET_TIPOS_LIST");
		},
		async resetTramosList({ commit }) {
			commit("RESET_TRAMOS_LIST");
		},
		async resetPlanificacionesList({ commit }) {
			commit("RESET_PLANIFICACIONES_LIST");
		},
		// Dashboard Transporte action
		async getDashboardTransporte({ commit }, data) {
			let response = [];
			let data_ = [];
			await apolloClient.query({
				query: GET_DASHBOARD_TRANSPORTE,
				variables: data
			}).then(res => {
				if (!res.loading) data_ = res.data.DashboardTransporte;
				response = data_;
			}).catch(error => {
				throw error;
			});
			commit("setDashboardTransporte", response);
		},

		// Tramos 
		async getPlannedTramosAction({ commit }, data) {
			const params = {
				query: GET_TRAMOS_INFO,
				variables: data,
        fetchPolicy: 'network-only'
			};

      let response = null;
			try {
				let data = null;
				response = await apolloClient.query(params);
        if (!response.loading) data = response.data.TramosConInfo;
        response = data;
			} catch (e) {
				console.log("response de getPlannedTramosAction: ", e);
        console.log(e.networkError.result.errors);

			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("getPlannedTramos", response);
				} else {
					resolve(false);
				}
			});
		},

		async getPlannedTramosPagination({ commit }, data) {
			const params = {
				query: GET_TRAMOS_PLANIFICADOS_PAGINACION,
				variables: data
			};

      let response = null;
			try {
				let data = null;
				response = await apolloClient.query(params);
        if (!response.loading) data = response.data.PaginacionTramosEnCurso;
        response = data;
			} catch (e) {
				console.log("response de getPlannedTramosPagination: ", e);
        console.log(e.networkError.result.errors);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("getPlannedTramosPagination", response);
				} else {
					resolve(false);
				}
			});
		},

		async getTramosEmpresa({ commit }, data) {
			let response = [];
			let data_ = [];
			await apolloClient.query({
				query: GET_TRAMOS_EMPRESA,
				variables: data,
				fetchPolicy: 'network-only'
			}).then(res => {
				if (!res.loading) {
					data_ = res.data.tramosEmpresa;
					data_.sort((a, b) => { return (b.id_destino - a.id_destino); });
					data_.forEach(tramo => {
						if (tramo.transporte_tipo_medio.cantidad_asiento.length == 1) {
							tramo.transporte_tipo_medio.cantidad_asiento = '0' + tramo.transporte_tipo_medio.cantidad_asiento;
						}
					});
				}
				response = data_;
			}).catch(error => {
				throw error;
			});
			commit("setTramosEmpresa", response);
		},

		// Transportes action get all
		async getTransportesList({ commit }, data) {
			let response = [];
			let data_ = [];
			await apolloClient.query({
				query: GET_TIPO_MEDIOS,
				variables: data,
				fetchPolicy: 'network-only'
			}).then(res => {
				if (!res.loading) {
					data_ = res.data.tipoMedios;
					data_.sort((a, b) => { return (b.id_medio - a.id_medio); });
				}
				response = data_;
			}).catch(error => {
				throw error;
			});
			commit("setTransportesList", response);
		},

		async deleteTransporteAction({ commit }, payload) {
			const params = {
				mutation: DELETE_TIPO_MEDIO_EMPRESA,
				variables: payload
			};

			let transporte = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.deleteTipoMedioEmpresa;
					transporte = data;
				});
			} catch (e) {
				console.log("response de eliminar transporte: ", e);
			}

			return new Promise((resolve) => {
				if (transporte != null) {
					resolve(transporte);
					commit("deleteTransporte", transporte);
					console.log('deleteTransporteAction response:', transporte);
				} else {
					resolve(false);
				}
			});

		},


		async getAllPlanificaciones({ commit }, data) {
			const params = {
				query: GET_ALL_PLANIFICACIONES,
				variables: data
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.query(params).then(res => {
					if (!res.loading) data = res.data.getAllPlanificaciones;
					response = data;
				});
			} catch (e) {
				console.log("response de getAllPlanificaciones:", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("setPlanificaciones", response);
				} else {
					resolve(false);
				}
			});
		},
	
		async getCadenciaPlanificacion({ commit }) {
			const { data } = await apolloClient.query({
				query: GET_CADENCIA_PLANIFICACION,
				variables: {}
			});
			commit("GET_CADENCIA_PLANIFICACION_LIST", data.cadenciaPlanificacion);
		},
		async getPlanificacionById({ commit }, plan) {
			let response;
			await apolloClient.query({
				query: GET_ONE_PLANIFICACION_ID,
				variables: {
					idEmpresa: plan.idEmpresa,
					idPlanificacion: plan.idPlanificacion,
				},
				fetchPolicy: 'network-only'
			}).then(res => {
				console.log('respuesta del obtener info plan', res);
				response = res.data.getOnePlanificacionID
			});
			commit("setEditingPlan", response);
		},
		async deletePlanificacionAction({ commit }, payload) {
			const params = {
				mutation: DELETE_PLANIFICACION,
				variables: payload
			};

			let plan = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.deletePlanificacion;
					plan = data;
				});
			} catch (e) {
				console.log("response de eliminar planificación: ", e);
			}

			return new Promise((resolve) => {
				if (plan != null) {
					resolve(plan);
					commit("deletePlanificacion", plan);
					console.log('deletePlanificacionAction response:', plan);
				} else {
					resolve(false);
				}
			});
		},

		// En Curso action get all
		async getViajesEnCurso({ commit }, enCurso) {
			const { data } = await apolloClient.query({
				query: GET_VIAJES_EN_CURSO,
				variables: enCurso
			});

			commit("GET_VIAJES_EN_CURSO_LIST", data.ViajesEnCurso);
		},
		async getTramosViajesEnCurso({ commit }, tramos) {
			const { data } = await apolloClient.query({
				query: GET_TRAMOS_VIAJES_EN_CURSO,
				variables: {
					idEmpresa: tramos.idEmpresa,
					fechaViaje: tramos.fechaViaje,
					idPlanificacion: tramos.idPlanificacion,
				}
			});
			commit("GET_TRAMOS_EN_CURSO_LIST", data.TramosViajeEnCurso);
		},

		// Inscripciones personal 
		async getUsuarios({ commit }, usuarios) {
			const { data } = await apolloClient.query({
				query: GET_USUARIOS,
				variables: {
					idEmpresa: usuarios.idEmpresa,
					nombreUsuario: usuarios.nombreUsuario,
				}
			});
			commit("GET_USUARIOS_LIST", data.FindUserSolicitud);
		},
		async getPlanificacionesApp({ commit }, data_) {
			let response = []
			await apolloClient.query({
				query: GET_PLANIFICACIONES_APP,
				variables: data_,
				fetchPolicy: 'network-only'
			}).then(res => {
				if (!res.loading) response = res.data.GetPlanificacionesApp;
				response.forEach(plan => {
					let date = new Date(plan.fecha_viaje + 'T00:00:00')
					let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
					let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
					let year = date.getFullYear()
					if (plan.nombre_planificacion.search(day + '-' + month + '-' + year) == -1) {
						plan.nombre_planificacion = plan.nombre_planificacion + ' (' + day + '-' + month + '-' + year + ' )'
					}
				});
			});
			commit("GET_PLANIFICACIONES_APP_LIST", response);
		},
		async getTurnos({ commit }, turno) {
			const { data } = await apolloClient.query({
				query: GET_TURNOS,
				variables: {
					idEmpresa: turno.idEmpresa,
				}
			});
			commit("GET_TURNOS_LIST", data.turnos);
		},
		async getTeams({ commit }, team) {
			const { data } = await apolloClient.query({
				query: GET_TEAMS,
				variables: {
					idEmpresa: team.idEmpresa,
				}
			});
			commit("GET_TEAMS_LIST", data.teams);
		},
		async getTipoTransporte({ commit }, tipo) {
			const { data } = await apolloClient.query({
				query: GET_TIPO_TRANSPORTE,
				variables: {
					idEmpresa: tipo.idEmpresa,
					idPlanificacion: tipo.idPlanificacion,
					fechaViaje: tipo.fechaViaje,
				}
			});
			console.log('data plan', data);
			commit("GET_TIPOS_LIST", data.tipoTransporteEmpresa);
		},
		async getTramosApp({ commit }, tramo) {
			const { data } = await apolloClient.query({
				query: GET_TRAMOS_ADMIN,
				variables: {
					idEmpresa: tramo.idEmpresa,
					idPlanificacion: tramo.idPlanificacion,
					fechaViaje: tramo.fechaViaje,
					isPrincipal: tramo.isPrincipal,
					idTramoPadre: tramo.idTramoPadre,
				},
				fetchPolicy: 'network-only'
			});
			commit("GET_TRAMOS_APP_LIST", data.GetTramosAdmin);
		},
		async getIdaVuelta({ commit }, data_) {
			let response = [];
			await apolloClient.query({
				query: GET_TIPO_IDA_VUELTA,
				variables: data_,
				fetchPolicy: 'network-only'
			}).then(res => {
				console.log('Response ida vuelta', res);
				if (!res.loading) response = res.data.tipoIdaVuelta;
			});
			commit("GET_IDA_VUELTA_LIST", response);
		},
		async getTramosAppVuelta({ commit }, data_) {
			let response = [];
			await apolloClient.query({
				query: GET_TRAMOS_APP_VUELTA,
				variables: data_,
				fetchPolicy: 'network-only'
			}).then(res => {
				if (!res.loading) response = res.data.GetTramosAppVuelta;
			});
			commit("GET_TRAMOS_APP_VUELTA_LIST", response);
		},
		async getFindSolicitudes({ commit }, data_) {
			let params = {
				query: GET_FIND_SOLICITUDES,
				variables: data_,
				fetchPolicy: 'network-only'
			};
			
			let response = null;
			try {				
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.FindSolicitudesRecientesUsuario;
				commit("GET_SOLICITUDES_LIST", response);
			} catch (e) {
				console.error("error en obtención solicitudes del usuario", e);				
			}
		},

		// Pagination tramos
		async getTramosPagination({ commit }, data_) {
			let response = [];
			let params = {
				query: GET_TRAMOS_PAGINATION,
				variables: data_
			};
			await apolloClient.query(params).then(res => {
				if (!res.loading) response = res.data.totalTramosPaginados;
			});
			commit("setTramosPagination", response);
		},

		async getTramosPaginated({ commit }, data_) {
			let response = [];
			let params = {
				query: GET_TRAMOS_TOTAL_PAGINATION,
				variables: data_
			};
			await apolloClient.query(params).then(res => {
				if (!res.loading) response = res.data.listTramosPaginados;
			});
			commit('setTramosByPagination', response);
		},

		// Pagination solicitudes
		async getSolicitudesPagination({ commit }, data_) {
			let response = [];
			let params = {
				query: GET_SOLICITUDES_PAGINATION,
				variables: data_
			};
			await apolloClient.query(params).then(res => {
				if (!res.loading) response = res.data.PaginacionUserSolicitud;
			});
			commit("setSolicitudesPagination", response);
		},

		async getSolicitudesPaginated({ commit }, data_) {
			const params = {
				query: GET_SOLICITUDES_TOTAL_PAGINATION,
				variables: data_,
				fetchPolicy: "network-only"
			};

			let response = null;
			try {
				const res = await apolloClient.query(params);
				if (!res.loading) response = res.data.FindUserSolicitudPaginadas;
				commit('setSolicitudesByPagination', response);
			} catch (e) {
        console.log("error en obtención de solicitudes: ", e);
			}
		},

		async deleteSolicitudAction({ commit }, data_) {
			let response;
			let params = {
				mutation: DELETE_SOLICITUD_TRANSPORTE,
				variables: data_,
			};
			await apolloClient.mutate(params).then(res => {
				if (!res.loading) {
					response = res.data.cancelarSolicitud[0];
				}
			});
			commit('deleteSolicitud', response);
		},
		async addSolicitudesPaginated({ commit }, data_) {
			let response = [];
			let params = {
				mutation: CREATE_SOLICITUD_TRANSPORTE,
				variables: data_
			};
			await apolloClient.mutate(params).then(res => {
				if (!res.loading) {
					response = res.data.SendSolicitudTransporte;
				}
			});
			commit('addSolicitudes', response);
		},

		setSelectedSolicitudAction({ commit }, solicitud) {
			commit('setSelectedSolicitud', solicitud);
		},

		async editSolicitudesPaginated({ commit }, data_) {
			let response;
			let params = {
				mutation: EDIT_SOLICITUD_TRANSPORTE,
				variables: data_
			};
			await apolloClient.mutate(params).then(res => {
				if (!res.loading) {
					response = res.data.EditSolicitudTransporte[0];
					if (res.data.EditSolicitudTransporte[0].error == "") response = res.data.EditSolicitudTransporte[0];
				}
			});
			commit('editSolicitudes', response);
		},

		async editSolicitudTransporteAdmin({ commit }, data_) {
      const params = {
				mutation: EDIT_SOLICITUD_TRANSPORTE_ADMIN,
				variables: data_
			};

      let response = null;
			try {
				let data = null;
				response = await apolloClient.mutate(params);
        if (!response.loading) data = response.data.EditSolicitudTransporteAdmin;
        response = data;
			} catch (e) {
				console.log("response de EditSolicitudTransporteAdmin: ", e);
        console.log(e.networkError.result.errors);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit('editSolicitudes', response);
				} else {
					resolve(false);
				}
			});
    },

		// Pagination planificaciones
		async getPlanificacionesPagination({ commit }, data_) {
			let response = [];
			let params = {
				query: GET_PLANIFICACIONES_PAGINATION,
				variables: data_
			};
			await apolloClient.query(params).then(res => {
				if (!res.loading) response = res.data.totalPlanificacionesAdmin;
			});
			commit("setPlanificacionesPagination", response);
		},

		async getPlanificacionesPaginated({ commit }, data_) {
			let response = [];
			let params = {
				query: GET_PLANIFICACIONES_TOTAL_PAGINATION,
				variables: data_
			};
			await apolloClient.query(params).then(res => {
				if (!res.loading) response = res.data.getAllPlanificacionesLimit;
			});
			commit('setPlanificacionesByPagination', response);
		}

	},
};