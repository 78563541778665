const Benefit = () => import("@/views/ADMIN/Benefit.vue");
const BenefitDashboard = () => import("@/components/Benefit/Dashboard.vue");
const BenefitRequest = () => import("@/components/Benefit/BenefitRequest.vue");

export const benefitList = {
  path: "/beneficios",
  name: "Beneficios",
  meta: { requiresAuth: true },
  component: Benefit,
  redirect: { name: "benefit-Dashboard" },
  children: [
    {
      path: "Dashboard",
      meta: { requiresAuth: true},
      name: "benefit-Dashboard",
      component: BenefitDashboard,
    },
    {
      path: "solicitud/:id",
      meta: { requiresAuth: true},
      name: "solici-request",
      component: BenefitRequest,
    },
  ],
};
