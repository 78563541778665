import Vue from "vue";
import VueRouter from "vue-router";

import { loginRoute, loginTokenRoute } from "./login/routesLogin";

import { homeList } from "./admin/home/routesHome";
import { recognizeList } from "./admin/recognize/routesRecognize";
import { newsList, intraNewsList } from "./admin/news/routesNews";
import { benefitList } from "./admin/benefit/routesBenefit";

import { birthdayList } from "./admin/birthday/routesBirthday";
import { covidList } from "./admin/covid/routesCovid";
import { cultureList } from "./admin/culture/routesCulture";
import { galleryList } from "./admin/gallery/routesGallery";
import { libraryList } from "./admin/library/routesLibrary";

import { organigramaList } from "./admin/organigrama/routesOrganigrama";
import { seguridadList } from "./admin/seguridad/routesSeguridad";
import { videosList } from "./admin/video/routesVideo";
import { profileList } from "./profile/routesProfile";

import { userList } from "./admin/users/routesUsers";
import { alertPushList } from "./admin/alertaPush/routesAlert";
import { encuestasList } from "./admin/encuestas/routesEncuestas";
import { transporteList } from "./admin/transporte/routesTransporte";
import { wallList } from "./admin/wall/routesWall";

import { births } from "@/router/admin/births/routesBirths";
import { customization } from "@/router/admin/customization/routesCustomization";

import { canalCorporativoList } from "./admin/tv/routesTV";
import { hotelsList } from "./admin/hotels/routesHotels";

Vue.use(VueRouter);

const routes = [
  { path: "*", redirect: "/inicio" },
  hotelsList,
  benefitList,
  birthdayList,
  covidList,
  cultureList,
  galleryList,
  homeList,
  libraryList,
  newsList,
  intraNewsList,

  organigramaList,
  recognizeList,
  seguridadList,
  videosList,
  loginRoute,
  loginTokenRoute,
  profileList,

  userList,
  alertPushList,
  encuestasList,
  transporteList,
  wallList,
  canalCorporativoList,

  //FF
  births,
  customization,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem("access-token");
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth);

  if (requiresAuth && !accessToken) {
    next({ name: "Login" });
  } else if (accessToken && (to.name === "Login" || to.name === "Login-token")) {
    next({ name: "Inicio" });
  } else {
    next();
  }
});

export default router;
