<template>
   <div class="profile">
      <div class="profile-container" :style="{ width: '100%'}">
      <!-- <div class="profile-container"> -->
         <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />
         <!-- <div class="profile-body"> -->
         <div class="profile-body" :style="{ 'padding-left': '' }">

            <!-- contenido -->
            <div class="profile-body-container">
               <div class="user-data">
                  <div class="user-data-title">
                     <h3>Datos del Usuario</h3>
                     <!-- Activar edicion -->
                     <h5 v-if="!edit_profile" @click="activeEditProfile">
                        Editar
                        <font-awesome-icon icon="pen"></font-awesome-icon>
                     </h5>
                  </div>
                  <div class="user-data-container">
                     <div class="user-data-container-column">
                        <div class="profile-element name">
                           <h4>Nombre:</h4>
                           <div class="resp">
                              {{ user.primer_nombre }}
                           </div>
                        </div>
                        <div class="profile-element lastname">
                           <h4>Apellido:</h4>
                           <div class="resp">
                              {{ user.apellido_paterno }}
                           </div>
                        </div>
                        <div class="profile-element dni">
                           <h4>Rut:</h4>
                           <div class="resp">
                              {{ user.rut_usuario }}-{{ user.dv_usuario }}
                           </div>
                        </div>
                        <div class="profile-element phone">
                           <h4>Telefono:</h4>
                           <div class="resp">
                              <span v-if="!edit_profile">
                                 {{ user.telefono_movil }}
                              </span>
                              <div class="input-container" v-if="edit_profile">
                                 <input
                                    type="text"
                                    v-model="user_edit.phone"
                                    maxlength="9"
                                    @keypress="
                                       handleNumberInputs(), handlePhone()
                                    "
                                 />
                              </div>
                           </div>
                        </div>
                        <div class="profile-element email">
                           <h4>Email:</h4>
                           <div class="resp">
                              <span v-if="!edit_profile">
                                 {{ user.correo }}
                              </span>
                              <div class="input-container" v-if="edit_profile">
                                 <input
                                    type="text"
                                    v-model="user_edit.correo"
                                    @keydown.space.prevent
                                    @keypress="handleEmail"
                                    @click="handleEmail"
                                    @keyup.delete="handleEmail"
                                 />
                                 <!-- icono correo valido -->
                                 <span
                                    v-if="show_icon_correo == 'check'"
                                    :content="correo_success_msg"
                                    v-tippy="{ arrow: true }"
                                 >
                                    <font-awesome-icon
                                       icon="check"
                                    ></font-awesome-icon>
                                 </span>
                                 <!-- icono correo no valido -->
                                 <span
                                    v-if="show_icon_correo == 'error'"
                                    :content="correo_error_msg"
                                    v-tippy="{ arrow: true }"
                                 >
                                    <font-awesome-icon
                                       icon="times"
                                    ></font-awesome-icon>
                                 </span>
                                 <!-- loading -->
                                 <div
                                    class="gif-container"
                                    v-if="show_icon_correo == 'load'"
                                 >
                                    <img
                                       src="@/assets/img/spinner.gif"
                                       alt=""
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="profile-element user-type">
                           <h4>Tipo de usuario:</h4>
                           <div class="resp">
                              {{ user.privilegio[0].nombre_tipo }}
                           </div>
                        </div>
                     </div>
                     <div class="user-data-container-column">
                        <div class="profile-element company">
                           <h4>Empresa:</h4>
                           <div class="resp">
                              {{ user.empresa[0].nombre_empresa }}
                           </div>
                        </div>
                        <div class="profile-element workplace">
                           <h4>Lugar de trabajo:</h4>
                           <div class="resp">
                              {{ user.sucursal[0].nombre_sucursal }}
                           </div>
                        </div>
                        <div class="profile-element area">
                           <h4>Departamento:</h4>
                           <div class="resp">
                              {{ user.gerencia[0].nombre_gerencia }}
                           </div>
                        </div>
                        <div class="profile-element anexo">
                           <h4>Anexo:</h4>
                           <div class="resp">
                              <span v-if="!edit_profile">
                                 {{ user.anexo }}
                              </span>
                              <div class="input-container" v-if="edit_profile">
                                 <input
                                    type="text"
                                    v-model="user_edit.anexo"
                                    @keypress="handleAnexo()"
                                    @click="handleAnexo()"
                                    @keyup.delete="handleAnexo()"
                                 />
                              </div>
                           </div>
                        </div>
                        <!-- opcion cambiar contraseña -->
                        <div v-if="edit_profile" class="change-password">
                           <button @click="openModalPass">
                              Cambiar contraseña &nbsp;
                              <font-awesome-icon icon="key"></font-awesome-icon>
                           </button>
                        </div>
                        <div class="buttons" v-if="edit_profile">
                           <!-- cancelar cambios -->
                           <button @click="cancelEdit" class="cancel">
                              Cancelar
                           </button>
                           <!-- Guardar cambios de perfil-->
                           <button
                              @click="active_edit_profile_btn && editProfile()"
                              class="save"
                              :class="active_edit_profile_btn && 'active'"
                           >
                              Guardar cambios
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="user-subscription">
                  <div class="user-subscription-title">
                     <h3>Datos suscripción</h3>
                  </div>
                  <div class="user-subscription-container">
                     <div class="user-subscription-container-column">
                        <div class="subscription-date">
                           <h4>Fecha de suscripción:</h4>
                        </div>
                        <div class="due-date">
                           <h4>Fecha de vencimiento:</h4>
                        </div>
                     </div>
                     <div class="user-subscription-container-column">
                        <div class="subscription-type">
                           <h4>Tipo de suscripción:</h4>
                        </div>
                        <div class="subscription-email">
                           <h4>Email:</h4>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- Modal para cambiar contraseña -->
      <div class="modal-change-pass" v-if="open_modal_pass">






         <FormulateForm 
            class="modal-change-pass-container"
            v-model="userPasswordForm"
            @submit="onChangePasswordSubmit"
         >

            <div class="title">
               <h3>Cambiar contraseña</h3>
               <div class="close" @click="closeModalPass">
                  <font-awesome-icon icon="times"></font-awesome-icon>
               </div>
            </div>

            <!-- contraseña actual -->
            <div class="current-pass">
               <label >Contraseña actual</label>
               <FormulateInput 
                  type="password" 
                  name="oldPassword"
                  validation="required"
                  input-class="form-control mi-perfil__input" 
               />
            </div>

            <!-- nueva contraseña -->
            <div class="new-pass">
               <label>Nueva contraseña </label>
               <FormulateInput 
                  type="password" 
                  name="newPassword"
                  validation="^required|min:6,length|matches:/^(?=.*\d)(?=.*[!@#$%^&=/(){\}[\].´\\\-_'¿?¡\:\+\~*])(?=.*[a-z])(?=.*[A-Z]).+$/"
                  input-class="form-control mi-perfil__input" 
               />
            </div>

            <!-- repite nueva contraseña -->
            <div class="again-pass">
               <label>Repite tu contraseña </label>
               <FormulateInput 
                  type="password" 
                  name="newPasswordConfirm"
                  validation="bail|required|confirm:newPassword"
                  input-class="form-control mi-perfil__input" 
               />
            </div>

            <div class="save-pass">
               <FormulateInput 
                  type="submit"
                  label="Cambiar contraseña"
                  input-class="submit-change-password"
               />
            </div>
         </FormulateForm>








      </div>
      <Spinner v-if="show_spinner" />
      <Status
         v-if="open_modal_status"
         :msg="modal_status_msg"
         :status="modal_status"
         @close="closeStatus"
      />
   </div>
</template>

<script>
import Spinner from "../components/Spinner.vue";
import {
   EDIT_USER_PROFILE,
   EDIT_IMG_PROFILE,
} from "@/apollo/mutations";
import Status from "../components/Modales/Status.vue";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
   components: { Spinner, Status },
   data() {
      return {
         user: this.$ls.get("user"),
         id_usuario: this.$ls.get("user").id_usuario,
         user_edit: {
            phone: "",
            anexo: "",
            correo: "",
            img_base64: "",
            image_format: "",
         },
         title_component: {
            title: "Mi Perfil",
            subtitle: "",
         },
         tab_component: [{
               name: "Mi Perfil",
               route: "Perfil",
            },
         ],
         edit_profile: false,
         open_modal_pass: false,
         show_spinner: false,
         open_modal_status: false,
         modal_status_msg: "",
         modal_status: true,
         user_img_changed: false,
         active_save_btn: false,
         show_icon_correo: null,
         phone_has_change: false,
         mail_has_change: false,
         anexo_has_change: false,
         correo_error_msg: "",
         correo_success_msg: "",
         active_edit_profile_btn: false,
         user_default_img: require("@/assets/img/user_default_img.svg"),
         open_list: false,
         userPasswordForm: {}
      };
   },

   computed: {
      ...mapGetters("sidebar", ["userHasOnlyOneModule"]),
      ...mapState("sidebar", ["modules"]),

      userPhone() {
         return this.user_edit.phone;
      },
      userAnexo() {
         return this.user_edit.anexo;
      },
      userEmail() {
         return this.user_edit.correo;
      },

   },

   watch: {
      userAnexo() {
         this.handleAnexo();
      },
      userPhone() {
         this.handlePhone();
      },
   },

   methods: {
      ...mapActions("videoModule", ["addVideoAction", "changeVideoState", "deleteVideo"]),
      ...mapActions("profileModule", ["editPasswordAction", "verifyPasswordAction"]),

      // Cargar nueva imagen de perfil
      loadImages(event) {
         this.show_spinner = true;
         var files = event.target.files;
         if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
               if (
                  files[i].type === "image/jpeg" ||
                  files[i].type === "image/png"
               ) {
                  var reader = new FileReader();
                  var new_img = {
                     src: "",
                     format: "",
                  };
                  reader.onload = (e) => {
                     new_img = {
                        src: `${e.target.result}`,
                        format: `${e.target.result}`.split(",")[0],
                     };
                     this.user_edit.img_base64 = new_img.src;
                     this.user_edit.image_format = new_img.format;
                  };
                  reader.readAsDataURL(files[i]);
               }
            }
         }

         setTimeout(() => {
            this.editProfileImage();
         }, 100);
      },

      // Funcion para mostrar foto de perfil del usuario
      handleUserImage() {
         if (this.user_edit.img_base64 !== "" && this.edit_profile) {
            return this.user_edit.img_base64;
         } else {
            if (
               this.user.imagen_perfil !== null &&
               this.user.imagen_perfil !== "" &&
               this.user.imagen_perfil !== undefined
            ) {
               return this.user.imagen_perfil;
            } else {
               return this.user_default_img;
            }
         }
      },

      openModalPass() {
         this.open_modal_pass = true;
      },

      closeModalPass() {
         this.open_modal_pass = false;
      },

      cleanPasswordForm() {
         this.userPasswordForm = {};
      },

      closeStatus() {
         this.open_modal_status = false;
         this.show_icon_correo = null;
         this.active_edit_profile_btn = false;
      },

      // Activar opcion editar info perfil
      activeEditProfile() {
         this.edit_profile = true;
         this.open_list = false;
         this.user_edit.phone = this.user.telefono_movil;
         this.user_edit.anexo = this.user.anexo;
         this.user_edit.correo = this.user.correo;
         this.user_edit.imagen_perfil = this.user.imagen_perfil;
      },
      // funcion que maneja los iconos del input correo y lo valida
      handleEmail() {
         if (this.user_edit.correo.length > 0) {
            this.show_icon_correo = "load";
            setTimeout(() => {
               if (this.user_edit.correo !== this.user.correo) {
                  if (this.validUserEmail(this.user_edit.correo)) {
                     this.show_icon_correo = "check";
                     this.mail_has_change = true;
                     this.active_edit_profile_btn = true;
                     this.correo_success_msg = "Correo válido";
                  } else {
                     this.show_icon_correo = "error";
                     this.correo_error_msg = "Correo no válido";
                  }
               } else {
                  this.show_icon_correo = "error";
                  this.correo_error_msg = "Correo es igual al actual";
               }
            }, 2000);
         }
      },
      handlePhone() {
         if (this.user_edit.phone.length > 6) {
            if (this.user_edit.phone !== this.user.telefono_movil) {
               this.phone_has_change = true;
               this.active_edit_profile_btn = true;
            } else {
               this.active_edit_profile_btn = false;
            }
         }
      },
      handleAnexo() {
         if (this.user_edit.anexo !== "") {
            if (this.user_edit.anexo !== this.user.anexo) {
               this.anexo_has_change = true;
               this.active_edit_profile_btn = true;
            } else {
               this.active_edit_profile_btn = false;
            }
         }
      },
      // Funcion para permitir solo numeros en el input de telefono
      handleNumberInputs(evt) {
         evt = evt ? evt : window.event;
         var charCode = evt.which ? evt.which : evt.keyCode;
         if (
            charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            charCode !== 46
         ) {
            evt.preventDefault();
         } else {
            return true;
         }
      },
      // Validar formato de mail
      validUserEmail(email) {
         return String(email)
            .toLowerCase()
            .match(
               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
      },

      async onChangePasswordSubmit() {
         this.show_spinner = true;

         // validación de contraseña actual
         const check = await this.confirmCurrentPassword();
         if (check === false) {
            this.toastConfirmCurrentPasswordError();
            this.cleanPasswordForm();
            this.show_spinner = false;
            return;
         } else if (check === null) {
            this.toastGenericError();
            this.show_spinner = false;
            return;
         }

         const editPasswordRes = await this.editPassword();
         if (editPasswordRes) {
            // cambio exitoso
            this.toastEditPasswordSuccess();
            this.cleanPasswordForm();
            this.closeModalPass();
         } else if (editPasswordRes === null) {
            // no se guardó..
            this.toastEditPasswordError();
         } else if (editPasswordRes === false) {
            // no pasó las validaciones del back..
            this.toastEditPasswordInvalid();
         }

         this.show_spinner = false;
      },

      async confirmCurrentPassword() {
         const encodedPassword = btoa(this.userPasswordForm.oldPassword);
         const payload = {
            id_usuario: this.id_usuario,
            password: encodedPassword
         };
         const res = await this.verifyPasswordAction(payload);
         const check = res === null ? res : res.password;
         return check;
      },

      async editPassword() {
         const encodedNewPassword = btoa(btoa(btoa(this.userPasswordForm.newPassword)))
         const payload = {
            id_usuario: this.id_usuario,
            password: encodedNewPassword
         };
         const res = await this.editPasswordAction(payload);
         const check = res === null ? res : res.password;
         return check;
      },

      // Editar imagen de perfil
      editProfileImage() {
         var format = this.user_edit.image_format.split("/")[1].split(";")[0];
         var src = this.user_edit.img_base64.split(",")[1];
         this.$apollo
            .mutate({
               mutation: EDIT_IMG_PROFILE,
               variables: {
                  id_usuario: this.user.id_usuario,
                  imagen_perfil: src,
                  formato: format,
                  id_empresa_fk: this.user.empresa[0].id_empresa,
               },
            })
            .then((res) => {
               var resp = res.data.editImagenPerfil;
               // console.log("editProfileImage", resp);
               // console.log("this.user.imagen_perfil", this.user.imagen_perfil);

               this.user_img_changed = resp.imagen_perfil;
               this.user.imagen_perfil = resp.imagen_perfil;
               this.user_edit.img_base64 = resp.imagen_perfil;
               this.show_spinner = false;
               this.open_modal_status = true;
               this.modal_status = true;
               this.modal_status_msg = "Imagen cambiada con éxito";
            })
            .catch((err) => {
               console.log(err);
               this.open_modal_status = true;
               this.modal_status_msg =
                  "Ha ocurrido un problema, intenta nuevamente";
               this.modal_status = false;
               this.show_spinner = false;
            });
      },
      // Editar perfil
      editProfile() {
         this.show_spinner = true;

         if (
            this.anexo_has_change ||
            this.phone_has_change ||
            this.mail_has_change
         ) {
            this.$apollo
               .mutate({
                  mutation: EDIT_USER_PROFILE,
                  variables: {
                     id_usuario: this.user.id_usuario,
                     correo: this.user_edit.correo,
                     telefono_movil: this.user_edit.phone,
                     anexo: this.user_edit.anexo,
                     telefono_fijo: "",
                  },
               })
               .then((res) => {
                  var resp = res.data.editPerfilUsuario;
                  this.edit_profile = false;
                  this.show_spinner = false;
                  this.open_modal_status = true;
                  this.modal_status = true;
                  this.modal_status_msg = "Cambios guardados exitosamente";
                  // Actualizar usuario
                  this.$ls.remove("user");
                  this.$ls.set("user", resp);
                  this.user = this.$ls.get("user");
                  // Si hubo un cambio de imagen se agrega al obj usuario editado
                  if (this.user_img_changed !== null) {
                     this.user.imagen_perfil = this.user_img_changed;
                  }
               })
               .catch((err) => {
                  console.log(err);
                  this.show_spinner = false;
                  this.open_modal_status = true;
                  this.modal_status = false;
                  this.modal_status_msg =
                     "Ha ocurrido un problema, intenta nuevamente";
               });
         } else {
            this.show_spinner = false;
         }
      },
      // cancelar edicion
      cancelEdit() {
         this.edit_profile = false;
         this.show_icon_correo = null;
         this.active_edit_profile_btn = false;
      },
      // abrir y cerrar 'lista' que muestra opcion cerrar sesion
      openList() {
         if (this.open_list) {
            this.open_list = false;
         } else {
            this.open_list = true;
         }
      },
      toDashboard() {
         if (this.userHasOnlyOneModule) {
            this.redirectToLoneModule(this.modules);
         } else {
            this.$router.push({ name: "Inicio" });
         }
      },


      toastConfirmCurrentPasswordError() {
         this.$toast.open({
            message: "La contraseña actual ingresada es incorrecta.",
            type: "error",
            duration: 5000,
            position: "top-right",
         });
      },
      toastEditPasswordSuccess() {
         this.$toast.open({
            message: "Cambio de contraseña exitoso.",
            type: "success",
            duration: 5000,
            position: "top-right",
         });
      },
      toastEditPasswordError() {
         this.$toast.open({
            message: "Error al cambiar la contraseña. Por favor intenta nuevamente.",
            type: "error",
            duration: 5000,
            position: "top-right",
         });
      },
      toastEditPasswordInvalid() {
         this.$toast.open({
            message: "La contraseña debe contener al menos 6 caracteres, minúscula, mayúscula, un dígito y un caracter especial.",
            type: "error",
            duration: 5000,
            position: "top-right",
         });
      },
      toastGenericError() {
         this.$toast.open({
            message: "Error obteniendo información. Por favor recarga la página e intenta nuevamente.",
            type: "error",
            duration: 5000,
            position: "top-right",
         });
      },
   },
};
</script>

<style lang="scss" scoped>
*::v-deep .validation-messages {
	font-size: 0.8rem !important;
	color: red;
	padding-left: 0.5rem;
	margin-top: 2px;
}
</style>