const Wall= ()=> import('@/views/ADMIN/Wall.vue')
const WallList= ()=> import('@/components/Wall/List.vue')
const WallProfiles= ()=> import('@/components/Wall/ListPerfil.vue')
const WallAdd= ()=> import('@/components/Wall/Add.vue')

export const wallList = {
    path: "/admin-muro",
    name: "Muro-admin",
    meta: { requiresAuth: true},
    component: Wall,
    redirect: { name: "wall-list" },
    children: [{
            path: "list",
            meta: { requiresAuth: true},
            name: "wall-list",
            component: WallList
        }, {
            path: "perfiles",
            meta: { requiresAuth: true},
            name: "wall-perfiles",
            component: WallProfiles
        }, {
            path: "edit/:id",
            meta: { requiresAuth: true},
            name: "post-edit",
            props: true,
            component: WallAdd
        }, {
            path: "add",
            meta: { requiresAuth: true},
            name: "post-add",
            component: WallAdd
        },
    ],
};