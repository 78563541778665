import { apolloClient } from "../../../vue-apollo";
import {
  GET_VALORES,
  DASHBOARD_RECONOCE,
  GET_INDICADORES_RECONOCIMIENTOS_ANUALES,
  GET_INDICADORES_RECONOCEDORES_ANUALES,
  GET_RECONOCIDOS_MONTH,
  GET_GERENCIAS,
  GET_GERENCIAS_DATA,
  GET_SUCURSALES,
  GET_RECONOCIMIENTO_PLANTA,
  GET_RECONOCIMIENTOS_USUARIO,
  GET_RECONOCIMIENTOS_REPORTE
} from "../../../apollo/queries";
import { } from "../../../apollo/mutations";

export default {
  namespaced: true,
  state: {
    data_gerencias: [{ id: "0", type: "Todas", label: "Todas" }],
    data_sucursales: [{ id: "0", type: "Todas", label: "Todas" }],
  },

  mutations: {
    addGerencias(state, gerencias) {
      gerencias.forEach(gerenciaObj => {
        state.data_gerencias.push({
          id: gerenciaObj.id_gerencia,
          type: gerenciaObj.__typename,
          label: gerenciaObj.nombre_gerencia
        });
      });
    },
    addSucursales(state, sucursales) {
      sucursales.forEach(sucursalObj => {
        state.data_sucursales.push({
          id: sucursalObj.id_sucursal,
          type: sucursalObj.__typename,
          label: sucursalObj.nombre_sucursal
        });
      });
    }
  },

  actions: {
    async getValoresAction() {
      const params = {
        query: GET_VALORES
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.valoresReconocimientos;
        response = data;
      } catch (e) {
        console.log("response de getValoresAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getEstadisticasReconocimientosAction(_, data) {
      const params = {
        query: DASHBOARD_RECONOCE,
        variables: data
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.DashboardReconocimientos;
        response = data;
      } catch (e) {
        console.log("response de getEstadisticasReconocimientosAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getCantidadReconocimientosAnualAction(_, data) {
      const params = {
        query: GET_INDICADORES_RECONOCIMIENTOS_ANUALES,
        variables: data
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.IndicadoresReconocimientosAnuales;
        response = data;
      } catch (e) {
        console.log("response de getCantidadReconocimientosAnualAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getCantidadReconocedoresAnualAction(_, data) {
      const params = {
        query: GET_INDICADORES_RECONOCEDORES_ANUALES,
        variables: data
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.IndicadoresReconocedoresAnuales;
        response = data;
      } catch (e) {
        console.log("response de getCantidadReconocedoresAnualAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },
    async getReconocidosAction(_, data) {
      const params = {
        query: GET_RECONOCIDOS_MONTH,
        variables: data
      };

      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.totalReconocidosYearMonthReconocimientos;
        response = data;
      } catch (e) {
        console.log("response de getReconocidosAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },
    async getReconocimientosGerenciasAction(_, data) {
      const params = {
        query: GET_GERENCIAS_DATA,
        variables: data,
        fetchPolicy: "network-only"
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.reconocimientosGerenciasYearMonth;
        response = data;
      } catch (e) {
        console.log("response de getReconocimientosGerenciasAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getGerenciasAction({ commit }, data) {
      const params = {
        query: GET_GERENCIAS,
        variables: data
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.gerencias;
        response = data;
        commit("addGerencias", response);
      } catch (e) {
        console.log("response de getGerencias: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getSucursalesAction({ commit }, data) {
      const params = {
        query: GET_SUCURSALES,
        variables: data
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.sucursales;
        response = data;
        commit("addSucursales", response);
      } catch (e) {
        console.log("response de getSucursalesAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getReconocimientosPlantaAction(_, data) {
      const params = {
        query: GET_RECONOCIMIENTO_PLANTA,
        variables: data
      };
      let response = null;
      try {
        let data = null;
        response = await apolloClient.query(params);
        if (!response.loading) data = response.data.reconocimientosPlantaYearMonth;
        response = data;
      } catch (e) {
        console.log("response de getReconocimientosPlantaAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async findReconocimientosByUsernameAction(_, data) {
      const params = {
        query: GET_RECONOCIMIENTOS_USUARIO,
        variables: data
      };
      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.reconocidosYearMonthReconocimientosName;
      } catch (e) {
        console.log("response de findReconocimientosByUsernameAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    },

    async getReconocimientosReporteAction(_, data) {
      const params = {
        query: GET_RECONOCIMIENTOS_REPORTE,
        variables: data
      };
      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.reconocimientosReportes;
      } catch (e) {
        console.log("response de getReconocimientosReporteAction: ", e);
        console.log(e.networkError.result.errors);
      }
      return response;
    }


  },
};