import { apolloClient } from "../../../vue-apollo";
import { GET_MODULES_ADMIN_PRIVILEGIOS } from "@/apollo/queries";
export default {
	namespaced: true,
	state: { modules: [] },
	mutations: {
		SET_MODULES(state, res) {
			state.modules = res;
		},
	},
	getters: {
		userHasVideoModule(state) {
			const { modules } = state;
			return modules.some(module => module.moduloGeneral.id_modulo == "6");
		},
		userHasOnlyOneModule(state) {
			const { modules } = state;
			return (modules.length === 1) ||
				(modules.length === 2 && modules.some(module => module.moduloGeneral.id_modulo === "1"));
		},
	},
	actions: {
		async getModules({ commit }, data_) {
			const params = {
				query: GET_MODULES_ADMIN_PRIVILEGIOS,
				variables: data_
			};
			
			let response = null;
			try {
				const res = await apolloClient.query(params)
				if (!res.loading) response = res.data.modulosAdminUpdated;
				response.forEach(module => { module.visitas = 0 });
				commit("SET_MODULES", response);
			} catch (error) {
				console.error("Error de obteniendo lista de módulos", error);
			}
			return response;
		}
	},
};