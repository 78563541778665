const Recognize= ()=> import('@/views/ADMIN/Recognize.vue')
const RecognizeYear= ()=> import('@/components/Recognize/Year.vue')
const RecognizeBehavior= ()=> import('@/components/Recognize/Behavior.vue')
const RecognizeManagement= ()=> import('@/components/Recognize/Management.vue')
const RecognizeAge= ()=> import('@/components/Recognize/Age.vue')
const RecognizeRanking= ()=> import('@/components/Recognize/Ranking.vue')
const RecognizeContributor= ()=> import('@/components/Recognize/Contributor.vue')
const RecognizeReports= ()=> import('@/components/Recognize/Reports.vue')
const RecognizeDashboard= ()=> import('@/components/Recognize/Dashboard.vue')

export const recognizeList = {
  path: "/reconoce",
  name: "Reconoce",
  meta: { requiresAuth: true},
  component: Recognize,
  redirect: { name: "recognize-Dashboard" },
  children: [ {
      path: "year",
      meta: { requiresAuth: true},
      name: "recognize-year",
      component: RecognizeYear
    }, {
      path: "behavior",
      meta: { requiresAuth: true},
      name: "recognize-behavior",
      component: RecognizeBehavior
    }, {
      path: "management",
      meta: { requiresAuth: true},
      name: "recognize-management",
      component: RecognizeManagement
    }, {
      path: "age",
      meta: { requiresAuth: true},
      name: "recognize-age",
      component: RecognizeAge
    }, {
      path: "ranking",
      meta: { requiresAuth: true},
      name: "recognize-ranking",
      component: RecognizeRanking
    }, {
      path: "contributor",
      meta: { requiresAuth: true},
      name: "recognize-contributor",
      component: RecognizeContributor
    }, {
      path: "reports",
      meta: { requiresAuth: true},
      name: "recognize-reports",
      component: RecognizeReports
    }, {
      path: "Dashboard",
      meta: { requiresAuth: true},
      name: "recognize-Dashboard",
      component: RecognizeDashboard
    },
  ],
};