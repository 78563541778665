<template>
	<!-- Modal para recuperar contraseña -->
	<div class="login-recover-pass">
		<div class="login-recover-pass-container">
			<div class="login-recover-pass-container-head">
				<div class="title">¿Has olvidado tu contraseña?</div>
				<div class="close" @click="closeRecover()">
					<font-awesome-icon icon="times"></font-awesome-icon>
				</div>
			</div>
			<FormulateForm 
				class="login-recover-pass-container-body"
				v-model="userPassRecoveryForm"
				@submit="onPassRecoverySubmit"
			>
				<label class="text-secondary text-start px-2 mb-2">
					<small>Ingresa tu correo electrónico y te enviaremos un enlace para recuperarla:</small>
				</label>
				<div class="email-input-container">
					<FormulateInput
						type="text"
						name="email"
						validation="required|email"
						placeholder="Ingresa tu correo"
						error-behavior="submit" 
						wrapper-class="input-container"
					/>
				</div>
				<label class="text-secondary text-start px-2 mt-3">
					<small>
						Si en los próximos <strong>10 minutos</strong> no recibes un correo de <strong>linkiwork@carpetresapp.cl</strong>
						o no tienes un correo registrado, comunícate con el área de Comunicaciones.
					</small>
				</label>


				<FormulateInput
					type="submit"
					label="Enviar"
					input-class="btn"
					outer-class="btn-container"
				/>
			</FormulateForm>
		</div>
		<Status
			v-if="open_modal_status"
			:msg="modal_status_msg"
			:status="modal_status"
			@close="closeStatus"
		/>
		<Spinner v-if="show_spinner" />

	</div>
</template>

<script>
import { mapActions } from "vuex";
import Spinner from "../components/Spinner.vue";
import Status from "../components/Modales/Status.vue";

export default {
	components: { Spinner, Status },

	data() {
		return {
			open_modal_status: false,
			modal_status_msg: "",
			modal_status: false,
			userPassRecoveryForm: {},
			show_spinner: false
		};
	},

	methods: {
		...mapActions("loginModule", ["recoverPasswordAction"]),

		closeStatus() {
			this.open_modal_status = false;
		},

		closeRecover() {
			this.$emit("closeModal");
		},

		async onPassRecoverySubmit() {
			this.show_spinner = true;
			await this.passwordRecovery();
			this.show_spinner = false;
		},

		async passwordRecovery() {
			const payload = {
				correo: this.userPassRecoveryForm.email
			};
			const resp = await this.recoverPasswordAction(payload);
			this.open_modal_status = true;
			if (resp && resp.action === "success") {
				this.modal_status = true;
				this.modal_status_msg = "Se ha enviado un correo a la dirección que ingresaste.\
					Por favor sigue las instrucciones para restablecer la contraseña.";
				
			} else {
				this.modal_status = false;
				this.modal_status_msg = "Hubo un error en el envío del correo.\
					Por favor intenta nuevamente.";
			}
		}
	}
};
</script>
