import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import newsModule from "./modules/news/storeNews";
import docsModule from "./modules/docs/storeDocs";
import videoModule from "./modules/videos/storeVideos";
import WallPerfiles from "./modules/wall/storeWallPerfiles";
import cultureModule from "./modules/culture/storeCulture";
import videoCatModule from "./modules/videos/storeVideosCategories";
import encuestasModule from "./modules/encuestas/storeEncuestas";
import alertPushModule from "./modules/alertPush/storeAlertPush";
import adminHomeModule from "./modules/adminHome/storeHome.js";
import transporteModule from "./modules/transporte/storeTransporte";
import organigramaModule from "./modules/organigrama/storeOrganigrama";
import canalCorporativoModule from "./modules/tv/storeTV";
import tvCorporativaModule from "./modules/tv/storeAddContent";
import recognizeModule from "./modules/recognize/storeRecognize";
import customizationModule from "./modules/adminHome/storeHomeCMS";
import galleryModule from "./modules/gallery/storeGallery";
import benefitModule from "./modules/benefit/storeBenefits";
import profileModule from "./modules/profile/storeProfile";
import safetyModule from "./modules/safety/storeSafety";
import loginModule from "./modules/login/storeLogin";
import usersModule from "./modules/users/storeUsers";
import wallModule from "./modules/wall/storeWall";
import sidebar from "./modules/sidebar/storeSidebar.js";
import birthsModule from "./modules/births/storeBirths.js";
import hotelsModule from "./modules/hotels/storeHotels.js";

export default new Vuex.Store({
    state: {}, // se debe el valor de estado de la variable para trabajar los tiempos de cargo
    mutations: {},
    actions: {},
    modules: {
        hotelsModule,
        newsModule,
        galleryModule,
        usersModule,
        cultureModule,
        recognizeModule,
        benefitModule,
        docsModule,
        safetyModule,
        encuestasModule,
        alertPushModule,
        transporteModule,
        adminHomeModule,
        wallModule,
        WallPerfiles,
        sidebar,
        videoModule,
        videoCatModule,
        organigramaModule,
        canalCorporativoModule,
        tvCorporativaModule,
        customizationModule,
        profileModule,
        loginModule,
        birthsModule
    },
});