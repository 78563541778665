import { apolloClient } from "../../../vue-apollo";
import { VIDEOS_ARR, TOTAL_VIDEOS, VIDEOS_CATEGORIAS } from "@/apollo/queries";
import { VIDEO_ADD, VIDEO_EDIT, VIDEO_STATE, VIDEO_DELETE } from "@/apollo/mutations";

export default {
  namespaced: true,
  state: {
    allVideoVideos: [],
    videoVideosPagination: []
  },
  getters: {
    videoVideosFiltered: (state) => (page) => {
      if (state.videoVideosPagination.length === 0) {
        return [];
      } else {
        return state.allVideoVideos.filter(video => state.videoVideosPagination[page].videos.includes(video.id_video));
      }
    },
    paginationDataForVideoVideos(state) {
      return {
        page_count: state.videoVideosPagination.length == 0 ? 1 : state.videoVideosPagination.length,
      };
    }
  },
  mutations: {
    addVideo(state, fileObj) {
      state.allVideoVideos.unshift(fileObj);
      if ("videos" in state.videoVideosPagination[0]) state.videoVideosPagination[0].videos.unshift(fileObj.id_video);
    },
    editVideo(state, editedVideo) {
      const videoIdx = state.allVideoVideos.findIndex(v => v.id_video === editedVideo.id_video);
      if (videoIdx !== -1) Object.assign(state.allVideoVideos[videoIdx], editedVideo);
    } ,
    setAllVideos(state, videos) {
      state.allVideoVideos = videos;
    },
    setPagination(state, pagination) {
      state.videoVideosPagination = pagination;
    },
    deleteVideo(state, deletedVideoId) {
      state.allVideoVideos = state.allVideoVideos.filter(video => video.id_video != deletedVideoId);
    }
  },
  actions: {
    async addVideoAction({ commit }, payload) {
      const params = {
        mutation: VIDEO_ADD,
        variables: payload
      };

      let response = null;
      try {
        let data = null;
        await apolloClient.mutate(params).then(res => {
          if (!res.loading) data = res.data.agregarVideo;
          response = data;
        });
      } catch (e) {
        console.log("response de crear video: ", e);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          console.log('addVideoAction response:', response);
          commit("addVideo", response);
        } else {
          resolve(false);
        }
      });
    },

    async editVideoAction({ commit }, payload) {
      const params = {
        mutation: VIDEO_EDIT,
        variables: payload
      };

      let response = null;
      try {
        let data = null;
        const res = await apolloClient.mutate(params);
        if (!res.loading) data = res.data.editarVideo;
        response = data;
      } catch (e) {
        console.log("response de editar video: ", e);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          commit("editVideo", response);
        } else {
          resolve(false);
        }
      });
    },

    async getCategoriesAction(_, payload) {
      const params = {
        query: VIDEOS_CATEGORIAS,
        variables: payload,
      };

      let response = null;
      try {
        let data = null;
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.categoriasVideos;
          response = data;
        });
      } catch (e) {
        console.log("response de getCategories: ", e);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
        } else {
          resolve(false);
        }
      });      
    },

    async getVideoAction(_, payload) {
      const params = {
        query: VIDEOS_ARR,
        variables: payload,
				fetchPolicy: 'network-only'
      };

      let response = null;
      try {
        let data = null;
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.videosAdmin[0];
          response = data;
        });
      } catch (e) {
        console.log("response de getVideoAction: ", e);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
        } else {
          resolve(false);
        }
      });
    },

    async getVideos(_, payload) {
      const params = {
        query: VIDEOS_ARR,
        variables: payload,
      };

      let response = null;
      try {
        let data = null;
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.videosAdmin;
          response = data;
        });
      } catch (e) {
        console.log("response de get videos: ", e);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
        } else {
          resolve(false);
        }
      });
    },

    async getPagination({ commit }, data_) {
      const params = {
        query: TOTAL_VIDEOS,
        variables: data_
      };

      let response = null;
      try {
        let data = null;
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.paginacionTotalVideosAdmin;
          response = data;
        });
      } catch (e) {
        console.log("response de getPagination: ", e);
      }

      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          commit("setPagination", response);
        } else {
          commit("setPagination", response);
          resolve(false);
        }
      });
    },

    async changeVideoState({ commit }, data_) {
      const params = {
        mutation: VIDEO_STATE,
        variables: data_
      };

      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.changeStateVideo;
        commit("editVideo", response);
      } catch (e) {
        console.log("response de changeVideoState: ", e);
      }
      return response;
			// await dispatch("addVideoAction", { action: "edit", data: response });      
    },

    async deleteVideo({ commit }, data_) {
      const params = {
        mutation: VIDEO_DELETE,
        variables: data_
      };
      
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.eliminarVideo;
        commit("deleteVideo", response.id_video);
      } catch (e) {
        console.log("response de eliminar archivo de Videos: ", response);
        console.log("error de eliminar archivo de Videos: ", e);
      }

      return response;
    },

    async getAllVideoVideos({ dispatch, commit }, data_) {
      Object.assign(data_, {
        nombre: ""
      });

      const paginatedFiles = await dispatch("getPagination", data_);
      //console.log("estoy acá en lista de video revisando el resultado sin datos: ",  paginatedFiles);      
      let allVideos = null;
      if (paginatedFiles !== null) {
        let videosIds = [];
        paginatedFiles.forEach(page => {
          videosIds = videosIds.concat(page.videos);
        });
        allVideos = await dispatch("getVideos", { idEmpresa: data_.idEmpresa, idVideo: videosIds });
      } else {
        //commit("setAllVideos", allVideos);
      }
			return new Promise((resolve) => {
				if (allVideos != null) {
					resolve(allVideos);
					commit("setAllVideos", allVideos);
				} else {
          //commit("setAllVideos", allVideos);
					resolve(false);
				}
			});
    }
  }
};
