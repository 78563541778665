/**
 * La url pertenece a un video?
 * @param {String} url 
 * @returns {Boolean}
 */
export const isVideo= (url) => 
     /\.(mp4|avi|mov)$/i.test(url);
/**
 * La url pertenece a una imagen?
 * @param {String} url 
 * @returns {Boolean}
 */
export const isImage= (url) => 
     /\.(jpg|jpeg|png|gif)$/i.test(url);
  