const Users = () => import("@/views/ADMIN/Users.vue");
const UsersAdd = () => import("@/components/Users/Add.vue");
const UsersList = () => import("@/components/Users/List.vue");

export const userList = {
  path: "/usuarios",
  name: "Usuarios",
  meta: { requiresAuth: true },
  component: Users,
  redirect: { name: "usuarios-list" },
  children: [
    { path: "list", meta: { requiresAuth: true}, name: "usuarios-list", component: UsersList },
    { path: "add", meta: { requiresAuth: true}, name: "usuarios-add", component: UsersAdd },
    { path: "edit/:id", meta: { requiresAuth: true}, name: "usuarios-edit", component: UsersAdd },
  ],
};
