import { apolloClient } from "../../../vue-apollo";

import {
	LOGIN_WITH_ADM,
	CHECK_TOKEN,
	CHECK_TOKEN_RECOVERY,
	RECOVER_PASS,
	USUARIO_LOGIN_TOKEN,
	EDIT_PASSWORD_RECUPERACION
} from "../../../apollo/mutations";


export default {
	namespaced: true,
	state: {},

	mutations: {},
	actions: {


		async getUserDataFromTokenAction(_, data_) {
			const params = {
				mutation: CHECK_TOKEN,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.checkToken;
			} catch (e) {
				console.error("Error en chequeo de token:", e);
			}
			return response;
		},

		async loginAdmin(_, data_) {
			const params = {
				mutation: LOGIN_WITH_ADM,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.usuarioLoginTokenAdmin;
			} catch (e) {
				console.error("Error iniciando sesión en Administrador:", e);
			}
			return response;
		},

		async recoverPasswordAction(_, data_) {
			const params = {
				mutation: RECOVER_PASS,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.recuperarPassword;
			} catch (e) {
				console.error("Error recuperando contraseña:", e);
			}
			return response;
		},

		async checkTokenRecoveryAction(_, data_) {
			const params = {
				mutation: CHECK_TOKEN_RECOVERY,
				variables: data_
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				// devolvemos false para los casos cuando checkTokenRecuperar sea null,
				// para desambiguar la respuesta de esta función cuando haya error en la 
				// llamada del WS (que ocasiona retornar response = null, ver el catch ↓)  
				if (!res.loading) response = res.data.checkTokenRecuperar ?? false;
			} catch (e) {
				console.error("Error obteniendo id desde token:", e);
			}
			return response;
		},
		async editPasswordRecuperacion(_, data_) {
			
			const params = {
				mutation: EDIT_PASSWORD_RECUPERACION,
				variables: data_ // id_usuario, password, token, uuid
			};
			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.editPasswordRecuperacion ?? false;
			} catch (e) {
				console.error("Error obteniendo id desde token:", e);
			}
			return response;
		},

		async getAuthTokens(_, payload) {
			const params = {
				mutation: USUARIO_LOGIN_TOKEN,
				variables: payload
			};
			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.usuarioLoginToken;
			} catch (e) {
				console.error("Error getAuthTokens:", e);
			}
			return response;
		}

	},
};