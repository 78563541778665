const Hotels = () => import("@/views/ADMIN/Hotels.vue");
const HotelsDashboard = () => import("@/components/Hotels/Dashboard.vue");
const HotelRequest = () => import("@/components/Hotels/HotelRequest.vue");
const HotelConsult = () => import("@/components/Hotels/HotelConsult.vue");

export const hotelsList = {
  path: "/hoteleria",
  name: "Hoteleria",
  meta: { requiresAuth: true },
  component: Hotels,
  redirect: { name: "hotels-Dashboard" },
  children: [
    {
      path: "Dashboard",
      meta: { requiresAuth: true},
      name: "hotels-Dashboard",
      component: HotelsDashboard,
    },
    {
      path: "Solicitudes",
      meta: { requiresAuth: true},
      name: "hotels-request",
      component: HotelRequest,
    },
    {
      path: "Consultas",
      meta: { requiresAuth: true},
      name: "hotels-consult",
      component: HotelConsult,
    },
  ],
};
