<template>
   <div class="logintoken">
      <div class="logintoken-recover-pass">
         <div class="logintoken-recover-pass-container">
            <div class="login-container-top">
               <h1 class="welcome">Crea una nueva contraseña</h1>
            </div>

            <FormulateForm 
					class="logintoken-recover-pass-container-body"
					v-model="userPasswordForm"
					@submit="onChangePasswordSubmit"
				>
               <div class="pass-input">
						<label>Ingrese una nueva contraseña</label>
						<FormulateInput 
							type="password" 
							name="newPassword"
							validation="^required|min:6,length|matches:/^(?=.*\d)(?=.*[!@#$%^&=/(){\}[\].´\\\-_'¿?¡\:\+\~*])(?=.*[a-z])(?=.*[A-Z]).+$/"
							input-class="form-control"
							wrapper-class="input-container"
						/>
               </div>
                <div class="pass-input">
						<label>Repite nueva contraseña</label>
						<FormulateInput 
							type="password" 
							name="newPasswordConfirm"
							validation="bail|required|confirm:newPassword"
							input-class="form-control" 
							wrapper-class="input-container"
						/>
               </div>

					<div class="save-pass">
						<FormulateInput 
							type="submit"
							label="Actualizar"
							input-class="btn btn-active"
						/>
					</div>
            </FormulateForm>

         </div>
      </div>
		<Spinner v-if="show_spinner" />
      <Status
         v-if="open_modal_status"
         :msg="modal_status_msg"
         :status="modal_status"
         @close="closeStatus()"
      />
   </div>
</template>

<script>
import Status from "../components/Modales/Status.vue";
import Spinner from "../components/Spinner.vue";
import { mapActions } from "vuex";

export default {
   components: { Spinner, Status },

   data() {
      return {
         show_spinner: false,
         open_modal_status: false,
         modal_status_msg: "",
         modal_status: true,
         token: this.$route.params.token,
         key: this.$route.params.key,
         user_id: "",
			userPasswordForm: {}
      };
   },

   mounted() {
		this.setUserIdFromToken();
   },

   methods: {
		...mapActions("loginModule", ["checkTokenRecoveryAction", "editPasswordRecuperacion"]),
      ...mapActions("profileModule", ["editPasswordAction"]),

		async onChangePasswordSubmit() {
         this.show_spinner = true;

         const editPasswordRes = await this.editPassword();

         if (editPasswordRes) {
            // cambio exitoso
            this.toastEditPasswordSuccess();
				this.redirectToLogin();
         } else if (editPasswordRes === null) {
            // no se guardó..
            this.toastEditPasswordError();
         } else if (editPasswordRes === false) {
            // no pasó las validaciones del back..
            this.toastEditPasswordInvalid();
         }

         this.show_spinner = false;
		},

      async setUserIdFromToken() {
			this.show_spinner = true;
			const payload = {
				token: this.token,
				uuid: this.key
			};
			const check = await this.checkTokenRecoveryAction(payload);
			if (check) {
				this.user_id = check.id_usuario;
			} else {
				this.open_modal_status = true;
				this.modal_status = false;
				if (check === false) {
					this.modal_status_msg = "La URL ingresada es inválida.";				
				} else if (check === null) {
					this.modal_status_msg = "Ha ocurrido un problema. Por favor intenta nuevamente.";
				}
			}
			this.show_spinner = false;
      },

      async editPassword() {
         const encodedNewPassword = btoa(btoa(btoa(this.userPasswordForm.newPassword)));
         const payload = {
            id_usuario: this.user_id,
            password: encodedNewPassword,
            token: this.token, 
            uuid: this.key,
         };
         const res = await this.editPasswordRecuperacion(payload);
         const check = res === null ? res : res.password;
         return check;
      },

      closeStatus() {
			this.open_modal_status = false;
         if (this.modal_status === false) {
            this.redirectToLogin();
         }
      },

		redirectToLogin() {
         this.$router.push({ name: "Login" });
		},

		toastEditPasswordSuccess() {
         this.$toast.open({
            message: "Cambio de contraseña exitoso.",
            type: "success",
            duration: 5000,
            position: "top-right",
         });
      },
      toastEditPasswordError() {
         this.$toast.open({
            message: "Error al cambiar la contraseña. Por favor intenta nuevamente.",
            type: "error",
            duration: 5000,
            position: "top-right",
         });
      },
      toastEditPasswordInvalid() {
         this.$toast.open({
            message: "La contraseña debe contener al menos 6 caracteres, minúscula, mayúscula, un dígito y un caracter especial.",
            type: "error",
            duration: 5000,
            position: "top-right",
         });
      },
   },
};
</script>
