import { apolloClientCC as apolloClient } from "../../../vue-apollo";
import {
  GET_CANALES,
  GET_ESTADO_CANALES,
  GET_LIST_SCREENS,
  GET_ONE_SCREENS,
  GET_TIMEZONES,
  GET_SCREENS_REGISTERED,
  GET_PANTALLAS_PAGINADAS,
  GET_TOTAL_PANTALLAS_ONOFF,
  GET_PALETA_COLORES,
  GET_LOG_TVS_PAGINATION,
  GET_LOG_TVS,
  GET_LOG_CHANELS,
} from "../../../apollo/queries";
import {
  CANAL_ADD,
  CANAL_UPDATE,
  CANAL_DELETE,
  VALIDATE_SCREEN_DISP,
  CREATE_SCREEN,
  UPDATE_SCREEN,
  DELETE_SCREEN,
  CHANGE_SCREEN_STATE,
  SEND_MAIL_NEW_SCREEN
} from "../../../apollo/mutations";
export default {
  namespaced: true,
  state: {
    newSreen: {},
    CantidadPantallasAdmin: {
      pantallas_contrato: "0",
      registradas: "0",
      no_registradas: "0",
      offline: "0",
      online: "0",
    },
    GetTimezones: [],
    GetPantallaList: [],
    listCanales: [],
    GetEstadoCanalesFiltro: [{
      id_estado: 5,
      nombre_estado: "Todos"
    }],
    logsIds:{},
    logs:{},
  },
  getters: {
    getScreenById: (state) => (id) => {
      const { GetPantallaList } = state;
      const screen = GetPantallaList.find((screen) => screen.id_pantalla == id);
      return screen;
    },
    getChannelById: (state) => async (id) => {
      const { listCanales } = state;
      const channel = listCanales.find((channel) => {
        return channel.id_canal == id
      } );
      return channel;
    },
    getNumLogsByType: (state) => (tipo) => {
      const list = state.logsIds[tipo]
      if(list === undefined || list === null) return 0
      return list.reduce((a, b) => a + b.ids.length, 0);
    },
    areThereScreensAvailable(state) {
      const {pantallas_contrato, registradas} = 
        state.CantidadPantallasAdmin;
      return Number(pantallas_contrato) > Number(registradas);
    },
  },
  mutations: {

    getLogsIds(state, {response, tipo}) {
      state.logsIds[tipo] = response
    },
    getLogs(state, {response, tipo}) {
      state.logs[tipo] = response
    },
    SET_CANALES(state, canales) {
      let finalCanales = canales.filter(x => x.estado.nombre_estado !== "Eliminado")
      state.listCanales = finalCanales;
    },
    SET_ESTADO_CANALES_FILTRO(state, estados) {
      let finalEstados = estados.filter(x => x.nombre_estado !== "Eliminado")
      state.GetEstadoCanalesFiltro = finalEstados;
      // el estado "Todos" en el top
      state.GetEstadoCanalesFiltro.sort((a, b) => (a.id_estado === 5 ? -1 : b.id_estado === 5 ? 1 : 0));
    },
    SET_NEW_CANAL(state, canal) {
      state.listCanales.unshift(canal);
    },
    editCanalList(state, canal) {
      let canal_index = state.listCanales.findIndex(canal_ => canal_.id_canal == canal.id_canal);
      if (canal_index != undefined) {
        if (state.listCanales.length > 0) Object.assign(state.listCanales[canal_index], canal)
      }
    },
    deleteCanalLista(state, data) {
      let found_index = state.listCanales.findIndex(canal => canal.id_canal == data.id_canal);
      state.listCanales.splice(found_index, 1);
    },
    setPantallas(state, resp) {
      state.GetPantallaList = resp;
    },
    setPantallaOne(state, resp) {
      state.newSreen = resp;
    },
    setTimezonesOptions(state, resp) {
      if (state.GetTimezones.length < 1) {
        state.GetTimezones = resp;
      }
    },
    setCantidadPantallas(state, resp) {
      if (parseInt(state.CantidadPantallasAdmin.registradas) < 1) {
        state.CantidadPantallasAdmin = resp;
      }
    },
    setNewScreen(state, resp) {
      state.CantidadPantallasAdmin.registradas = parseInt(state.CantidadPantallasAdmin.registradas) + 1;
      state.GetPantallaList.push(resp);
      state.GetPantallaList.sort((a, b) => parseInt(b.id_pantalla) - parseInt(a.id_pantalla));
    },
    setUpdateScreen(state, resp) {
      let index = state.GetPantallaList.findIndex(screen => parseInt(screen.id_pantalla) == parseInt(resp.id_pantalla));
      if (index > -1) {
        if (state.GetPantallaList.length > 0) Object.assign(state.GetPantallaList[index], resp)

      }
    },
    setDeleteScreen(state, resp) {
      let found_index = state.GetPantallaList.findIndex(screen => screen.id_pantalla == resp.id_pantalla);
      state.CantidadPantallasAdmin.registradas = parseInt(state.CantidadPantallasAdmin.registradas) - 1;
      state.GetPantallaList.splice(found_index, 1);
    },
  },
  actions: {
    async sendMailNewScreen() {
      const params = {
        mutation: SEND_MAIL_NEW_SCREEN,
        variables: {}
      };
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.SendMailNuevasPantallas;
      } catch (e) {
        console.log("Falla metodo addCanal: ", e);
      }
      return response;
    },
    async getLogsIds({commit}, _data) {
      const params = {
        query: GET_LOG_TVS_PAGINATION,
        fetchPolicy: "network-only",
        variables: _data
      };
      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.PaginacionLogsCanalPantallas;
        
        commit("getLogsIds", {response, tipo:_data.tipo});
      } catch (e) {
        console.log("Falla action PaginacionLogsCanalPantallas: ", e);
      }
      return response;
    },

    async getLogs({commit, state}, _data) {
      if(state.logsIds[_data.tipo].length === 0) {
        commit("getLogs",{response: [], tipo:_data.tipo});
        return [];
      }
      const data_ = state.logsIds[_data.tipo]
        .find(x => x.pagina == _data.page);
      const params = {
        query: _data.tipo === "pantalla" ? GET_LOG_TVS: GET_LOG_CHANELS,
        variables: {ids:data_.ids}
      };
      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = _data.tipo === "pantalla" ? 
          res.data.InformacionLogsPantallas : 
          res.data.InformacionLogsCanales;
          commit("getLogs", {response, tipo:_data.tipo});
      } catch (e) {
        console.log("Falla action InformacionLogsPantallas: ", e);
      }
      return response;
    },

    async getPaletaColoresAction(_, data_) {
      const params = {
        query: GET_PALETA_COLORES,
        variables: data_
      };
      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.GetPaletaColores;
      } catch (e) {
        console.log("Falla action getPaletaColores: ", e);
      }
      return response;
    },

    async getEstadoCanales({ commit }) {
      let params = {
        query: GET_ESTADO_CANALES
      }
      let response = [];
      try {
        const res = await apolloClient.query(params)
        if (!res.loading) response = res.data.EstadoCanales;
        commit("SET_ESTADO_CANALES_FILTRO", response);
      } catch (e) {
        console.log("Error metodo getEstadoCanales", e);
      }
    },

    async addCanal({ commit }, data_) {
      const params = {
        mutation: CANAL_ADD,
        variables: data_
      };
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.SaveCanal;
        commit("SET_NEW_CANAL", response);
      } catch (e) {
        console.log("Falla metodo addCanal: ", e);
      }
      return response;
    },

    async editCanal({ commit }, data_) {
      let params = {
        mutation: CANAL_UPDATE,
        variables: data_
      };
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.UpdateCanal;
        commit("editCanalList", response);
      } catch (e) {
        console.log("Falla metodo editCanal: ", e);
      }
      return response;
    },

    async deleteCanal({ commit }, data_) {
      const params = {
        mutation: CANAL_DELETE,
        variables: data_
      };
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.DeleteCanal;
        commit("deleteCanalLista", response);
      } catch (e) {
        console.log("Error de deleteCanal canal: ", e);
      }
      return response;
    },
    
    async GetPantallaListAction({ commit }) {
      let response = [];
      let params = {
        query: GET_LIST_SCREENS
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.GetPantallaList;
        });
      } catch (e) {
        console.log("response de listar pantallas: ", e);
        response = null;
      }
      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          commit("setPantallas", response);
        } else {
          resolve(null);
        }
      });
    },

    async GetOnePantallaAction({ commit }, data_) {
      let response = [];
      let params = {
        query: GET_ONE_SCREENS,
        variables: data_,
        fetchPolicy: 'network-only',
      };
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) response = res.data.GetPantallaOne;
        });
      } catch (e) {
        console.log("response de buscar pantalla: ", e);
        response = null;
      }
      return new Promise((resolve) => {
        if (response != null) {
          resolve(response);
          commit("setPantallaOne", response)
        } else {
          resolve(null);
        }
      });
    },

    async GetCantidadPantallasAction({ commit }) {
      const params = {
        query: GET_SCREENS_REGISTERED
      }

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.CantidadPantallasAdmin;
        commit("setCantidadPantallas", response);
      } catch (e) {
        console.log("error en GetCantidadPantallasAction", e);
      }
      return response;
    },

    async GetCantidadPantallasPaginadasAction(_, data_) {
      const params = {
        query: GET_PANTALLAS_PAGINADAS,
        variables: data_
      }

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.PantallasPaginadas;
      } catch (e) {
        console.log("error en GetCantidadPantallasPaginadasAction", e);
      }
      return response;
    },

    async GetCantidadPantallasOnOffAction(_, data_) {
      const params = {
        query: GET_TOTAL_PANTALLAS_ONOFF,
        variables: data_
      }

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.TotalPantallasOnOff;
      } catch (e) {
        console.log("error en GetCantidadPantallasOnOffAction", e);
      }
      return response;
    },

    async GetListCanalesAction({ commit }, data_) {
      const params = {
        query: GET_CANALES,
        variables: data_,
        fetchPolicy: "network-only"
      }
      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.GetListCanales;
        commit("SET_CANALES", response);
    } catch (e) {
        console.log("listar canales falla: ", e);
      }
      return response;
    },

    async GetTimezonesActions({ commit }) {
      let response = {};
      let data = {};
      let params = {
        query: GET_TIMEZONES,
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.GetZonasHorarias;
          response = data;
        });
      } catch (e) {
        console.log("listar zonas horarias falla: ", e);
        response = null
      }
      return new Promise((resolve) => {
        if (response != null) {
          resolve(response)
          commit("setTimezonesOptions", response);
        } else {
          resolve(null)
        }
      });
    },

    //mutaciones
    async SaveScreenAction({ commit }, data_) {
      const params = {
        mutation: CREATE_SCREEN,
        variables: data_
      };
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.SavePantalla;
        commit("setNewScreen", response);
      } catch (e) {
        console.log("guardar pantalla falla: ", e);
      }
      return response;
    },

    async UpdateScreenAction({ commit }, data_) {
      const params = {
        mutation: UPDATE_SCREEN,
        variables: data_
      };
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.UpdatePantalla;
        commit("setUpdateScreen", response);
      } catch (e) {
        console.log("editar pantalla falla: ", e);
      }
      return response;
    },

    async DeleteScreenAction({ commit }, data_) {
      const params = {
        mutation: DELETE_SCREEN,
        variables: data_
      };
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.DeletePantalla;
        commit("setDeleteScreen", response);
      } catch (e) {
        console.log("eliminar pantalla falla: ", e);
      }
      return response;
    },

    async ValidateScreenDispAction(_, data_) {
      let params = {
        mutation: VALIDATE_SCREEN_DISP,
        variables: data_
      }

      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.ValidarDispositivo;
      } catch (e) {
        console.log("validar codigo falla: ", e);
      }
      return response;
    },

    async RejectScreenSaving(/* { commit } */_, data_) {
      const params = {
        mutation: CHANGE_SCREEN_STATE,
        variables: data_
      };
      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = res.data.UpdateEstadoDispositivo;
      } catch (e) {
        console.log("rechazar pantalla falla: ", e);
      }
      return response;
    }

  }
};