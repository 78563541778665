import gql from "graphql-tag";

export const ADD_VIDEO = gql`
  mutation addVideo(
    $titulo_video: String!
    $descripcion_video: String!
    $video: String!
    $formato_video: String!
    $imagen: String!
    $formato_imagen: String!
    $id_empresa_fk: String!
    $id_categoria: String!
  ) {
    agregarVideo(
      titulo_video: $titulo_video
      descripcion_video: $descripcion_video
      video: $video
      formato_video: $formato_video
      imagen: $imagen
      formato_imagen: $formato_imagen
      id_empresa_fk: $id_empresa_fk
      id_categoria: $id_categoria
    ) {
      id_video
      titulo_video
      descripcion_video
      nombre_carpeta
      url_video
      url_imagen
      id_empresa_fk
      categoria {
        id_categoria
        nombre_categoria
      }
    }
  }
`;

export const DELETE_GALERIA = gql`
  mutation eliminarGaleria(
    $id_empresa: String!
    $id_galeria: String!
    $id_usuario: String!
  ) {
    eliminarGaleria(
      id_empresa: $id_empresa
      id_galeria: $id_galeria
      id_usuario: $id_usuario
    ) {
      id_galeria
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUsuario(
    $id_usuario: String!
    $rut_usuario: String!
    $dv_usuario: String!
    $primer_nombre: String!
    $segundo_nombre: String!
    $apellido_paterno: String!
    $apellido_materno: String!
    $correo: String!
    $telefono_movil: String!
    $anexo: String!
    $telefono_fijo: String!
    $fecha_nacimiento: String!
    $fecha_ingreso: String!
    $genero: String!
    $entity_id: String!
    $id_empresa_fk: String!
    $id_cargo_fk: String!
    $id_gerencia_fk: String!
    $id_sucursal_fk: String!
    $id_turno_fk: String!
    $id_nacion_fk: String!
    $id_sociedad_fk: String!
    $id_sindicato_fk: String!
    $id_privilegio_fk: String!
    $id_grupo_fk: String!
    $id_modificador: String!
    $fecha_movimiento: String!
  ) {
    editUsuario(
      id_usuario: $id_usuario
      rut_usuario: $rut_usuario
      dv_usuario: $dv_usuario
      primer_nombre: $primer_nombre
      segundo_nombre: $segundo_nombre
      apellido_paterno: $apellido_paterno
      apellido_materno: $apellido_materno
      correo: $correo
      telefono_movil: $telefono_movil
      anexo: $anexo
      telefono_fijo: $telefono_fijo
      fecha_nacimiento: $fecha_nacimiento
      fecha_ingreso: $fecha_ingreso
      genero: $genero
      entity_id: $entity_id
      id_empresa_fk: $id_empresa_fk
      id_cargo_fk: $id_cargo_fk
      id_gerencia_fk: $id_gerencia_fk
      id_sucursal_fk: $id_sucursal_fk
      id_turno_fk: $id_turno_fk
      id_nacion_fk: $id_nacion_fk
      id_sociedad_fk: $id_sociedad_fk
      id_sindicato_fk: $id_sindicato_fk
      id_privilegio_fk: $id_privilegio_fk
      id_grupo_fk: $id_grupo_fk
      id_modificador: $id_modificador
      fecha_movimiento: $fecha_movimiento
    ) {
      id_usuario
      rut_usuario
      dv_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      correo
      telefono_movil
      anexo
      telefono_fijo
      genero
      imagen_perfil
      fecha_nacimiento
      fecha_ingreso
      id_sucursal_fk
      id_privilegio_fk
      privilegio {
        nombre_tipo
      }
      sucursal {
        nombre_sucursal
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUsuario(
    $rut_usuario: String!
    $dv_usuario: String!
    $primer_nombre: String!
    $segundo_nombre: String!
    $apellido_paterno: String!
    $apellido_materno: String!
    $correo: String!
    $telefono_movil: String!
    $anexo: String!
    $telefono_fijo: String!
    $fecha_nacimiento: String!
    $fecha_ingreso: String!
    $genero: String!
    $id_empresa_fk: String!
    $id_cargo_fk: String!
    $id_gerencia_fk: String!
    $id_sucursal_fk: String!
    $id_turno_fk: String!
    $id_privilegio_fk: String!
    $entity_id: String!
    $id_nacion_fk: String!
    $id_sociedad_fk: String!
    $id_sindicato_fk: String!
    $id_grupo_fk: String!
    $id_creador: String!
  ) {
    addUsuario(
      rut_usuario: $rut_usuario
      dv_usuario: $dv_usuario
      primer_nombre: $primer_nombre
      segundo_nombre: $segundo_nombre
      apellido_paterno: $apellido_paterno
      apellido_materno: $apellido_materno
      correo: $correo
      telefono_movil: $telefono_movil
      anexo: $anexo
      telefono_fijo: $telefono_fijo
      fecha_nacimiento: $fecha_nacimiento
      fecha_ingreso: $fecha_ingreso
      genero: $genero
      id_empresa_fk: $id_empresa_fk
      id_cargo_fk: $id_cargo_fk
      id_gerencia_fk: $id_gerencia_fk
      id_sucursal_fk: $id_sucursal_fk
      id_turno_fk: $id_turno_fk
      id_privilegio_fk: $id_privilegio_fk
      entity_id: $entity_id
      id_nacion_fk: $id_nacion_fk
      id_sociedad_fk: $id_sociedad_fk
      id_sindicato_fk: $id_sindicato_fk
      id_grupo_fk: $id_grupo_fk
      id_creador: $id_creador
    ) {
      id_usuario
      rut_usuario
      dv_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      correo
      telefono_movil
      anexo
      telefono_fijo
      genero
      imagen_perfil
      fecha_nacimiento
      fecha_ingreso
      id_sucursal_fk
      id_privilegio_fk
      privilegio {
        nombre_tipo
      }
      sucursal {
        nombre_sucursal
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation eliminarUsuario(
    $id_usuario: String!
    $id_empresa_fk: String!
    $id_eliminador: String!
  ) {
    eliminarUsuario(
      id_usuario: $id_usuario
      id_empresa_fk: $id_empresa_fk
      id_eliminador: $id_eliminador
    ) {
      id_usuario
    }
  }
`;

export const CREATE_GALERIA = gql`
  mutation crearGaleria(
    $titulo_galeria: String!
    $imagenes_originales: [String]
    $formatos_originales: [String]
    $imagenes_thumb: [String]
    $formatos_thumb: [String]
    $id_empresa: String!
    $fecha: String!
    $id_usuario: String!
    $tipo_segementacion: String!
    $segmentaciones: [String]
    $alerta_push: String!
  ) {
    crearGaleria(
      titulo_galeria: $titulo_galeria
      imagenes_originales: $imagenes_originales
      formatos_originales: $formatos_originales
      imagenes_thumb: $imagenes_thumb
      formatos_thumb: $formatos_thumb
      id_empresa: $id_empresa
      fecha: $fecha
      id_usuario: $id_usuario
      tipo_segementacion: $tipo_segementacion
      segmentaciones: $segmentaciones
      alerta_push: $alerta_push
    ) {
      id_galeria
      titulo_galeria
      fecha_galeria
      id_estado_fk
      nombre_carpeta
      total_likes_admin
      imagenes {
        id_imagen
        url_imagen_original
        url_imagen_thumb
        likes {
          id_like
          usuario {
            id_usuario
          }
        }
      }
    }
  }
`;

export const EDIT_GALERIA = gql`
  mutation editarGaleria(
    $titulo_galeria: String!
    $id_imagenes: [String]
    $imagenes_originales: [String]
    $formatos_originales: [String]
    $imagenes_thumb: [String]
    $formatos_thumb: [String]
    $id_empresa: String!
    $id_galeria: String!
    $fecha: String!
    $id_usuario: String!
    $tipo_segementacion: String!
    $segmentaciones: [String]
    $alerta_push: String!
  ) {
    editarGaleria(
      titulo_galeria: $titulo_galeria
      id_imagenes: $id_imagenes
      imagenes_originales: $imagenes_originales
      formatos_originales: $formatos_originales
      imagenes_thumb: $imagenes_thumb
      formatos_thumb: $formatos_thumb
      id_empresa: $id_empresa
      id_galeria: $id_galeria
      fecha: $fecha
      id_usuario: $id_usuario
      tipo_segementacion: $tipo_segementacion
      segmentaciones: $segmentaciones
      alerta_push: $alerta_push
    ) {
      id_galeria
      titulo_galeria
      fecha_galeria
      id_estado_fk
      nombre_carpeta
      total_likes_admin
      imagenes {
        id_imagen
        url_imagen_original
        url_imagen_thumb
        likes {
          id_like
          usuario {
            id_usuario
          }
        }
      }
    }
  }
`;

export const LIKE_IMAGE = gql`
  mutation darLikeImagen(
    $id_usuario: String!
    $id_empresa: String!
    $id_imagen: String!
    $id_galeria: String!
  ) {
    darLikeImagen(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_imagen: $id_imagen
      id_galeria: $id_galeria
    ) {
      id_galeria
      fecha_galeria
      id_estado_fk
      titulo_galeria
      total_likes_admin
      imagenes {
        id_imagen
        url_imagen_original
        url_imagen_thumb
        likes {
          id_like
          usuario {
            id_usuario
          }
        }
      }
    }
  }
`;

export const UNLIKE_IMAGEN = gql`
  mutation quitarLikeImagen(
    $id_usuario: String!
    $id_empresa: String!
    $id_imagen: String!
    $id_galeria: String!
  ) {
    quitarLikeImagen(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_imagen: $id_imagen
      id_galeria: $id_galeria
    ) {
      id_galeria
      fecha_galeria
      id_estado_fk
      titulo_galeria
      total_likes_admin
      imagenes {
        id_imagen
        url_imagen_original
        url_imagen_thumb
        likes {
          id_like
          usuario {
            id_usuario
          }
        }
      }
    }
  }
`;

export const ADD_NOTICIA = gql`
  mutation agregarNoticia(
    $id_empresa_fk: String!
    $titulo: String!
    $id_categoria: String!
    $tipo_contenido: [String]
    $contenido: [String]
    $formato_contenido: [String]
    #$formato_app: String!
    $id_tipo: String!
    $imagen_principal: String!
    $formato_principal: String!
    $fecha_noticia: String!
    $url_externo_app: [String]
    $url_externo_intranet: [String!]
    $nombre_documento: [String]
    $tipo_segementacion: String!
    $segmentaciones: [String]
    $id_creador: String!
    $alerta_push: String!
  ) {
    agregarNoticia(
      id_empresa_fk: $id_empresa_fk
      titulo: $titulo
      id_categoria: $id_categoria
      tipo_contenido: $tipo_contenido
      contenido: $contenido
      formato_contenido: $formato_contenido
      #formato_app: $formato_app
      id_tipo: $id_tipo
      imagen_principal: $imagen_principal
      formato_principal: $formato_principal
      fecha_noticia: $fecha_noticia
      url_externo_app: $url_externo_app
      url_externo_intranet: $url_externo_intranet
      nombre_documento: $nombre_documento
      tipo_segementacion: $tipo_segementacion
      segmentaciones: $segmentaciones
      id_creador: $id_creador
      alerta_push: $alerta_push
    ) {
      id_noticia
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      fecha_creacion
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        #nombre_documento
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
      }
    }
  }
`;

export const EDIT_NOTICIA = gql`
  mutation editarNoticia(
    $id_noticia: String!
    $id_empresa_fk: String!
    $titulo: String!
    $id_categoria: String!
    $tipo_contenido: [String]
    $id_contenidos: [String]
    $contenido: [String]
    $formato_contenido: [String]
    $formato_app: String!
    $id_tipo: String!
    $imagen_principal: String!
    $formato_principal: String!
    $fecha_noticia: String!
    $url_externo_app: [String]
    $url_externo_intranet: [String]
    $nombre_documento: [String]
    $tipo_segementacion: String!
    $segmentaciones: [String]
    $id_modificador: String!
    $alerta_push: String!
  ) {
    editarNoticia(
      id_noticia: $id_noticia
      id_empresa_fk: $id_empresa_fk
      titulo: $titulo
      id_categoria: $id_categoria
      tipo_contenido: $tipo_contenido
      id_contenidos: $id_contenidos
      contenido: $contenido
      formato_contenido: $formato_contenido
      formato_app: $formato_app
      id_tipo: $id_tipo
      imagen_principal: $imagen_principal
      formato_principal: $formato_principal
      fecha_noticia: $fecha_noticia

      url_externo_app: $url_externo_app
      url_externo_intranet: $url_externo_intranet

      nombre_documento: $nombre_documento

      tipo_segementacion: $tipo_segementacion
      segmentaciones: $segmentaciones
      id_modificador: $id_modificador
      alerta_push: $alerta_push
    ) {
      id_noticia
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      fecha_creacion
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        #nombre_documento
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
      }
    }
  }
`;

export const DELETE_NOTICIA = gql`
  mutation eliminarNoticia(
    $id_empresa_fk: String!
    $id_noticia: String!
    $id_eliminador: String!
  ) {
    eliminarNoticia(
      id_empresa_fk: $id_empresa_fk
      id_noticia: $id_noticia
      id_eliminador: $id_eliminador
    ) {
      id_noticia
    }
  }
`;

export const EDIT_USER_PROFILE = gql`
  mutation editPerfilUsuario(
    $id_usuario: String!
    $correo: String!
    $telefono_movil: String!
    $anexo: String!
    $telefono_fijo: String!
  ) {
    editPerfilUsuario(
      id_usuario: $id_usuario
      correo: $correo
      telefono_movil: $telefono_movil
      anexo: $anexo
      telefono_fijo: $telefono_fijo
    ) {
      id_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      rut_usuario
      dv_usuario
      correo
      telefono_movil
      telefono_fijo
      anexo
      imagen_perfil
      genero
      token
      fecha_nacimiento
      fecha_ingreso
      empresa {
        id_empresa
        nombre_empresa
      }
      cargo {
        id_cargo
        nombre_cargo
      }
      sucursal {
        id_sucursal
        nombre_sucursal
      }
      gerencia {
        id_gerencia
        nombre_gerencia
      }
      turno {
        id_turno
        nombre_turno
      }
      privilegio {
        id_tipo
        nombre_tipo
      }
    }
  }
`;

export const EDIT_PASSWORD = gql`
  mutation editPasswordUsuarioUpdate($id_usuario: String!, $password: String!) {
    editPasswordUsuarioUpdate(id_usuario: $id_usuario, password: $password) {
      id_usuario
      password
    }
  }
`;

export const EDIT_IMG_PROFILE = gql`
  mutation editImagenPerfil(
    $id_usuario: String!
    $imagen_perfil: String!
    $formato: String!
    $id_empresa_fk: String!
  ) {
    editImagenPerfil(
      id_usuario: $id_usuario
      imagen_perfil: $imagen_perfil
      formato: $formato
      id_empresa_fk: $id_empresa_fk
    ) {
      id_usuario
      imagen_perfil
    }
  }
`;
// Login
/* STAND-BY */
export const USUARIO_LOGIN_TOKEN = gql`
  mutation usuarioLoginToken(
    $login: String!
    $tipo_login: String!
    $password: String!
    $plataforma: String!
    $tipo_web: String!
  ) {
    usuarioLoginToken(
      login: $login
      tipo_login: $tipo_login
      password: $password
      plataforma: $plataforma
      tipo_web: $tipo_web
    ) {
      user_token
      access_token
      refresh_token
    }
  }
`;

export const LOGIN_WITH_ADM = gql`
  mutation usuarioLoginTokenAdmin(
    $login: String!
    $tipo_login: String!
    $password: String!
    $plataforma: String!
  ) {
    usuarioLoginTokenAdmin(
      login: $login
      tipo_login: $tipo_login
      password: $password
      plataforma: $plataforma
    ) {
      token
      uuid
    }
  }
`;

export const CHECK_TOKEN = gql`
  mutation checkToken($token: String!, $uuid: String!) {
    checkToken(token: $token, uuid: $uuid) {
      id_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      rut_usuario
      dv_usuario
      correo
      telefono_movil
      telefono_fijo
      anexo
      imagen_perfil
      genero
      token
      fecha_nacimiento
      fecha_ingreso
      id_empresa_fk
      empresa {
        id_empresa
        nombre_empresa
        color_primario
        color_secundario
      }
      cargo {
        id_cargo
        nombre_cargo
      }
      sucursal {
        id_sucursal
        nombre_sucursal
      }
      gerencia {
        id_gerencia
        nombre_gerencia
      }
      turno {
        id_turno
        nombre_turno
      }
      privilegio {
        id_tipo
        nombre_tipo
      }
      grupo {
        id_grupo
        nombre_grupo
        descripcion_grupo
      }
      id_estado_fk
    }
  }
`;

export const CHECK_TOKEN_RECOVERY = gql`
  mutation checkTokenRecovery($token: String!, $uuid: String!) {
    checkTokenRecuperar(token: $token, uuid: $uuid) {
      id_usuario
      token
    }
  }
`;
export const EDIT_PASSWORD_RECUPERACION = gql`
  mutation editPasswordRecuperacion(
    $id_usuario: String!
    $password: String!
    $token: String!
    $uuid: String!
  ) {
    editPasswordRecuperacion(
      id_usuario: $id_usuario
      password: $password
      token: $token
      uuid: $uuid
    ) {
      id_usuario
      password
    }
  }
`;

export const RECOVER_PASS = gql`
  mutation recuperarPassword($correo: String!) {
    recuperarPassword(correo: $correo) {
      token
      uuid
      action
    }
  }
`;

export const VERIFY_PASSWORD = gql`
  mutation checkCurrentPasswordBoolean(
    $id_usuario: String!
    $password: String!
  ) {
    checkCurrentPasswordBoolean(id_usuario: $id_usuario, password: $password) {
      id_usuario
      password
    }
  }
`;
// Login
// Seguridad
export const ADD_SAFETY_DOC = gql`
  mutation agregarDocumento(
    $nombre: String!
    $archivo: String!
    $formato_archivo: String!
    $id_empresa: String!
    $id_categoria: String!
    $id_modulo: String!
    $id_creador: String!
  ) {
    agregarDocumento(
      nombre: $nombre
      archivo: $archivo
      formato_archivo: $formato_archivo
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      id_modulo: $id_modulo
      id_creador: $id_creador
    ) {
      id_documento
      nombre
      url_documento
      id_estado_fk
      id_empresa_fk
      fecha_creacion
      categoria {
        nombre
        id_categoria
      }
    }
  }
`;

export const DELETE_SAFETY_DOC = gql`
  mutation eliminarDocumento(
    $id_documento: String!
    $id_empresa: String!
    $id_eliminador: String!
  ) {
    eliminarDocumento(
      id_documento: $id_documento
      id_empresa: $id_empresa
      id_eliminador: $id_eliminador
    ) {
      id_documento
    }
  }
`;

export const EDIT_SAFETY_DOC = gql`
  mutation editarDocumento(
    $nombre: String!
    $archivo: String!
    $formato_archivo: String!
    $id_empresa: String!
    $id_categoria: String!
    $id_modulo: String!
    $id_modificador: String!
    $id_documento: String!
  ) {
    editarDocumento(
      nombre: $nombre
      archivo: $archivo
      formato_archivo: $formato_archivo
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      id_modulo: $id_modulo
      id_modificador: $id_modificador
      id_documento: $id_documento
    ) {
      id_documento
      nombre
      url_documento
      id_estado_fk
      id_empresa_fk
      fecha_creacion
      categoria {
        nombre
        id_categoria
      }
    }
  }
`;

export const CHANGE_SAFETY_DOC_STATUS = gql`
  mutation cambiarEstadoDocumento(
    $id_documento: String!
    $id_empresa: String!
    $estado: String!
    $id_modificador: String!
  ) {
    cambiarEstadoDocumento(
      id_documento: $id_documento
      id_empresa: $id_empresa
      estado: $estado
      id_modificador: $id_modificador
    ) {
      id_documento
      nombre
      url_documento
      id_estado_fk
      id_empresa_fk
      fecha_creacion
      categoria {
        nombre
        id_categoria
      }
    }
  }
`;

export const ADD_SAFETY_CATEGORY_DOC = gql`
  mutation agregarCategoriaDocumento(
    $nombre: String!
    $id_empresa: String!
    $archivo: String!
    $formato_archivo: String!
    $id_modulo: String!
    $id_creador: String!
  ) {
    agregarCategoriaDocumento(
      nombre: $nombre
      id_empresa: $id_empresa
      archivo: $archivo
      formato_archivo: $formato_archivo
      id_modulo: $id_modulo
      id_creador: $id_creador
    ) {
      id_categoria
      nombre
    }
  }
`;

export const DELETE_SAFETY_CATEGORY_DOC = gql`
  mutation eliminarCategoriaDocumento(
    $id_categoria: String!
    $id_empresa: String!
    $id_eliminador: String!
    $id_modulo: String!
  ) {
    eliminarCategoriaDocumento(
      id_categoria: $id_categoria
      id_empresa: $id_empresa
      id_eliminador: $id_eliminador
      id_modulo: $id_modulo
    ) {
      id_categoria
      nombre
    }
  }
`;

export const EDIT_SAFETY_CATEGORY_DOC = gql`
  mutation editarCategoriaDocumento(
    $id_categoria: String!
    $nombre: String!
    $id_empresa: String!
    $archivo: String!
    $formato_archivo: String!
    $id_modulo: String!
    $id_modificador: String!
  ) {
    editarCategoriaDocumento(
      id_categoria: $id_categoria
      nombre: $nombre
      id_empresa: $id_empresa
      archivo: $archivo
      formato_archivo: $formato_archivo
      id_modulo: $id_modulo
      id_modificador: $id_modificador
    ) {
      id_categoria
      nombre
    }
  }
`;

export const CHANGE_STATE_BENEF = gql`
  mutation ChangeEstadoSolicitud(
    $id_empresa: String!
    $id_estado: String!
    $id_solicitud: String!
    $id_usuario: String!
    $id_administrador: String!
  ) {
    ChangeEstadoSolicitud(
      id_empresa: $id_empresa
      id_estado: $id_estado
      id_solicitud: $id_solicitud
      id_usuario: $id_usuario
      id_administrador: $id_administrador
    ) {
      id_solicitud
      codigo_solicitud
      nombre
      descripcion
      fecha_creacion
      estado_solicitud {
        id_estado_solicitud
        nombre
        descripcion
        color
      }
      detalle_solicitud {
        id_solicitud_detalle
        nombre
        descripcion
        cantidad_solicitada
        cantidad_modificada
        cantidad_entregada
        inventario {
          id_inventario
          codigo_inventario
          nombre
          descripcion
          cantidad_inicial
          cantidad_actual
          con_limite
          limite_cantidad
          con_detalle
        }
        inventario_detalle {
          id_detalle
          nombre_detalle
          descripcion
          tipo_detalle
          editable
          id_formulario_fk
          codigo_detalle
        }
        valores_formulario {
          id_valor
          valor
          formulario_selector {
            id_formulario
            nombre
            tipo_dato
            con_valor_predeterminado
            is_selector
            nombre_header
          }
        }
      }
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        imagen_perfil
        fecha_ingreso
        cargo {
          id_cargo
          nombre_cargo
        }
      }
      tipo_solicitud {
        id_tipo
        nombre_solicitud
        descripcion
        url_imagen
        datos_formulario {
          id_formulario
          nombre
          tipo_dato
          con_valor_predeterminado
          informacion_usuario
          is_selector
          is_admin

          datos_selector_empresa {
            id_dato_selector
            nombre
            descripcion
            id_formulario_dato_fk
          }
          datos_selector_inventario {
            id_detalle
            nombre_detalle
            descripcion
            tipo_detalle
            editable
            id_formulario_fk
            codigo_detalle
          }
          datos_selector_producto {
            id_inventario
            codigo_inventario
            nombre
            descripcion
            cantidad_inicial
            cantidad_actual
            con_limite
            limite_cantidad
            con_detalle
            detalles_inventario {
              id_detalle
              nombre_detalle
              descripcion
              tipo_detalle
              editable
              id_formulario_fk
              codigo_detalle
            }
          }
        }
      }
    }
  }
`;

//covid
export const CREATE_TYPE_VACUNA = gql`
  mutation CreateTipoVacuna(
    $nombre: String!
    $descripcion: String!
    $id_empresa: String!
    $id_usuario: String!
  ) {
    CreateTipoVacuna(
      nombre: $nombre
      descripcion: $descripcion
      id_empresa: $id_empresa
      id_usuario: $id_usuario
    ) {
      id_tipo
      nombre
      descripcion
    }
  }
`;
export const EDIT_TYPE_VACUNA = gql`
  mutation EditTipoVacuna(
    $id_tipo: String!
    $nombre: String!
    $descripcion: String!
    $id_empresa: String!
    $id_usuario: String!
  ) {
    EditTipoVacuna(
      id_tipo: $id_tipo
      nombre: $nombre
      descripcion: $descripcion
      id_empresa: $id_empresa
      id_usuario: $id_usuario
    ) {
      id_tipo
      nombre
      descripcion
    }
  }
`;
export const DELETE_TYPE_VACUNA = gql`
  mutation DeleteTipoVacuna(
    $id_tipo: String!
    $id_empresa: String!
    $id_usuario: String!
  ) {
    DeleteTipoVacuna(
      id_tipo: $id_tipo
      id_empresa: $id_empresa
      id_usuario: $id_usuario
    ) {
      id_tipo
    }
  }
`;
export const CREATE_DOSIS_VACUNA = gql`
  mutation CreateDosisVacuna(
    $id_tipo: String!
    $fecha_dosis: String!
    $fecha_siguiente_dosis: String!
    $id_empresa: String!
    $id_usuario: String!
    $imagen: String!
    $formato_imagen: String!
  ) {
    CreateDosisVacuna(
      id_tipo: $id_tipo
      fecha_dosis: $fecha_dosis
      fecha_siguiente_dosis: $fecha_siguiente_dosis
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      imagen: $imagen
      formato_imagen: $formato_imagen
    ) {
      id_vacuna
      numero_dosis
      fecha_dosis
      fecha_siguiente_dosis
      url_imagen
      codigo
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
      }
      tipo_vacuna {
        id_tipo
        nombre
        descripcion
      }
    }
  }
`;
export const EDIT_DOSIS_VACUNA = gql`
  mutation EditDosisVacuna(
    $id_vacuna: String!
    $id_tipo: String!
    $fecha_dosis: String!
    $fecha_siguiente_dosis: String!
    $id_empresa: String!
    $id_usuario: String!
    $imagen: String!
    $formato_imagen: String!
  ) {
    EditDosisVacuna(
      id_vacuna: $id_vacuna
      id_tipo: $id_tipo
      fecha_dosis: $fecha_dosis
      fecha_siguiente_dosis: $fecha_siguiente_dosis
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      imagen: $imagen
      formato_imagen: $formato_imagen
    ) {
      id_vacuna
      numero_dosis
      fecha_dosis
      fecha_siguiente_dosis
      url_imagen
      codigo
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
      }
      tipo_vacuna {
        id_tipo
        nombre
        descripcion
      }
    }
  }
`;
export const DELETE_DOSIS_VACUNA = gql`
  mutation DeleteVacuna(
    $id_vacuna: String!
    $id_empresa: String!
    $id_usuario: String!
  ) {
    DeleteVacuna(
      id_vacuna: $id_vacuna
      id_empresa: $id_empresa
      id_usuario: $id_usuario
    ) {
      id_vacuna
    }
  }
`;
export const CREATE_ENCUESTA = gql`
  mutation CrearEncuesta(
    $idEmpresa: String!
    $descripcion: String!
    $fechaInicio: String!
    $fechaFin: String!
    $tipoEncuesta: String!
    $urlEncuesta: String!
    $idUsuario: String!
    $anonima: String!
    $tipoSegmentacion: String!
    $alertaPush: String!
    $nombreEncuesta: String!
    $preguntas: [crearPreguntas]
    $usuarios: [String]
    $enlaces: [String]
    $correoContactos: [String]
    $segmentaciones: [String]
  ) {
    crearEncuesta(
      id_empresa: $idEmpresa
      descripcion: $descripcion
      fecha_inicio: $fechaInicio
      fecha_fin: $fechaFin
      tipo_encuesta: $tipoEncuesta
      url_encuesta: $urlEncuesta
      id_usuario: $idUsuario
      anonima: $anonima
      tipo_segmentacion: $tipoSegmentacion
      alerta_push: $alertaPush
      nombre_encuesta: $nombreEncuesta
      preguntas: $preguntas
      usuarios: $usuarios
      enlaces: $enlaces
      correo_contactos: $correoContactos
      segmentaciones: $segmentaciones
    ) {
      id_encuesta
      nombre_encuesta
      url_encuesta
      fecha_inicio_encuesta
      fecha_termino_encuesta
      fecha_creacion
      id_empresa_fk
      is_anonima
      id_categoria
      nombre_categoria
      tipo_encuesta {
        id_tipo_encuesta
        nombre_tipo_encuesta
      }
      preguntas {
        id_pregunta
        texto_pregunta
        editable
        is_star
        is_points
        id_estado_fk
        tipo_pregunta {
          id_pregunta_tipo
          nombre_pregunta_tipo
          is_icon
        }
        alternativas {
          id_alternativa
          texto_alternativa
          correcta_alternativa
          genera_qr
          id_pregunta_fk
        }
      }
      enlace_individual {
        id_enlace
        id_usuario_fk
        id_encuesta_fk
        url_enlace
      }
      confirm_seen {
        id_visto
        visto
        id_encuesta_fk
        id_usuario_fk
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
      estadisticas {
        total_usuarios
        total_respuestas
        porcentaje
      }
      descripcion
    }
  }
`;
export const EDIT_ENCUESTA_ID = gql`
  mutation EditarEncuesta(
    $idEncuesta: String!
    $idEmpresa: String!
    $nombreEncuesta: String!
    $descripcion: String!
    $fechaInicio: String!
    $fechaFin: String!
    $tipoEncuesta: String!
    $urlEncuesta: String!
    $idUsuario: String!
    $anonima: String!
    $tipoSegmentacion: String!
    $alertaPush: String!
    $preguntas: [crearPreguntas]
    $usuarios: [String]
    $enlaces: [String]
    $correoContactos: [String]
    $segmentaciones: [String]
  ) {
    editarEncuesta(
      id_encuesta: $idEncuesta
      id_empresa: $idEmpresa
      nombre_encuesta: $nombreEncuesta
      descripcion: $descripcion
      fecha_inicio: $fechaInicio
      fecha_fin: $fechaFin
      tipo_encuesta: $tipoEncuesta
      url_encuesta: $urlEncuesta
      id_usuario: $idUsuario
      anonima: $anonima
      tipo_segmentacion: $tipoSegmentacion
      alerta_push: $alertaPush
      preguntas: $preguntas
      usuarios: $usuarios
      enlaces: $enlaces
      correo_contactos: $correoContactos
      segmentaciones: $segmentaciones
    ) {
      id_encuesta
      nombre_encuesta
      url_encuesta
      fecha_inicio_encuesta
      fecha_termino_encuesta
      fecha_creacion
      id_empresa_fk
      is_anonima
      id_categoria
      nombre_categoria
      tipo_encuesta {
        id_tipo_encuesta
        nombre_tipo_encuesta
      }
      preguntas {
        id_pregunta
        texto_pregunta
        editable
        is_star
        is_points
        id_estado_fk
        tipo_pregunta {
          id_pregunta_tipo
          nombre_pregunta_tipo
          is_icon
        }
        alternativas {
          id_alternativa
          texto_alternativa
          correcta_alternativa
          genera_qr
          id_pregunta_fk
        }
      }
      enlace_individual {
        id_enlace
        id_usuario_fk
        id_encuesta_fk
        url_enlace
      }
      confirm_seen {
        id_visto
        visto
        id_encuesta_fk
        id_usuario_fk
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
      estadisticas {
        total_usuarios
        total_respuestas
        porcentaje
      }
      descripcion
    }
  }
`;

export const REPORTAR_SINTOMAS = gql`
  mutation ReportarSintomas(
    $respuestas: [RespuestasArr]
    $id_empresa: String!
    $id_usuario: String!
    $temperatura: String!
    $patente: String!
    $id_encuesta: String!
  ) {
    ReportarSintomas(
      respuestas: $respuestas
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      temperatura: $temperatura
      patente: $patente
      id_encuesta: $id_encuesta
    ) {
      id_respuesta
      temperatura
      patente
      fecha_creacion
      id_empresa_fk
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        correo
        telefono_movil
        telefono_fijo
      }
      respuestas {
        id_respuesta_detalle
        respuesta
        tiempo_respuesta
        fecha_creacion
        pregunta {
          id_pregunta
          texto_pregunta
          editable
          id_estado_fk
          tipo_pregunta {
            id_pregunta_tipo
            nombre_pregunta_tipo
          }
        }
      }
    }
  }
`;
//CULTURA
export const ADD_CULTURA = gql`
  mutation agregarCultura($contenido: crearCulturaO!) {
    agregarCultura(contenido: $contenido) {
      id_cultura
      id_estado_fk
      fecha_creacion
      id_empresa_fk
      descripcion {
        id_descripcion
        titulo
        texto
        url_documento
        color_elemento
        id_estado_fk
        tipo_descripcion {
          id_tipo
          nombre
          descripcion
          imagen_url
        }
      }
      valores {
        id_valor
        nombre
        descripcion
        color
        imagen_url
        orden
        id_estado_fk
        id_empresa_fk
      }
    }
  }
`;
export const EDIT_CULTURA = gql`
  mutation editarCultura($contenido: crearCulturaO!, $id_cultura: String!) {
    editarCultura(contenido: $contenido, id_cultura: $id_cultura) {
      id_cultura
      id_estado_fk
      fecha_creacion
      id_empresa_fk
      descripcion {
        id_descripcion
        titulo
        texto
        url_documento
        color_elemento
        id_estado_fk
        tipo_descripcion {
          id_tipo
          nombre
          descripcion
          imagen_url
        }
      }
      valores {
        id_valor
        nombre
        descripcion
        color
        imagen_url
        orden
        id_estado_fk
        id_empresa_fk
      }
    }
  }
`;
export const ADD_CULTURA_VALOR = gql`
  mutation crearValorCultura(
    $nombre: String!
    $descripcion: String!
    $color: String!
    $imagen: String!
    $formato_imagen: String!
    $id_cultura: String!
    $id_empresa: String!
    $id_usuario: String!
  ) {
    crearValorCultura(
      nombre: $nombre
      descripcion: $descripcion
      color: $color
      imagen: $imagen
      formato_imagen: $formato_imagen
      id_cultura: $id_cultura
      id_empresa: $id_empresa
      id_usuario: $id_usuario
    ) {
      id_valor
      nombre
      descripcion
      color
      imagen_url
      orden
      id_estado_fk
      id_empresa_fk
    }
  }
`;
export const EDIT_CULTURA_VALOR = gql`
  mutation editarValorCultura(
    $nombre: String!
    $descripcion: String!
    $color: String!
    $imagen: String!
    $formato_imagen: String!
    $id_cultura: String!
    $id_empresa: String!
    $id_usuario: String!
    $id_valor: String!
  ) {
    editarValorCultura(
      nombre: $nombre
      descripcion: $descripcion
      color: $color
      imagen: $imagen
      formato_imagen: $formato_imagen
      id_cultura: $id_cultura
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      id_valor: $id_valor
    ) {
      id_valor
      nombre
      descripcion
      color
      imagen_url
      orden
      id_estado_fk
      id_empresa_fk
    }
  }
`;
export const DELETE_CULTURA_VALOR = gql`
  mutation eliminarValorCultura(
    $id_empresa: String!
    $id_valor: String!
    $id_cultura: String!
    $id_usuario: String!
  ) {
    eliminarValorCultura(
      id_empresa: $id_empresa
      id_valor: $id_valor
      id_cultura: $id_cultura
      id_usuario: $id_usuario
    ) {
      id_valor
      nombre
      descripcion
      color
      imagen_url
      orden
      id_estado_fk
      id_empresa_fk
    }
  }
`;
export const ORDER_CULTURA_VALOR = gql`
  mutation ordenarValoresCultura(
    $id_empresa: String!
    $id_cultura: [String]
    $valores: [String]
    $id_usuario: [String]
  ) {
    ordenarValoresCultura(
      id_empresa: $id_empresa
      id_cultura: $id_cultura
      valores: $valores
      id_usuario: $id_usuario
    ) {
      id_valor
      nombre
      descripcion
      imagen_url
      orden
      id_estado_fk
      id_empresa_fk
    }
  }
`;

//Carga Masiva
export const ADD_BULK_LOAD = gql`
  mutation cargaMasivaUpdated(
    $rut_usuario: [String]
    $dv_usuario: [String]
    $primer_nombre: [String]
    $segundo_nombre: [String]
    $apellido_paterno: [String]
    $apellido_materno: [String]
    $correo: [String]
    $telefono_movil: [String]
    $anexo: [String]
    $telefono_fijo: [String]
    $fecha_nacimiento: [String]
    $fecha_ingreso: [String]
    $genero: [String]
    $entity_id: [String]
    $cargo: [String]
    $gerencia: [String]
    $sucursal: [String]
    $turno: [String]
    $nacion: [String]
    $sociedad: [String]
    $sindicato: [String]
    $privilegio: [String]
    $grupo: [String]
    $id_creador: String!
    $id_empresa_fk: String!
  ) {
    cargaMasivaUpdated(
      rut_usuario: $rut_usuario
      dv_usuario: $dv_usuario
      primer_nombre: $primer_nombre
      segundo_nombre: $segundo_nombre
      apellido_paterno: $apellido_paterno
      apellido_materno: $apellido_materno
      correo: $correo
      telefono_movil: $telefono_movil
      anexo: $anexo
      telefono_fijo: $telefono_fijo
      fecha_nacimiento: $fecha_nacimiento
      fecha_ingreso: $fecha_ingreso
      genero: $genero
      entity_id: $entity_id
      cargo: $cargo
      gerencia: $gerencia
      sucursal: $sucursal
      turno: $turno
      nacion: $nacion
      sociedad: $sociedad
      sindicato: $sindicato
      privilegio: $privilegio
      grupo: $grupo
      id_creador: $id_creador
      id_empresa_fk: $id_empresa_fk
    ) {
      id_usuario
      primer_nombre
      apellido_paterno
      rut_usuario
      dv_usuario
      id_sucursal
      id_privilegio
      result
      action
    }
  }
`;
export const EDIT_BULK_LOAD = gql`
  mutation updateNominaMasiva(
    $rut_usuario: [String]
    $dv_usuario: [String]
    $primer_nombre: [String]
    $segundo_nombre: [String]
    $apellido_paterno: [String]
    $apellido_materno: [String]
    $correo: [String]
    $telefono_movil: [String]
    $anexo: [String]
    $telefono_fijo: [String]
    $fecha_nacimiento: [String]
    $fecha_ingreso: [String]
    $genero: [String]
    $entity_id: [String]
    $cargo: [String]
    $gerencia: [String]
    $sucursal: [String]
    $turno: [String]
    $nacion: [String]
    $sociedad: [String]
    $sindicato: [String]
    $privilegio: [String]
    $grupo: [String]
    $id_creador: String!
    $id_empresa_fk: String!
  ) {
    updateNominaMasiva(
      rut_usuario: $rut_usuario
      dv_usuario: $dv_usuario
      primer_nombre: $primer_nombre
      segundo_nombre: $segundo_nombre
      apellido_paterno: $apellido_paterno
      apellido_materno: $apellido_materno
      correo: $correo
      telefono_movil: $telefono_movil
      anexo: $anexo
      telefono_fijo: $telefono_fijo
      fecha_nacimiento: $fecha_nacimiento
      fecha_ingreso: $fecha_ingreso
      genero: $genero
      entity_id: $entity_id
      cargo: $cargo
      gerencia: $gerencia
      sucursal: $sucursal
      turno: $turno
      nacion: $nacion
      sociedad: $sociedad
      sindicato: $sindicato
      privilegio: $privilegio
      grupo: $grupo
      id_creador: $id_creador
      id_empresa_fk: $id_empresa_fk
    ) {
      id_usuario
      primer_nombre
      apellido_paterno
      rut_usuario
      dv_usuario
      id_sucursal
      id_privilegio
      result
      action
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation resetPassword($id_usuario: String!, $id_empresa: String!) {
    resetPassword(id_usuario: $id_usuario, id_empresa: $id_empresa) {
      id_usuario
      primer_nombre
      apellido_paterno
      apellido_materno
      correo
      cargo {
        id_cargo
        nombre_cargo
      }
      sucursal {
        id_sucursal
        nombre_sucursal
      }
    }
  }
`;

export const DELETE_ENCUESTA = gql`
  mutation EliminarEncuestaMutation(
    $eliminarEncuestaIdEmpresa: String!
    $eliminarEncuestaIdEncuesta: String!
    $eliminarEncuestaIdUsuario: String!
  ) {
    eliminarEncuesta(
      id_empresa: $eliminarEncuestaIdEmpresa
      id_encuesta: $eliminarEncuestaIdEncuesta
      id_usuario: $eliminarEncuestaIdUsuario
    ) {
      id_encuesta
      nombre_encuesta
      url_encuesta
      fecha_inicio_encuesta
      fecha_termino_encuesta
      fecha_creacion
      id_empresa_fk
      is_anonima
      tipo_encuesta {
        id_tipo_encuesta
        nombre_tipo_encuesta
      }
      preguntas {
        id_pregunta
        texto_pregunta
        editable
        is_star
        is_points
        id_estado_fk
        tipo_pregunta {
          id_pregunta_tipo
          nombre_pregunta_tipo
          is_icon
        }
        alternativas {
          id_alternativa
          texto_alternativa
          correcta_alternativa
          genera_qr
          id_pregunta_fk
        }
      }
      enlace_individual {
        id_enlace
        id_usuario_fk
        id_encuesta_fk
        url_enlace
      }
      confirm_seen {
        id_visto
        visto
        id_encuesta_fk
        id_usuario_fk
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          nombre_segmentacion
          id_elementos_segmentacion
        }
        segmentaciones
      }
    }
  }
`;
export const SEND_ALERT = gql`
  mutation enviarPush(
    $id_empresa: String!
    $titulo: String!
    $texto: String!
    $tipo_segmentacion: String!
    $segmentaciones: [String]
    $id_modulo: String!
    $id_objeto: String!
    $fecha_notificacion: String!
    $id_usuario: String!
  ) {
    enviarPush(
      id_empresa: $id_empresa
      titulo: $titulo
      texto: $texto
      tipo_segmentacion: $tipo_segmentacion
      segmentaciones: $segmentaciones
      id_modulo: $id_modulo
      id_objeto: $id_objeto
      fecha_notificacion: $fecha_notificacion
      id_usuario: $id_usuario
    ) {
      id_notificacion
      titulo_notificacion
      texto_notificacion
      fecha_creacion
      fecha_notificacion
      push_enviado
      id_empresa_fk
      id_modulo_fk
      id_estado_fk
      token_totales
      token_check
      intentos
      tipo_notificacion {
        id_tipo
        nombre_tipo
      }
      estado_notificacion
    }
  }
`;

export const DELETE_NOTIFICACION = gql`
  mutation eliminarNotificacionPush(
    $idNotificacion: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    eliminarNotificacionPush(
      id_notificacion: $idNotificacion
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_notificacion
      titulo_notificacion
      texto_notificacion
      fecha_creacion
      fecha_notificacion
      push_enviado
      id_empresa_fk
      id_modulo_fk
      token_totales
      token_check
      intentos
    }
  }
`;

export const EDIT_NOTIFICACION = gql`
  mutation EditarNotificacionPush(
    $idNotificacion: String!
    $idEmpresa: String!
    $titulo: String!
    $texto: String!
    $tipoSegmentacion: String!
    $idModulo: String!
    $idObjeto: String!
    $fechaNotificacion: String!
    $idUsuario: String!
    $segmentaciones: [String]
  ) {
    editarNotificacionPush(
      id_notificacion: $idNotificacion
      id_empresa: $idEmpresa
      titulo: $titulo
      texto: $texto
      tipo_segmentacion: $tipoSegmentacion
      id_modulo: $idModulo
      id_objeto: $idObjeto
      fecha_notificacion: $fechaNotificacion
      id_usuario: $idUsuario
      segmentaciones: $segmentaciones
    ) {
      id_notificacion
      titulo_notificacion
      texto_notificacion
      fecha_creacion
      fecha_notificacion
      push_enviado
      id_empresa_fk
      tipo_notificacion {
        id_tipo
        nombre_tipo
      }
      estado_notificacion
      id_modulo_fk
      id_estado_fk
      token_totales
      token_check
      intentos
    }
  }
`;
export const CHANGE_NOTIFICACION_STATUS = gql`
  mutation ChangeEstadoNotificacion(
    $idNotificacion: String!
    $idEstado: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    changeEstadoNotificacion(
      id_notificacion: $idNotificacion
      id_estado: $idEstado
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_notificacion
      titulo_notificacion
      texto_notificacion
      fecha_creacion
      fecha_notificacion
      push_enviado
      id_empresa_fk
      tipo_notificacion {
        id_tipo
        nombre_tipo
      }
      id_modulo_fk
      id_estado_fk
      token_totales
      token_check
      intentos
      confirm_seen {
        id_notificaciones_visto
        fecha_creacion
        id_usuario_fk
      }
    }
  }
`;

export const RESEND_NOTIFICACION = gql`
  mutation ReenviarNotificacionPush(
    $idNotificacion: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    reenviarNotificacionPush(
      id_notificacion: $idNotificacion
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_notificacion
      titulo_notificacion
      texto_notificacion
      fecha_creacion
      fecha_notificacion
      push_enviado
      id_empresa_fk
      tipo_notificacion {
        id_tipo
        nombre_tipo
      }
      id_modulo_fk
      id_estado_fk
      token_totales
      token_check
      intentos
      confirm_seen {
        id_notificaciones_visto
        fecha_creacion
        id_usuario_fk
      }
    }
  }
`;

export const WALL_ADD = gql`
  mutation CrearMuroAdmin(
    $usuario_perfil: String!
    $id_objeto: String!
    $imagen_muro: String!
    $imagen_formato: String!
    $video_muro: String!
    $video_formato: String!
    $id_empresa: String!
    $texto: String!
    $id_usuario: String!
  ) {
    crearMuroAdmin(
      usuario_perfil: $usuario_perfil
      id_objeto: $id_objeto
      imagen_muro: $imagen_muro
      imagen_formato: $imagen_formato
      video_muro: $video_muro
      video_formato: $video_formato
      id_empresa: $id_empresa
      texto: $texto
      id_usuario: $id_usuario
    ) {
      id_muro
      texto
      imagen_muro
      video_muro
      tipo_post
      fijado_orden
      fecha_creacion
      id_estado_fk
      usuario {
        primer_nombre
        apellido_paterno
        imagen_perfil
        id_usuario
      }
      perfil {
        id_perfil
        nombre_perfil
        color
        imagen_perfil
        id_estado_fk
      }
      comentarios_compartidos {
        id_comentario
        texto
        fecha_creacion
        usuario {
          id_usuario
          primer_nombre
          apellido_paterno
        }
        mencionados {
          usuario_comentario {
            id_usuario
            primer_nombre
            apellido_paterno
          }
          id_mencion
          usuario_mencionado {
            id_usuario
            primer_nombre
            apellido_paterno
          }
        }
        likes {
          id_like
        }
        respuestas_comentarios {
          id_comentario
          texto
          fecha_creacion
          usuario {
            id_usuario
            primer_nombre
            apellido_paterno
          }
          mencionados {
            usuario_comentario {
              id_usuario
              primer_nombre
              apellido_paterno
            }
            id_mencion
            usuario_mencionado {
              id_usuario
              primer_nombre
              apellido_paterno
            }
          }
          likes {
            id_like
          }
        }
      }
      likes {
        id_like
      }
      compartido {
        id_compartido
        id_modulo_solicitador
        id_modulo_compartido
        id_objeto
        id_objeto_compartido
        noticia {
          id_noticia
          titulo_noticia
        }
        galeria {
          id_galeria
          titulo_galeria
        }
      }
    }
  }
`;
export const WALL_EDIT = gql`
  mutation EditarMuroAdmin(
    $usuario_perfil: String!
    $id_objeto: String!
    $id_usuario: String!
    $imagen_muro: String!
    $imagen_formato: String!
    $video_muro: String!
    $video_formato: String!
    $id_empresa: String!
    $texto: String!
    $id_muro: String!
  ) {
    editarMuroAdmin(
      usuario_perfil: $usuario_perfil
      id_objeto: $id_objeto
      id_usuario: $id_usuario
      imagen_muro: $imagen_muro
      imagen_formato: $imagen_formato
      video_muro: $video_muro
      video_formato: $video_formato
      id_empresa: $id_empresa
      texto: $texto
      id_muro: $id_muro
    ) {
      id_muro
      texto
      imagen_muro
      video_muro
      tipo_post
      fijado_orden
      fecha_creacion
      id_estado_fk
      usuario {
        primer_nombre
        apellido_paterno
        imagen_perfil
        id_usuario
      }
      perfil {
        id_perfil
        nombre_perfil
        color
        imagen_perfil
        id_estado_fk
      }
      comentarios_compartidos {
        id_comentario
        texto
        fecha_creacion
        usuario {
          id_usuario
          primer_nombre
          apellido_paterno
        }
        mencionados {
          usuario_comentario {
            id_usuario
            primer_nombre
            apellido_paterno
          }
          id_mencion
          usuario_mencionado {
            id_usuario
            primer_nombre
            apellido_paterno
          }
        }
        likes {
          id_like
        }
        respuestas_comentarios {
          id_comentario
          texto
          fecha_creacion
          usuario {
            id_usuario
            primer_nombre
            apellido_paterno
          }
          mencionados {
            usuario_comentario {
              id_usuario
              primer_nombre
              apellido_paterno
            }
            id_mencion
            usuario_mencionado {
              id_usuario
              primer_nombre
              apellido_paterno
            }
          }
          likes {
            id_like
          }
        }
      }
      likes {
        id_like
      }
      compartido {
        id_compartido
        id_modulo_solicitador
        id_modulo_compartido
        id_objeto
        id_objeto_compartido
        noticia {
          id_noticia
          titulo_noticia
        }
        galeria {
          id_galeria
          titulo_galeria
        }
      }
    }
  }
`;
export const WALL_DELETE = gql`
  mutation eliminarMuro(
    $id_usuario: String!
    $id_empresa: String!
    $id_muro: String!
  ) {
    eliminarMuro(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_muro: $id_muro
    ) {
      id_muro
    }
  }
`;
export const WALL_STATE = gql`
  mutation ChangeEstadoMuro(
    $id_objeto: String!
    $usuario_perfil: String!
    $id_usuario: String!
    $id_empresa: String!
    $id_muro: String!
    $estado: String!
  ) {
    ChangeEstadoMuro(
      id_objeto: $id_objeto
      usuario_perfil: $usuario_perfil
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_muro: $id_muro
      estado: $estado
    ) {
      id_muro
      id_estado_fk
    }
  }
`;

export const WALL_ADD_PROFILE = gql`
  mutation CreatePerfil(
    $nombre_perfil: String!
    $color: String!
    $imagen: String!
    $formato_imagen: String!
    $id_empresa: String!
  ) {
    CreatePerfil(
      nombre_perfil: $nombre_perfil
      color: $color
      imagen: $imagen
      formato_imagen: $formato_imagen
      id_empresa: $id_empresa
    ) {
      id_perfil
      nombre_perfil
      color
      imagen_perfil
      id_estado_fk
    }
  }
`;
export const WALL_EDIT_PROFILE = gql`
  mutation UpdatePerfilMuro(
    $id_perfil: String!
    $nombre_perfil: String!
    $color: String!
    $imagen: String!
    $formato_imagen: String!
    $id_empresa: String!
  ) {
    UpdatePerfilMuro(
      id_perfil: $id_perfil
      nombre_perfil: $nombre_perfil
      color: $color
      imagen: $imagen
      formato_imagen: $formato_imagen
      id_empresa: $id_empresa
    ) {
      id_perfil
      nombre_perfil
      color
      imagen_perfil
      id_estado_fk
    }
  }
`;
export const WALL_DELETE_PROFILE = gql`
  mutation DeletePerfilMuro(
    $id_perfil: String!
    $id_empresa: String!
    $id_usuario: [String]
  ) {
    DeletePerfilMuro(
      id_perfil: $id_perfil
      id_empresa: $id_empresa
      id_usuario: $id_usuario
    ) {
      id_perfil
    }
  }
`;

export const DELETE_TRAMO = gql`
  mutation DeleteDestino(
    $idDestino: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    deleteDestino(
      id_destino: $idDestino
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_destino
      nombre_origen
      nombre_destino
      transporte_tipo_medio {
        id_medio
        nombre_medio
        cantidad_asiento
        transporte_tipo {
          id_tipo
          nombre_transporte
        }
      }
      is_conexion
    }
  }
`;

export const EDIT_TRAMO = gql`
  mutation UpdateDestino(
    $idDestino: String!
    $nombreDestino: String!
    $nombreOrigen: String!
    $idMedio: String!
    $idDestinoPadre: String!
    $isConexion: Boolean!
    $antesDespues: String!
    $idaVuelta: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    updateDestino(
      id_destino: $idDestino
      nombre_destino: $nombreDestino
      nombre_origen: $nombreOrigen
      id_medio: $idMedio
      id_destino_padre: $idDestinoPadre
      is_conexion: $isConexion
      antes_despues: $antesDespues
      ida_vuelta: $idaVuelta
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_destino
      nombre_origen
      nombre_destino
      ida_vuelta
      transporte_tipo_medio {
        id_medio
        nombre_medio
        cantidad_asiento
        transporte_tipo {
          id_tipo
          nombre_transporte
        }
      }
      is_conexion
    }
  }
`;

export const CREATE_TIPO_MEDIO_EMPRESA = gql`
  mutation TipoMedioEmpresa(
    $idTipo: String!
    $nombreMedio: String!
    $cantidadAsientos: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    tipoMedioEmpresa(
      id_tipo: $idTipo
      nombre_medio: $nombreMedio
      cantidad_asientos: $cantidadAsientos
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_medio
      nombre_medio
      cantidad_asiento
      transporte_tipo {
        id_tipo
        nombre_transporte
      }
    }
  }
`;

export const EDIT_TIPO_MEDIO_EMPRESA = gql`
  mutation UpdateTipoMedioEmpresa(
    $idMedio: String!
    $idTipo: String!
    $nombreMedio: String!
    $cantidadAsientos: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    updateTipoMedioEmpresa(
      id_medio: $idMedio
      id_tipo: $idTipo
      nombre_medio: $nombreMedio
      cantidad_asientos: $cantidadAsientos
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_medio
      nombre_medio
      cantidad_asiento
      transporte_tipo {
        id_tipo
        nombre_transporte
      }
    }
  }
`;

export const DELETE_TIPO_MEDIO_EMPRESA = gql`
  mutation DeleteTipoMedioEmpresa(
    $idMedio: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    deleteTipoMedioEmpresa(
      id_medio: $idMedio
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_medio
      nombre_medio
      cantidad_asiento
      transporte_tipo {
        id_tipo
        nombre_transporte
      }
    }
  }
`;

export const CREATE_PLANIFICACION = gql`
  mutation CreatePlanificacion(
    $planificacion: crearPlanificacion!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    createPlanificacion(
      planificacion: $planificacion
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_planificacion
      nombre_planificacion
      transporte_planificacion_cadencia {
        id_cadencia
        nombre_cadencia
      }
      tranpsorte_planificacion_detalle {
        id_detalle
        concurrencia_ejecucion_semanal
        concurrencia_ejecucion_day
        concurrencia_ejecucion_month
        concurrencia_tiempo
        inicio_periodo_semanal
        inicio_periodo_day
        inicio_periodo_month
        hora_inicio_periodo
        final_periodo_semanal
        final_periodo_day
        final_periodo_month
        hora_final_periodo
        fecha_inicio_ejecucion
        fecha_final_ejecucion
      }
      transporte_tramo {
        id_tramo
        fecha_viaje
        hora_viaje
        fecha_inicio_inscripcion
        fecha_fin_inscripcion
        hora_inicio_inscripcion
        hora_fin_inscripcion
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
            transporte_tipo {
              id_tipo
              nombre_transporte
            }
          }
        }
        codigo_asientos
        codigo
      }
    }
  }
`;

export const UPDATE_PLANIFICACION_INSCRIPCION = gql`
  mutation updatePlanificacionInscripciones(
    $planificacion: updatePlanificacionInscripcionObject!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    updatePlanificacionInscripciones(
      planificacion: $planificacion
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
     id_planificacion
      nombre_planificacion
      transporte_planificacion_cadencia {
        id_cadencia
        nombre_cadencia
      }
      tranpsorte_planificacion_detalle {
        id_detalle
        concurrencia_ejecucion_semanal
        concurrencia_ejecucion_day
        concurrencia_ejecucion_month
        concurrencia_tiempo
        inicio_periodo_semanal
        inicio_periodo_day
        inicio_periodo_month
        hora_inicio_periodo
        final_periodo_semanal
        final_periodo_day
        final_periodo_month
        hora_final_periodo
        fecha_inicio_ejecucion
        fecha_final_ejecucion
      }
      transporte_tramo {
        id_tramo
        fecha_viaje
        hora_viaje
        fecha_inicio_inscripcion
        fecha_fin_inscripcion
        hora_inicio_inscripcion
        hora_fin_inscripcion
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
            transporte_tipo {
              id_tipo
              nombre_transporte
            }
          }
        }
        codigo
        codigo_asientos
      }
    }
  }
`;

export const UPDATE_PLANIFICACION_DATE = gql`
  mutation updatePlanificacionFecha(
    $planificacion: updatePlanificacionDateObject!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    updatePlanificacionFecha(
      planificacion: $planificacion
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
     id_planificacion
      nombre_planificacion
      transporte_planificacion_cadencia {
        id_cadencia
        nombre_cadencia
      }
      tranpsorte_planificacion_detalle {
        id_detalle
        concurrencia_ejecucion_semanal
        concurrencia_ejecucion_day
        concurrencia_ejecucion_month
        concurrencia_tiempo
        inicio_periodo_semanal
        inicio_periodo_day
        inicio_periodo_month
        hora_inicio_periodo
        final_periodo_semanal
        final_periodo_day
        final_periodo_month
        hora_final_periodo
        fecha_inicio_ejecucion
        fecha_final_ejecucion
      }
      transporte_tramo {
        id_tramo
        fecha_viaje
        hora_viaje
        fecha_inicio_inscripcion
        fecha_fin_inscripcion
        hora_inicio_inscripcion
        hora_fin_inscripcion
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
            transporte_tipo {
              id_tipo
              nombre_transporte
            }
          }
        }
        codigo
        codigo_asientos
      }
    }
  }
`;

export const UPDATE_PLANIFICACION_TRAMOS = gql`
  mutation updatePlanificacionTramos(
    $planificacion: updateAddPlanificacionTramos!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    updatePlanificacionTramos(
      planificacion: $planificacion
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
     id_planificacion
      nombre_planificacion
      transporte_planificacion_cadencia {
        id_cadencia
        nombre_cadencia
      }
      tranpsorte_planificacion_detalle {
        id_detalle
        concurrencia_ejecucion_semanal
        concurrencia_ejecucion_day
        concurrencia_ejecucion_month
        concurrencia_tiempo
        inicio_periodo_semanal
        inicio_periodo_day
        inicio_periodo_month
        hora_inicio_periodo
        final_periodo_semanal
        final_periodo_day
        final_periodo_month
        hora_final_periodo
        fecha_inicio_ejecucion
        fecha_final_ejecucion
      }
      transporte_tramo {
        id_tramo
        fecha_viaje
        hora_viaje
        fecha_inicio_inscripcion
        fecha_fin_inscripcion
        hora_inicio_inscripcion
        hora_fin_inscripcion
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
            transporte_tipo {
              id_tipo
              nombre_transporte
            }
          }
        }
        codigo
        codigo_asientos
      }
    }
  }
`;

export const UPDATE_PLANIFICACION = gql`
  mutation UpdatePlanificacion(
    $planificacion: updatePlanificacion!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    updatePlanificacion(
      planificacion: $planificacion
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_planificacion
      nombre_planificacion
      transporte_planificacion_cadencia {
        id_cadencia
        nombre_cadencia
      }
      tranpsorte_planificacion_detalle {
        id_detalle
        concurrencia_ejecucion_semanal
        concurrencia_ejecucion_day
        concurrencia_ejecucion_month
        concurrencia_tiempo
        inicio_periodo_semanal
        inicio_periodo_day
        inicio_periodo_month
        hora_inicio_periodo
        final_periodo_semanal
        final_periodo_day
        final_periodo_month
        hora_final_periodo
        fecha_inicio_ejecucion
        fecha_final_ejecucion
      }
      transporte_tramo {
        id_tramo
        fecha_viaje
        hora_viaje
        fecha_inicio_inscripcion
        fecha_fin_inscripcion
        hora_inicio_inscripcion
        hora_fin_inscripcion
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
            transporte_tipo {
              id_tipo
              nombre_transporte
            }
          }
        }
        codigo_asientos
        codigo
      }
    }
  }
`;

export const DELETE_PLANIFICACION = gql`
  mutation DeletePlanificacion(
    $idPlanificacion: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    deletePlanificacion(
      id_planificacion: $idPlanificacion
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_planificacion
      nombre_planificacion
      transporte_planificacion_cadencia {
        id_cadencia
        nombre_cadencia
      }
      tranpsorte_planificacion_detalle {
        id_detalle
        concurrencia_ejecucion_semanal
        concurrencia_ejecucion_day
        concurrencia_ejecucion_month
        concurrencia_tiempo
        inicio_periodo_semanal
        inicio_periodo_day
        inicio_periodo_month
        hora_inicio_periodo
        final_periodo_semanal
        final_periodo_day
        final_periodo_month
        hora_final_periodo
        fecha_inicio_ejecucion
        fecha_final_ejecucion
      }
      transporte_tramo {
        id_tramo
        fecha_viaje
        hora_viaje
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
            transporte_tipo {
              id_tipo
              nombre_transporte
            }
          }
        }
        codigo_asientos
        codigo
      }
    }
  }
`;

export const CREATE_TRAMO = gql`
  mutation CreateDestino(
    $idMedio: String!
    $idDestinoPadre: String!
    $isConexion: Boolean!
    $antesDespues: String!
    $idaVuelta: String!
    $idEmpresa: String!
    $idUsuario: String!
    $nombreOrigen: String!
    $nombreDestino: String!
  ) {
    createDestino(
      id_medio: $idMedio
      id_destino_padre: $idDestinoPadre
      is_conexion: $isConexion
      antes_despues: $antesDespues
      ida_vuelta: $idaVuelta
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
      nombre_origen: $nombreOrigen
      nombre_destino: $nombreDestino
    ) {
      id_destino
      nombre_origen
      nombre_destino
      ida_vuelta
      transporte_tipo_medio {
        id_medio
        cantidad_asiento
        nombre_medio
        transporte_tipo {
          id_tipo
          nombre_transporte
        }
      }
    }
  }
`;

export const CREATE_SOLICITUD_TRANSPORTE = gql`
  mutation SendSolicitudTransporte(
    $tramos: [crearSolicitudTransporte]!
    $idEmpresa: String!
    $idUsuario: String!
    $idTurno: String!
    $idTeam: String!
  ) {
    SendSolicitudTransporte(
      tramos: $tramos
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
      id_turno: $idTurno
      id_team: $idTeam
    ) {
      id_solicitud
      fecha_creacion
      error
      guardado
      ida_vuelta
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        correo
        telefono_movil
        telefono_fijo
        anexo
        imagen_perfil
        genero
        token
        fecha_nacimiento
        year
        fecha_ingreso
        empresa {
          id_empresa
          nombre_empresa
        }
        cargo {
          id_cargo
          nombre_cargo
        }
        sucursal {
          id_sucursal
          nombre_sucursal
        }
        gerencia {
          id_gerencia
          nombre_gerencia
          bussiness_unit
          unit_number
        }
        turno {
          id_turno
          nombre_turno
        }
        nacion {
          id_nacion
          nombre_nacion
        }
        sociedad {
          id_sociedad
          nombre_sociedad
        }
        sindicato {
          id_sindicato
          nombre_sindicato
        }
        team {
          id_team
          nombre_team
        }
        privilegio {
          id_tipo
          nombre_tipo
        }
        grupo {
          id_grupo
          nombre_grupo
          descripcion_grupo
        }
      }
      transporte_tramo {
        id_tramo
        codigo
        codigo_asientos
        fecha_viaje
        hora_viaje
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
          }
        }
      }
      transporte_asiento {
        id_asiento
        nombre_asiento
      }
      transporte_tramo_conexion {
        id_tramo
        codigo
        codigo_asientos
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            cantidad_asiento
          }
        }
      }
      transporte_asiento_conexion {
        id_asiento
        nombre_asiento
      }
    }
  }
`;

export const DELETE_SOLICITUD_TRANSPORTE = gql`
  mutation CancelarSolicitud(
    $idSolicitud: [String]!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    cancelarSolicitud(
      id_solicitud: $idSolicitud
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_solicitud
      fecha_creacion
      error
      guardado
      ida_vuelta
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        apellido_materno
        imagen_perfil
      }
      transporte_tramo {
        id_tramo
        codigo
        codigo_asientos
        fecha_viaje
        hora_viaje
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
          }
        }
      }
      transporte_asiento {
        id_asiento
        nombre_asiento
      }
      transporte_tramo_conexion {
        id_tramo
        codigo
        codigo_asientos
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            cantidad_asiento
          }
        }
      }
      transporte_asiento_conexion {
        id_asiento
        nombre_asiento
      }
    }
  }
`;

export const EDIT_SOLICITUD_TRANSPORTE = gql`
  mutation EditSolicitudTransporte(
    $idSolicitud: [String]!
    $tramos: [crearSolicitudTransporte]!
    $idEmpresa: String!
    $idUsuario: String!
    $idTurno: String!
    $idTeam: String!
    $idCreador: String!
  ) {
    EditSolicitudTransporte(
      id_solicitud: $idSolicitud
      tramos: $tramos
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
      id_turno: $idTurno
      id_team: $idTeam
      id_creador: $idCreador
    ) {
      id_solicitud
      fecha_creacion
      error
      guardado
      ida_vuelta
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        apellido_materno
        imagen_perfil
        turno {
          id_turno
          nombre_turno
        }
        team {
          id_team
          nombre_team
        }
      }
      transporte_tramo {
        id_tramo
        codigo
        codigo_asientos
        fecha_viaje
        hora_viaje
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
          }
        }
      }
      transporte_asiento {
        id_asiento
        nombre_asiento
      }
      transporte_tramo_conexion {
        id_tramo
        codigo
        codigo_asientos
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            cantidad_asiento
          }
        }
      }
      transporte_asiento_conexion {
        id_asiento
        nombre_asiento
      }
    }
  }
`;

export const EDIT_SOLICITUD_TRANSPORTE_ADMIN = gql`
  mutation EditSolicitudTransporteAdmin(
    $idEmpresa: String!
    $idCreador: String!
    $idTramo: String!
    $idSolicitud: String!
    $idAsiento: String!
    $idUsuario: String!
    $idTurno: String!
    $idTeam: String!
  ) {
    EditSolicitudTransporteAdmin(
      id_empresa: $idEmpresa
      id_creador: $idCreador
      id_tramo: $idTramo
      id_solicitud: $idSolicitud
      id_asiento: $idAsiento
      id_usuario: $idUsuario
      id_turno: $idTurno
      id_team: $idTeam
    ) {
      id_solicitud
      fecha_creacion
      error
      guardado
      ida_vuelta
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        telefono_movil
        correo
        imagen_perfil
        turno {
          id_turno
          nombre_turno
        }
        team {
          id_team
          nombre_team
        }
        empresa {
          id_empresa
          nombre_empresa
        }
        cargo {
          id_cargo
          nombre_cargo
        }
        sucursal {
          id_sucursal
          nombre_sucursal
        }
        gerencia {
          id_gerencia
          nombre_gerencia
          bussiness_unit
          unit_number
        }
      }
      transporte_tramo {
        id_tramo
        codigo
        codigo_asientos
        fecha_viaje
        hora_viaje
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            nombre_medio
            cantidad_asiento
          }
        }
      }
      transporte_asiento {
        id_asiento
        nombre_asiento
      }
      transporte_tramo_conexion {
        id_tramo
        codigo
        codigo_asientos
        transporte_lista_destino {
          id_destino
          nombre_origen
          nombre_destino
          transporte_tipo_medio {
            id_medio
            cantidad_asiento
          }
        }
      }
      transporte_asiento_conexion {
        id_asiento
        nombre_asiento
      }
    }
  }
`;

export const VIDEO_ADD = gql`
  mutation AgregarVideo(
    $tituloVideo: String!
    $descripcionVideo: String!
    $video: String!
    $formatoVideo: String!
    $imagen: String!
    $formatoImagen: String!
    $idEmpresa: String!
    $idCategoria: String!
    $fechaVideo: String!
    $tipoSegementacion: String!
    $idUsuario: String!
    $alertaPush: String!
    $segmentaciones: [String]
  ) {
    agregarVideo(
      titulo_video: $tituloVideo
      descripcion_video: $descripcionVideo
      video: $video
      formato_video: $formatoVideo
      imagen: $imagen
      formato_imagen: $formatoImagen
      id_empresa: $idEmpresa
      id_categoria: $idCategoria
      fecha_video: $fechaVideo
      tipo_segementacion: $tipoSegementacion
      id_usuario: $idUsuario
      alerta_push: $alertaPush
      segmentaciones: $segmentaciones
    ) {
      id_video
      titulo_video
      descripcion_video
      nombre_carpeta
      url_video
      url_imagen
      id_empresa_fk
      id_estado_fk
      alerta_push
      fecha_video
      likes
      fecha_creacion
      categoria {
        id_categoria
        nombre_categoria
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
    }
  }
`;

export const VIDEO_EDIT = gql`
  mutation EditarVideo(
    $idVideo: String!
    $tituloVideo: String!
    $descripcionVideo: String!
    $video: String!
    $formatoVideo: String!
    $imagen: String!
    $formatoImagen: String!
    $idEmpresa: String!
    $idCategoria: String!
    $fechaVideo: String!
    $tipoSegementacion: String!
    $idUsuario: String!
    $alertaPush: String!
    $segmentaciones: [String]
  ) {
    editarVideo(
      id_video: $idVideo
      titulo_video: $tituloVideo
      descripcion_video: $descripcionVideo
      video: $video
      formato_video: $formatoVideo
      imagen: $imagen
      formato_imagen: $formatoImagen
      id_empresa: $idEmpresa
      id_categoria: $idCategoria
      fecha_video: $fechaVideo
      tipo_segementacion: $tipoSegementacion
      id_usuario: $idUsuario
      alerta_push: $alertaPush
      segmentaciones: $segmentaciones
    ) {
      id_video
      titulo_video
      descripcion_video
      nombre_carpeta
      url_video
      url_imagen
      id_empresa_fk
      id_estado_fk
      alerta_push
      fecha_video
      likes
      fecha_creacion
      categoria {
        id_categoria
        nombre_categoria
      }
      segmentaciones {
        id_segmentacion
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        segmentaciones
      }
    }
  }
`;

export const VIDEO_STATE = gql`
  mutation ChangeStateVideo(
    $idEmpresa: String!
    $idVideo: String!
    $idUsuario: String!
    $idEstado: String!
    $alertaPush: String!
  ) {
    changeStateVideo(
      id_empresa: $idEmpresa
      id_video: $idVideo
      id_usuario: $idUsuario
      id_estado: $idEstado
      alerta_push: $alertaPush
    ) {
      id_video
      titulo_video
      descripcion_video
      nombre_carpeta
      url_video
      url_imagen
      id_empresa_fk
      id_estado_fk
      categoria {
        id_categoria
        nombre_categoria
        id_empresa_fk
      }
      likes
      fecha_creacion
      fecha_video
      segmentaciones {
        tipo_segmentacion {
          id_elementos_segmentacion
          nombre_segmentacion
        }
        id_segmentacion
        segmentaciones
      }
      alerta_push
    }
  }
`;

export const VIDEO_DELETE = gql`
  mutation EliminarVideo(
    $idEmpresa: String!
    $idVideo: String!
    $idUsuario: String!
  ) {
    eliminarVideo(
      id_empresa: $idEmpresa
      id_video: $idVideo
      id_usuario: $idUsuario
    ) {
      id_video
    }
  }
`;

export const VIDEO_CAT_ADD = gql`
  mutation AgregarCategoriaVideo(
    $nombre: String!
    $idEmpresa: String!
    $idUsuario: String!
  ) {
    agregarCategoriaVideo(
      nombre: $nombre
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
    ) {
      id_categoria
      nombre_categoria
      id_empresa_fk
    }
  }
`;

export const VIDEO_CAT_EDIT = gql`
  mutation UpdateCategoriaVideo(
    $nombre: String!
    $idEmpresa: String!
    $idUsuario: String!
    $idCategoria: String!
  ) {
    updateCategoriaVideo(
      nombre: $nombre
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
      id_categoria: $idCategoria
    ) {
      id_categoria
      nombre_categoria
      id_empresa_fk
    }
  }
`;
export const VIDEO_CAT_DELETE = gql`
  mutation DeleteCategoriaVideo(
    $idEmpresa: String!
    $idUsuario: String!
    $idCategoria: String!
  ) {
    deleteCategoriaVideo(
      id_empresa: $idEmpresa
      id_usuario: $idUsuario
      id_categoria: $idCategoria
    ) {
      id_categoria
    }
  }
`;

export const VALIDATE_SCREEN_DISP = gql`
  mutation ValidarDispositivo($codigo: String!) {
    ValidarDispositivo(codigo: $codigo) {
      id_dispositivo
      codigo
      nombre_dispositivo
      asociado
      uuid
      estado_configuracion
      db_message
    }
  }
`;

export const CHANGE_SCREEN_STATE = gql`
  mutation UpdateEstadoDispositivo(
    $codigo: String!
    $id_estado_dispositivo: Int!
  ) {
    UpdateEstadoDispositivo(
      codigo: $codigo
      id_estado_dispositivo: $id_estado_dispositivo
    ) {
      id_dispositivo
      codigo
      nombre_dispositivo
      asociado
      uuid
      estado_configuracion
      db_message
    }
  }
`;

export const CREATE_SCREEN = gql`
  mutation savePantalla(
    $nombre_pantalla: String!
    $descripcion_pantalla: String!
    $codigo: String!
    $ubicacion: String!
    $zona_horaria: String!
    $id_canal: [Int]!
    $id_dispositivo: Int!
  ) {
    SavePantalla(
      nombre_pantalla: $nombre_pantalla
      descripcion_pantalla: $descripcion_pantalla
      codigo: $codigo
      ubicacion: $ubicacion
      zona_horaria: $zona_horaria
      id_canal: $id_canal
      id_dispositivo: $id_dispositivo
    ) {
      id_pantalla
      nombre_pantalla
      ubicacion
      zona_horaria
      codigo
      estado {
        id_estado
        nombre_estado
        color
      }
      canal {
        id_canal
        nombre_canal
        descripcion_canal
        cantidad_pantallas
      }
    }
  }
`;

export const UPDATE_SCREEN = gql`
  mutation UpdatePantalla(
    $nombre_pantalla: String!
    $descripcion_pantalla: String!
    $codigo: String!
    $ubicacion: String!
    $zona_horaria: String!
    $id_canal: [Int]!
    $id_pantalla: Int!
  ) {
    UpdatePantalla(
      nombre_pantalla: $nombre_pantalla
      descripcion_pantalla: $descripcion_pantalla
      codigo: $codigo
      ubicacion: $ubicacion
      zona_horaria: $zona_horaria
      id_canal: $id_canal
      id_pantalla: $id_pantalla
    ) {
      id_pantalla
      nombre_pantalla
      ubicacion
      zona_horaria
      codigo
      estado {
        id_estado
        nombre_estado
        color
      }
      canal {
        id_canal
        nombre_canal
        descripcion_canal
      }
    }
  }
`;

export const DELETE_SCREEN = gql`
  mutation DeletePantalla($id_pantalla: Int!) {
    DeletePantalla(id_pantalla: $id_pantalla) {
      id_pantalla
      nombre_pantalla
      ubicacion
      zona_horaria
      canal {
        id_canal
        nombre_canal
        descripcion_canal
        cantidad_pantallas
      }
    }
  }
`;

export const SEND_MAIL_NEW_SCREEN = gql`
mutation SendMailNuevasPantallas {
  SendMailNuevasPantallas {
    id_usuario,
    id_empresa,
    envio_correo,
    mensaje_modal,
    type_error
  }
}
`;

// Canal corporativo
export const CANAL_ADD = gql`
  mutation SaveCanal(
    $nombre_canal: String!
    $descripcion_canal: String!
    $dias_frecuencia: [Int]!
    $hora_inicio: String
    $hora_fin: String
  ) {
    SaveCanal(
      nombre_canal: $nombre_canal
      descripcion_canal: $descripcion_canal
      dias_frecuencia: $dias_frecuencia
      hora_inicio: $hora_inicio
      hora_fin: $hora_fin
    ) {
      id_canal
      nombre_canal
      descripcion_canal
      reproduccion_dia
      reproduccion_hora
      frecuencia {
        id_frecuencia
        num_dia
        string_dia
        hora_inicio
        hora_fin
      }
      estado {
        id_estado
        nombre_estado
        color
      }
      cantidad_pantallas
      fecha_modificacion
    }
  }
`;

export const CANAL_UPDATE = gql`
  mutation UpdateCanal(
    $id_canal: Int!
    $nombre_canal: String!
    $descripcion_canal: String!
    $dias_frecuencia: [Int]!
    $hora_inicio: String
    $hora_fin: String
  ) {
    UpdateCanal(
      id_canal: $id_canal
      nombre_canal: $nombre_canal
      descripcion_canal: $descripcion_canal
      dias_frecuencia: $dias_frecuencia
      hora_inicio: $hora_inicio
      hora_fin: $hora_fin
    ) {
      id_canal
      nombre_canal
      descripcion_canal
      reproduccion_dia
      reproduccion_hora
      frecuencia {
        id_frecuencia
        num_dia
        string_dia
        hora_inicio
        hora_fin
      }
      estado {
        id_estado
        nombre_estado
        color
      }
      cantidad_pantallas
      fecha_modificacion
    }
  }
`;

export const CANAL_DELETE = gql`
  mutation DeleteCanal($id_canal: Int!) {
    DeleteCanal(id_canal: $id_canal) {
      id_canal
      nombre_canal
      descripcion_canal
    }
  }
`;

export const SAVE_CHANNEL_LAYOUT = gql`
  mutation SaveListaReproduccion(
    $id_canal: Int!
    $lista: CanalListaObjectNew!
  ) {
    SaveListaReproduccion(id_canal: $id_canal, lista: $lista) {
      id_lista
      codigo
      fecha_creacion
      color
      videos {
        id_file
        tiempo_ejecucion
        fecha_activacion
        total_tiempo
        orden
        archivo {
          id_archivo
          nombre_archivo
          descripcion_archivo
          url_archivo
          tiempo_video
          detalle_tipoa {
            id_detalle
            nombre_detalle
            tipo_archivo {
              id_tipo
              nombre_archivo
            }
          }
        }
        video_tv {
          id_video
          url_video
        }
      }
      banners {
        id_file
        tiempo_ejecucion
        fecha_activacion
        total_tiempo
        orden
        archivo {
          id_archivo
          nombre_archivo
          descripcion_archivo
          url_archivo
          tiempo_video
          detalle_tipoa {
            id_detalle
            nombre_detalle
            tipo_archivo {
              id_tipo
              nombre_archivo
            }
          }
        }
        video_tv {
          id_video
          url_video
        }
      }
      widgets {
        id_widget
        tipo_widget
        tiempo_ejecucion
        api {
          id_api
          nombre_api
          url_api
          indicadores_api {
            id_indicador
            nombre_indicador
            fecha_creacion
            valor
          }
        }
        modulo {
          id_modulo
          nombre_modulo
        }
        birthdays {
          id_usuario
          primer_nombre
          apellido_paterno
          fecha_nacimiento
          imagen_perfil
          cargo {
            id_cargo
            nombre_cargo
          }
        }
        nuevo_ingreso {
          id_usuario
          primer_nombre
          apellido_paterno
          imagen_perfil
          cargo {
            id_cargo
            nombre_cargo
          }
        }
        movimientos {
          fecha_movimiento
          usuario {
            id_usuario
            primer_nombre
            apellido_paterno
            imagen_perfil
            fecha_ingreso
            cargo {
              id_cargo
              nombre_cargo
            }
          }
          cargo_nuevo {
            id_cargo
            nombre_cargo
          }
          cargo_antiguo {
            id_cargo
            nombre_cargo
          }
        }
        nuevos_padres {
          id_nacimiento
          nombre_bebe
          peso
          medida
          color
          fecha_nacimiento
          sexo {
            id_sexo
            nombre_sexo
          }
          usuario {
            id_usuario
            primer_nombre
            apellido_paterno
            imagen_perfil
            cargo {
              id_cargo
              nombre_cargo
            }
          }
        }
        reconocimientos {
          id_reconocimiento
          reconocidos {
            id_usuario
            primer_nombre
            apellido_paterno
            imagen_perfil
          }
          reconocedor {
            id_usuario
            primer_nombre
            apellido_paterno
            apellido_materno
            imagen_perfil
          }
          relacion {
            id_relacion
            nombre_relacion
          }
          valor_comportamiento {
            id_valor_comportamiento
            valor {
              id_valor
              nombre_valor
            }
            comportamiento {
              id_comportamiento
              nombre_comportamiento
            }
          }
        }
      }
    }
  }
`;

export const EDIT_CHANNEL_LAYOUT = gql`
  mutation UpdateListaReproduccion(
    $id_canal: Int!
    $lista: CanalListaObjectNew!
  ) {
    UpdateListaReproduccion(id_canal: $id_canal, lista: $lista) {
      id_lista
      codigo
      fecha_creacion
      color
      url_logo
      videos {
        id_file
        tiempo_ejecucion
        fecha_activacion
        total_tiempo
        orden
        archivo {
          id_archivo
          nombre_archivo
          descripcion_archivo
          url_archivo
          tiempo_video
          detalle_tipoa {
            id_detalle
            nombre_detalle
            tipo_archivo {
              id_tipo
              nombre_archivo
            }
          }
        }
        video_tv {
          url_video
          titulo_video
        }
      }
      banners {
        id_file
        tiempo_ejecucion
        fecha_activacion
        total_tiempo
        orden
        archivo {
          id_archivo
          nombre_archivo
          descripcion_archivo
          url_archivo
          tiempo_video
          detalle_tipoa {
            id_detalle
            nombre_detalle
            tipo_archivo {
              id_tipo
              nombre_archivo
            }
          }
        }
      }
      widgets {
        id_widget
        tipo_widget
        tiempo_ejecucion
        api {
          id_api
          nombre_api
          url_api
          indicadores_api {
            id_indicador
            nombre_indicador
            fecha_creacion
            valor
          }
        }
        modulo {
          id_modulo
          nombre_modulo
        }
        birthdays {
          id_usuario
          primer_nombre
          apellido_paterno
          fecha_nacimiento
          imagen_perfil
          cargo {
            id_cargo
            nombre_cargo
          }
        }
        nuevo_ingreso {
          id_usuario
          primer_nombre
          apellido_paterno
          imagen_perfil
          cargo {
            id_cargo
            nombre_cargo
          }
        }
        movimientos {
          fecha_movimiento
          usuario {
            id_usuario
            primer_nombre
            apellido_paterno
            imagen_perfil
            fecha_ingreso
            cargo {
              id_cargo
              nombre_cargo
            }
          }
          cargo_nuevo {
            id_cargo
            nombre_cargo
          }
          cargo_antiguo {
            id_cargo
            nombre_cargo
          }
        }
        nuevos_padres {
          id_nacimiento
          nombre_bebe
          peso
          medida
          color
          fecha_nacimiento
          sexo {
            id_sexo
            nombre_sexo
          }
          usuario {
            id_usuario
            primer_nombre
            apellido_paterno
            imagen_perfil
            cargo {
              id_cargo
              nombre_cargo
            }
          }
        }
        reconocimientos {
          id_reconocimiento
          reconocidos {
            id_usuario
            primer_nombre
            apellido_paterno
            imagen_perfil
          }
          reconocedor {
            id_usuario
            primer_nombre
            apellido_paterno
            apellido_materno
            imagen_perfil
          }
          relacion {
            id_relacion
            nombre_relacion
          }
          valor_comportamiento {
            id_valor_comportamiento
            valor {
              id_valor
              nombre_valor
            }
            comportamiento {
              id_comportamiento
              nombre_comportamiento
            }
          }
        }
      }
    }
  }
`;

export const DELETE_FILE_REPOSITORIO = gql`
  mutation eliminarArcivoRepositorio($id_archivo: Int!) {
    eliminarArcivoRepositorio(id_archivo: $id_archivo) {
      id_archivo
      nombre_archivo
      url_archivo
      descripcion_archivo
      tiempo_video
      detalle_tipoa {
        id_detalle
        nombre_detalle
        descripcion_detalle
        cantidad
        dimensiones_alto
        dimensiones_ancho
        peso
        fecha_creacion
      }
      canales
      fecha_creacion
      fecha_modificacion
    }
  }
`;

export const CREAR_CATEGORIA_NOTICIA = gql`
  mutation CrearCategoriaNoticia(
    $id_usuario: String!
    $id_empresa: String!
    $nombre_categoria: String!
  ) {
    CrearCategoriaNoticia(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      nombre_categoria: $nombre_categoria
    ) {
      id_categoria
      nombre_categoria
      especial
    }
  }
`;

export const EDITAR_CATEGORIA_NOTICIA = gql`
  mutation EditarCategoriaNoticia(
    $id_usuario: String!
    $id_empresa: String!
    $id_categoria: String!
    $nombre_categoria: String!
  ) {
    EditarCategoriaNoticia(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_categoria: $id_categoria
      nombre_categoria: $nombre_categoria
    ) {
      id_categoria
      nombre_categoria
      especial
    }
  }
`;

export const ELIMINAR_CATEGORIA_NOTICIA = gql`
  mutation EliminarCategoriaNoticia(
    $id_usuario: String!
    $id_empresa: String!
    $id_categoria: String!
  ) {
    EliminarCategoriaNoticia(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_categoria: $id_categoria
    ) {
      id_categoria
      nombre_categoria
    }
  }
`;

export const CAMBIAR_ESTADO_NOTICIA = gql`
  mutation cambiarEstadoNoticia(
    $id_empresa: String!
    $id_usuario: String!
    $id_noticia: String!
    $id_estado: String!
    $alerta_push: String!
  ) {
    cambiarEstadoNoticia(
      id_modificador: $id_usuario
      id_empresa_fk: $id_empresa
      id_noticia: $id_noticia
      id_estado: $id_estado
      alerta_push: $alerta_push
    ) {
      id_noticia
      id_estado_fk
      alerta_push
      titulo_noticia
      url_imagen_principal
      fecha_noticia
      fecha_creacion
      tipo_noticia {
        id_tipo
        nombre_tipo
      }
      categoria {
        id_categoria
        nombre_categoria
        especial
      }
      contenido {
        id_contenido
        id_noticia_fk
        texto
        url_imagen
        url_externo_app
        url_externo_intranet
        url_video
        url_documento
        usuario {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
          imagen_perfil
        }
        tipo_contenido {
          id_tipo_contenido
          nombre_tipo_contenido
        }
      }
    }
  }
`;

export const CAMBIAR_ESTADO_GALERIA = gql`
  mutation cambiarEstadoGaleria(
    $id_usuario: String!
    $id_empresa: String!
    $id_galeria: String!
    $id_estado: String!
    $alerta_push: String!
  ) {
    cambiarEstadoGaleria(
      id_usuario: $id_usuario
      id_empresa: $id_empresa
      id_galeria: $id_galeria
      id_estado: $id_estado
      alerta_push: $alerta_push
    ) {
      id_galeria
      titulo_galeria
      fecha_galeria
      id_estado_fk
      alerta_push
      nombre_carpeta
      total_likes_admin
      nombre_carpeta
      imagenes {
        id_imagen
        url_imagen_thumb
        url_imagen_original
        likes {
          id_like
          usuario {
            id_usuario
          }
        }
      }
    }
  }
`;

// NUEVOS PADRES (BIRTHS)

export const CREAR_NUEVO_NACIMIENTO = gql`
  mutation crearNuevosPadres(
    $nombre_bebe: String!
    $peso: String!
    $medida: String!
    $color: String!
    $img_nacimiento: String!
    $formato_nacimiento: String!
    $id_usuario: String!
    $id_sexo: String!
    $id_empresa: String!
    $id_creador: String!
    $fecha_nacimiento: String!
  ) {
    crearNuevosPadres(
      nombre_bebe: $nombre_bebe
      peso: $peso
      medida: $medida
      color: $color
      img_nacimiento: $img_nacimiento
      formato_nacimiento: $formato_nacimiento
      id_usuario: $id_usuario
      id_sexo: $id_sexo
      id_empresa: $id_empresa
      id_creador: $id_creador
      fecha_nacimiento: $fecha_nacimiento
    ) {
      id_nacimiento
      nombre_bebe
      color
      fecha_nacimiento
      sexo {
        id_sexo
        nombre_sexo
      }
      peso
      medida
      img_nacimiento
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        imagen_perfil
        cargo {
          id_cargo
          nombre_cargo
        }
        gerencia {
          id_gerencia
          nombre_gerencia
        }
        sucursal {
          id_sucursal
          nombre_sucursal
        }
      }
    }
  }
`;

export const EDITAR_NACIMIENTO = gql`
  mutation editarNuevosPadres(
    $id_nacimiento: String!
    $nombre_bebe: String!
    $peso: String!
    $medida: String!
    $color: String!
    $img_nacimiento: String!
    $formato_nacimiento: String!
    $id_usuario: String!
    $id_sexo: String!
    $id_empresa: String!
    $id_modificador: String!
    $fecha_nacimiento: String!
  ) {
    editarNuevosPadres(
      id_nacimiento: $id_nacimiento
      nombre_bebe: $nombre_bebe
      peso: $peso
      medida: $medida
      color: $color
      img_nacimiento: $img_nacimiento
      formato_nacimiento: $formato_nacimiento
      id_usuario: $id_usuario
      id_sexo: $id_sexo
      id_empresa: $id_empresa
      id_modificador: $id_modificador
      fecha_nacimiento: $fecha_nacimiento
    ) {
      id_nacimiento
      nombre_bebe
      color
      fecha_nacimiento
      sexo {
        id_sexo
        nombre_sexo
      }
      peso
      medida
      img_nacimiento
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        imagen_perfil
        cargo {
          id_cargo
          nombre_cargo
        }
        gerencia {
          id_gerencia
          nombre_gerencia
        }
        sucursal {
          id_sucursal
          nombre_sucursal
        }
      }
    }
  }
`;
//eliminarNuevosPadres
export const ELIMINAR_NACIMIENTO = gql`
  mutation eliminarNuevosPadres(
    $id_eliminador: String!
    $id_empresa: String!
    $id_nacimiento: String!
  ) {
    eliminarNuevosPadres(
      id_eliminador: $id_eliminador
      id_empresa: $id_empresa
      id_nacimiento: $id_nacimiento
    ) {
      id_nacimiento
    }
  }
`;

/* PERSONALIZACIÓN INTERFAZ */
/* EditarModulosEmpresa: { 
  id_detalle: Int!
  nombre_alias: String
  posicion_pantalla: Int!
  icono_modulo: String
  formato_icono: String
  default_icono: String
  id_estado_fk: Int!
  destacado: Boolean!
} */
export const EDIT_MODULOS = gql`
  mutation editModulosEmpresas($data_modulos: [EditarModulosEmpresa]) {
    editModulosEmpresas(data_modulos: $data_modulos) {
      id_detalle
      nombre_modulo
      descripcion_modulo
      posicion_pantalla
      imagen_url
      url_menu_inactivo
      url_menu_activo
      ruta_web
      privilegio_modulos {
        id_modulo
        nombre_modulo
        descripcion_modulo
        id_app
        id_admin
        is_web
      }
      id_estado_fk
      destacado
    }
  }
`;

export const EDIT_INTERFAZ = gql`
  mutation editInterfaz(
    $color_primario: String!
    $logo_web: String
    $formato_logo_web: String
    $logo_app: String
    $formato_logo_app: String
    $logo_isotipo: String
    $formato_logo_isotipo: String
  ) {
    editInterfaz(
      color_primario: $color_primario
      logo_empresa: $logo_web
      formato_logo: $formato_logo_web
      logo_app_empresa: $logo_app
      formato_logo_app: $formato_logo_app
      isotipo_empresa: $logo_isotipo
      formato_isotipo: $formato_logo_isotipo
    ) {
      id_empresa
      color_primario
      logo_empresa
      isotipo_empresa
      logo_app_empresa
    }
  }
`;

/*
- HeaderObject: {
  id_header: Int, nombre_header: String, 
  color_activo: String, orden: Int, principal: [ObjectPricipales]
}
- ObjectPricipales: {
  id_elemento: Int, nombre_elemento: String, orden: Int, 
  url_externa: String,  secundarios: [ObjectsSecundarios]
}
- ObjectsSecundarios: (ídem ObjectPricipales), secundarios <-> terciarios: [ObjectsTerciarios]
- ObjectsTerciarios: (ídem ObjectsSecundarios, sin el "terciarios")
*/
export const ACTUALIZAR_MENU = gql`
  mutation actualizarMenu($menu: [HeaderObject]) {
    actualizarMenu(menu: $menu) {
      id_header
      nombre_header
      color_activo
      orden
      principal {
        id_elemento
        nombre_elemento
        orden
        url_externa
        secundarios {
          id_elemento
          nombre_elemento
          orden
          url_externa
          terciarios {
            id_elemento
            nombre_elemento
            orden
            url_externa
          }
        }
      }
    }
  }
`;

export const CREAR_SOLICITUD_HOTELERIA = gql`
  mutation createSolicitudHoteleria(
    $idUsuario: String!
    $descripcionSolicitud: String!
    $idServicio: String!
    $idTeam: String!
    $idTurno: String!
    $idHabitacion: String!
    $idCama: String!
    $idCloset: String!
    $idEmpresa: String!
  ) {
    createSolicitudHoteleria(
      id_usuario: $idUsuario
      descripcion_solicitud: $descripcionSolicitud
      id_servicio: $idServicio
      id_team: $idTeam
      id_turno: $idTurno
      id_habitacion: $idHabitacion
      id_cama: $idCama
      id_closet: $idCloset
      id_empresa: $idEmpresa
    ) {
      id_solicitud
      codigo_solicitud
      descripcion_solicitud
      usuario {
        id_usuario
        primer_nombre
        segundo_nombre
        apellido_paterno
        apellido_materno
        rut_usuario
        dv_usuario
        correo
        telefono_movil
        telefono_fijo
        anexo
        imagen_perfil
        genero
        token
        fecha_nacimiento
        year
        fecha_ingreso
        entity_id
        id_empresa_fk
        id_sucursal_fk
        id_privilegio_fk
        empresa
        cargo
        sucursal
        gerencia
        turno
        nacion
        sociedad
        sindicato
        team
        privilegio
        grupo
        id_estado_fk
      }
      habitacion {
        id_habitacion
        nombre_habitacion
        id_pabellon_fk
        id_empresa_fk
        id_estado_fk
      }
      cama {
        id_cama
        nombre_ubicacion
        id_estado_fk
        id_empresa_fk
      }
      closet {
        id_closet
        nombre_ubicacion
        id_estado_fk
        id_empresa_fk
      }
      urgencia {
        id_urgencia
        nombre_urgencia
        id_estado_fk
        id_empresa_fk
      }
      detalles {
        id_detalle
        descripcion_detalle
        detalle
      }
      documentos {
        id_documento
        url_documento
      }
      mensajes {
        id_mensaje
        mensaje
        visto
        fecha_creacion
        administrador
      }
      calificacion {
        id_calificacion_solicitud
        calificacion
        fecha_creacion
      }
      fecha_creacion
      estado {
        id_estado
        nombre_estado
        color
        calificacion
        id_estado_fk
        id_empresa_fk
      }
      id_empresa_fk
    }
  }
`;

export const EDITAR_ESTADO_SOLICITUD_HOTELERIA = gql`
  mutation UpdateEstadoSolicitudHoteleria(
    $idUsuario: String!
    $idEstado: String!
    $idEmpresa: String!
    $idSolicitud: String!
  ) {
    UpdateEstadoSolicitudHoteleria(
      id_usuario: $idUsuario
      id_estado: $idEstado
      id_empresa: $idEmpresa
      id_solicitud: $idSolicitud
    ) {
      id_solicitud
      codigo_solicitud
      descripcion_solicitud
      fecha_creacion
      usuario {
        id_usuario
        primer_nombre
        apellido_paterno
        imagen_perfil
        telefono_movil
        correo
        turno {
          nombre_turno
        }
        team {
          nombre_team
        }
      }
      habitacion {
        id_habitacion
        nombre_habitacion
        id_pabellon_fk {
          nombre_pabellon
        }
      }
      cama {
        id_cama
        nombre_ubicacion
      }
      closet {
        id_closet
        nombre_ubicacion
      }
      urgencia {
        id_urgencia
        nombre_urgencia
      }
      detalles {
        id_detalle
        descripcion_detalle
        detalle {
          nombre_detalle
          id_servicio_fk {
            id_servicio
            nombre_servicio
          }
        }
      }
      documentos {
        id_documento
      }
      mensajes {
        id_mensaje
      }
      calificacion {
        id_calificacion_solicitud
      }
      estado {
        id_estado
      }
      id_empresa_fk
    }
  }
`;
