const Encuestas = ()=> import('@/views/ADMIN/Encuestas.vue')
const EncuestasList = ()=> import('@/components/Encuestas/List.vue')
const EncuestasAdd = ()=> import('@/components/Encuestas/Add.vue')

export const encuestasList = {
  path: "/encuestas",
  name: "Encuestas",
  meta: { requiresAuth: true},
  component: Encuestas,
  redirect: { name: "encuestas-list" },
  children: [ {
      path: "list",
      meta: { requiresAuth: true},
      name: "encuestas-list",
      component: EncuestasList
    }, {
      path: "add",
      meta: { requiresAuth: true},
      name: "encuestas-add",
      component: EncuestasAdd
    }, {
      path: "edit/:id",
      meta: { requiresAuth: true},
      name: "encuestas-edit",
      component: EncuestasAdd
    },
  ],
};