const Library= ()=> import('@/views/ADMIN/Library.vue');
const LibraryList= ()=> import('@/components/Library/List.vue');
const LibraryAdd= ()=> import('@/components/Library/Add.vue');
const LibraryAddCat= ()=> import('@/components/Library/AddCategories.vue');

export const libraryList = {
  path: "/biblioteca",
  name: "Biblioteca",
  meta: { requiresAuth: true},
  component: Library,
  redirect: { name: "library-list" },
  children: [ {
      path: "list",
      meta: { requiresAuth: true},
      name: "library-list",
      component: LibraryList
    }, {
      path: "add",
      meta: { requiresAuth: true},
      name: "library-add",
      component: LibraryAdd
    }, {
      path: "edit/:id",
      meta: { requiresAuth: true},
      name: "library-edit",
      component: LibraryAdd
    }, {
      path: "add-categories",
      meta: { requiresAuth: true},
      name: "library-add-categories",
      component: LibraryAddCat
    },
  ],
};