import { GET_POSTS_PAGINATION, GET_POSTS_BY_PAGE, GET_WALL_PROFILES } from "@/apollo/queries";
import { WALL_STATE, WALL_ADD, WALL_ADD_PROFILE, WALL_EDIT_PROFILE, WALL_DELETE_PROFILE, WALL_EDIT } from "@/apollo/mutations";
import { apolloClient } from "../../../vue-apollo";

export default {
    namespaced: true,
    state: {
        pagination_data: [],
        paginated_posts: [],
        profile_list: [],
        post_edit: {}
    },

    mutations: {
        DELETE_POST(state, post_) {
            let index_post = state.paginated_posts.findIndex(
                post => post.id_muro == post_.id_muro
            );
            if (index_post != -1) {
                state.paginated_posts.splice(index_post, 1);
            }
            
        },
        DISABLE_POST(state, post_) {
            let index_post = state.paginated_posts.findIndex(
                post => post.id_muro == post_.id_muro
            );
            if (index_post != -1) {
                let changed_post = state.paginated_posts[index_post];
                changed_post.id_estado_fk = post_.id_estado_fk;
                Object.assign(state.paginated_posts[index_post], changed_post)
            }
        },
        EDIT_POST(state, post) {
            let index = state.paginated_posts.findIndex(
                e => e.id_muro == post.id_muro
            );
            // console.log("Index en edit: ", index);
            // console.log("revisado resultado de post: ", post);
            if (index != -1) {
                //state.paginated_posts[index] = post;
                Object.assign(state.paginated_posts[index], post)
                // console.log("data pusheada: ", state.paginated_posts[index]);
                // console.log("data total state: ", state.paginated_posts);
            }
        },
        SET_POST_FOR_EDIT(state, post){
            if(post.length>0){
                state.post_edit = post[0];
            }
        
        },
        ADD_POST(state, post) {
            //console.log("NUEVO POST A INGRESAR AL OBJETO: ", post);
            state.paginated_posts.unshift(post);
        },
        ADD_PROFILE(state, profile) {
            state.profile_list.unshift(profile);
        },
        UPDATE_PROFILE(state, profile_) {
            let index_profile = state.profile_list.findIndex(
                profile => profile.id_perfil == profile_.id_perfil
            );
            if (index_profile != -1) {
                console.log(state.profile_list[index_profile]);
                Object.assign(state.profile_list[index_profile], profile_)
            }
        },
        REMOVE_PROFILE(state, id_profile_) {
            let index_profile = state.profile_list.findIndex(
                profile => profile.id_perfil == id_profile_
            );
            if (index_profile != -1) {
                state.profile_list.splice(index_profile, 1);
            }
        },
        SET_PROFILE_LIST(state, profiles) {
            state.profile_list = profiles;
        },
        SET_PAGINATION(state, posts) {
            state.pagination_data = posts;
        },
        SET_PAGINATED_POST(state, posts) {
            state.paginated_posts = posts;
        },
    },
    actions: {
        async getPostsPagination({ commit }, data_) {
            let response = null;
            let params = {
                query: GET_POSTS_PAGINATION,
                variables: data_,
                fetchPolicy: 'network-only'
            };
            await apolloClient.query(params).then(res => {
                if (!res.loading) response = res.data.TotalPaginasPublicacionesMuro;
                if (response != null) {
                    commit('SET_PAGINATION', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async getAllPostsByPage({ commit }, data_) {
            let response = null;
            let params = {
                query: GET_POSTS_BY_PAGE,
                variables: data_,
                fetchPolicy: 'network-only'
            };
            await apolloClient.query(params).then(res => {
                if (!res.loading) response = res.data.PublicacionesMuroPaginas;
                if (response != null) {
                    commit('SET_PAGINATED_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        // metodo de paginacion y envia los datos correspondiente a muro según pagina, esto depreca metodo de getAllPostsByPage
        async getPostPaginasPostCompleto({ dispatch, commit }, payload){
            let response = null;
            const paginatedMuro = await dispatch("getPostsPagination", payload.data2_);
            let pagina = payload.pagina;
            let ids_postmuro = [];
            if (paginatedMuro){
                paginatedMuro.forEach((data)=>{
                    if(data.pagina == pagina){
                        ids_postmuro = data.muros;
                    }
                }); 
            }
            let data_ = {
                id_empresa: payload.data2_.id_empresa,
                plataforma: payload.data2_.plataforma,
                id_muro: ids_postmuro
            }
            let params = {
                query: GET_POSTS_BY_PAGE,
                variables: data_,
                fetchPolicy: 'network-only'
            }
            try {
            await apolloClient.query(params).then(res => {
                //console.log("respuest de server getPostPaginasPostCompleto: ", res.data.PublicacionesMuroPaginas);
                if (!res.loading) response = res.data.PublicacionesMuroPaginas;
            });
            }
            catch(e){
            console.log("Falla error, getPostPaginasPostCompleto ", e);
            }
            return new Promise((resolve)=>{
                if(response!=null){
                    if (response.length==0){
                        resolve(false);
                        commit("SET_PAGINATED_POST", response);
                    }
                    else {
                        resolve(response);
                        commit("SET_PAGINATED_POST", response);
                    }
                }
                else {
                    resolve(false);
                }
            });
        },
        async getProfiles({ commit }, data_) {
            let response = null;
            let params = {
                query: GET_WALL_PROFILES,
                variables: data_,
                fetchPolicy: 'network-only'
            };
            await apolloClient.query(params).then(res => {
                console.log('Perfiles', res);
                if (!res.loading) response = res.data.PerfilesListAll;
                if (response != null) {
                    commit('SET_PROFILE_LIST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async deletePost({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_STATE,
                variables: data_,
                
            };
            //console.log("params cuando es con perfiles de usuario y no con usuario posteador: ", data_);
            await apolloClient.mutate(params).then(res => {
                console.log("DATA deletePost: ", res.data);
                if (!res.loading) response = res.data.ChangeEstadoMuro;
                if (response != null) {
                    commit('DELETE_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async disablePost({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_STATE,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                //console.log('disabled', res.data.ChangeEstadoMuro);
                if (!res.loading) response = res.data.ChangeEstadoMuro;
                if (response != null) {
                    commit('DISABLE_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async addPost({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_ADD,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('add post', res);
                if (!res.loading) response = res.data.crearMuroAdmin;
                if (response != null) {
                    commit('ADD_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async editPost({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_EDIT,
                variables: data_
            };
            console.log("Params de wall: ", data_);
            await apolloClient.mutate(params).then(res => {
                console.log('editPost store wall', res);
                if (!res.loading) response = res.data.editarMuroAdmin;
                if (response != null) {
                    commit('EDIT_POST', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async addProfile({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_ADD_PROFILE,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('add profile', res);
                if (!res.loading) response = res.data.CreatePerfil;
                if (response != null) {
                    commit('ADD_PROFILE', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async editProfile({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_EDIT_PROFILE,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                if (!res.loading) response = res.data.UpdatePerfilMuro;
                if (response != null) {
                    commit('UPDATE_PROFILE', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        async removeProfile({ commit }, data_) {
            let response = null;
            let params = {
                mutation: WALL_DELETE_PROFILE,
                variables: data_
            };
            await apolloClient.mutate(params).then(res => {
                console.log('respuesta servidor', res);
                if (!res.loading) response = res.data.DeletePerfilMuro.id_perfil;
                console.log('delete profile', response);
                if (response != null) {
                    commit('REMOVE_PROFILE', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        },
        // Una noticia
        async getOnePostById({commit}, data_){
            let response = null;
            let params = {
                query: GET_POSTS_BY_PAGE,
                variables: data_,
                fetchPolicy: 'network-only'
            };
            await apolloClient.query(params).then(res => {
                if (!res.loading) response = res.data.PublicacionesMuroPaginas;
                if (response != null) {
                    commit('SET_POST_FOR_EDIT', response);
                }
            }).catch(err => {
                console.log('error:', err);
            });
            return new Promise(resolve => {
                resolve(response);
            });
        }
    },
};